"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExtendedClientFlagsresponseClientTypeEnum = exports.ExtendedClientFlagsresponseRmaStatusEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ExtendedClientFlagsresponseRmaStatusEnum;
(function (ExtendedClientFlagsresponseRmaStatusEnum) {
  ExtendedClientFlagsresponseRmaStatusEnum["INITIATED"] = "INITIATED";
  ExtendedClientFlagsresponseRmaStatusEnum["PENDING"] = "PENDING";
  ExtendedClientFlagsresponseRmaStatusEnum["ACCEPTED"] = "ACCEPTED";
  ExtendedClientFlagsresponseRmaStatusEnum["TERMINATED"] = "TERMINATED";
  ExtendedClientFlagsresponseRmaStatusEnum["SEND"] = "SEND";
  ExtendedClientFlagsresponseRmaStatusEnum["SECONDARY_OWNER"] = "SECONDARY_OWNER";
})(ExtendedClientFlagsresponseRmaStatusEnum = exports.ExtendedClientFlagsresponseRmaStatusEnum || (exports.ExtendedClientFlagsresponseRmaStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ExtendedClientFlagsresponseClientTypeEnum;
(function (ExtendedClientFlagsresponseClientTypeEnum) {
  ExtendedClientFlagsresponseClientTypeEnum["P"] = "P";
  ExtendedClientFlagsresponseClientTypeEnum["O"] = "O";
})(ExtendedClientFlagsresponseClientTypeEnum = exports.ExtendedClientFlagsresponseClientTypeEnum || (exports.ExtendedClientFlagsresponseClientTypeEnum = {}));
