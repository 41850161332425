@import '../variables';

.#{$namespace}-text-crop {

  // Grid or flex are a required secret ingredient for when text-crop is applied to child elements.
  // After compensating without it, by assigning different values to crop top and bottom and the 
  // visual indicator position for each, I found when I changed the order of elements, it broke. 
  // Setting values with display grid applied to parent makes it robust enough to work regardless
  // of the order.
  display: grid;

  .#{$namespace} {
    &-text {
      &-6xl {
        @include c11n-text-crop-6xl();
        &-headline {
          @include c11n-text-crop-6xl-headline();
        }
        &-display {
          @include c11n-text-crop-6xl-display();
        }
      }
      &-5xl {
        @include c11n-text-crop-5xl();
        &-headline {
          @include c11n-text-crop-5xl();
        }
        &-display {
          @include c11n-text-crop-5xl-display();
        }
      }
      &-4xl {
        @include c11n-text-crop-4xl();
        &-headline {
          @include c11n-text-crop-4xl-headline();
        }
        &-display {
          @include c11n-text-crop-4xl-display();
        }
      }
      &-3xl {
        @include c11n-text-crop-3xl();
        &-headline {
          @include c11n-text-crop-3xl-headline();
        }
        &-display {
          @include c11n-text-crop-3xl-display();
        }
      }
      &-2xl {
        @include c11n-text-crop-2xl();
        &-headline {
          @include c11n-text-crop-2xl();
        }
        &-display {
          @include c11n-text-crop-2xl-display();
        }
      }
      &-xl {
        @include c11n-text-crop-xl();
        &-headline {
          @include c11n-text-crop-xl-headline();
        }
        &-display {
          @include c11n-text-crop-xl-display();
        }
      }
      &-lg {
        @include c11n-text-crop-lg();
        &-headline {
          @include c11n-text-crop-lg();
        }
        &-bold {
          @include c11n-text-crop-lg-bold();
        }
      }
      &-md {
        @include c11n-text-crop-md();
        &-article {
          @include c11n-text-crop-md-article();
        }
        &-bold {
          @include c11n-text-crop-md();
        }
        &-table {
          @include c11n-text-crop-md();
        }
        &-table-narrow {
          @include c11n-text-crop-md();
        }
      }
      &-sm {
        @include c11n-text-crop-sm();
        &-table {
          @include c11n-text-crop-sm();
        }
        &-table-narrow {
          @include c11n-text-crop-sm();
        }
      }
      &-xs {
        @include c11n-text-crop-xs();
      }
    }
  }
}