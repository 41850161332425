import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SessionStorageKeys } from '@app/etfs-equities/enums/session-storage-keys.enums';
import { Account, JsonContent } from '@app/etfs-equities/models';
import { Holding } from '@app/etfs-equities/models/account.model';
import {
  CostBasisService,
  SessionStorageService,
  TradeTicketService,
  WindowService,
} from '@app/etfs-equities/services';
import {
  CostBasisState,
  createLoadAvailableMethodsAction,
  selectClientSelectedDefaultMethodForCurrentPosition,
  selectCostBasisMethodByPosition,
  selectCostBasisState,
  selectIsEditCostBasisState,
  selectIsSellingAllHeldShares,
  selectIsSpecIdAllowed,
  selectOrigOrder,
  selectPositionForCurrentQuoteBySecurityType,
  selectSelectedAccount,
  selectTradeMethodsForCurrentPosition,
  selectTradeTicket,
  selectUpdateDefaultCostBasis,
  TayneState,
} from '@app/etfs-equities/store';
import { CostBasisUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import {
  BehaviorSubject,
  debounceTime,
  delay,
  filter,
  Observable,
  Subject,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

import { CostBasisLotErrorModalComponent } from '../cost-basis-lot-error-modal/cost-basis-lot-error-modal.component';

@Component({
  selector: 'twe-cost-basis-control',
  templateUrl: './cost-basis-control.component.html',
  styleUrls: ['./cost-basis-control.component.scss'],
})
export class CostBasisControlComponent implements OnInit, OnDestroy {
  // Decorators...
  @Input() isChangeOrder = false;

  @ViewChild(CostBasisLotErrorModalComponent)
  costBasisLotLoadErrorModal: CostBasisLotErrorModalComponent | undefined;

  // Public variables...
  content: JsonContent = content;
  methodIsAvgCost = false;
  costBasisUnavailableForHolding = false;
  costBasisUrl = environment.costBasisUrl;
  isBeacon = false;
  allowSpecId = false;
  isSpecIdDisclaimerVisible = false;

  // Observables/subjects...
  currentPosition$: Observable<Holding>;
  costBasis$: Observable<CostBasisState>;
  isSellingAllHeldShares$: Observable<boolean>;
  selectedAccount$: Observable<Account.Account>;
  isSpecIdAllowed$: Observable<boolean>;
  isEditCostBasis$: Observable<boolean>;

  // Private observables/subjects...
  private readonly unsubscribe$ = new Subject<void>();
  private readonly methodHasAutoChangedToFIFO$ = new BehaviorSubject<boolean>(false);

  constructor(
    public readonly costBasisService: CostBasisService,
    public readonly tradeTicketService: TradeTicketService,
    public readonly windowService: WindowService,
    private readonly store: Store<TayneState>,
    private readonly sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    // Store selectors...
    this.currentPosition$ = this.store.pipe(select(selectPositionForCurrentQuoteBySecurityType));
    this.costBasis$ = this.store.pipe(select(selectCostBasisState));
    this.isSellingAllHeldShares$ = this.store.pipe(select(selectIsSellingAllHeldShares));
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.isBeacon = this.windowService.getIsBeacon();
    this.isSpecIdAllowed$ = this.store.pipe(select(selectIsSpecIdAllowed));
    this.isEditCostBasis$ = this.store.pipe(select(selectIsEditCostBasisState));

    // Watchers...
    this.watchForCostBasisAutoSelect();
    this.watchForCostBasisSelectionModalAutoLaunch();

    // Error handlers...
    this.watchForCostBasisLotLoadError();
    this.watchForCostBasisUnavailableForHolding();

    this.updateDisclaimerVisibility();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadCostBasisMethods() {
    this.store.dispatch(createLoadAvailableMethodsAction());
  }

  emitCostBasisErrorModal(): void {
    const isErrorModalEligible = !this.sessionStorageService.get(
      SessionStorageKeys.AUTO_LAUNCHED_COST_BASIS_ERROR_MODAL
    );

    if (isErrorModalEligible) {
      this.costBasisService.openUnavailableModal$.next();
      this.sessionStorageService.set(SessionStorageKeys.AUTO_LAUNCHED_COST_BASIS_ERROR_MODAL, true);
    }
  }

  /**
   * Private method to handle the auto selection of cost basis method.
   *
   * @remarks This method is used to determine the cost basis method to be set to the trade ticket.
   * @remarks It also checks if the method has changed to FIFO and if the method is average cost.
   */
  private watchForCostBasisAutoSelect() {
    this.store
      .pipe(
        delay(0), // this is needed to match the one in trade-ticket.service / watchForTradeTicketValueChanges
        select(selectTradeTicket),
        filter(
          () =>
            // Angular's change detection mechanism may not immediately unsubscribe from the observable
            // when the component is destroyed. This check is to prevent any further processing.
            this.tradeTicketService.costBasisIsVisible
        )
      )
      .pipe(
        withLatestFrom(
          this.isSellingAllHeldShares$,
          this.store.pipe(select(selectOrigOrder)),
          this.store.pipe(select(selectCostBasisMethodByPosition)),
          this.isEditCostBasis$
        ),
        tap(([tradeTicket, isSellAll, order, costBasisByPosition]) => {
          // get the cost basis method to be set to the trade ticket
          const costBasisMethod = CostBasisUtil.getCostBasisMethod(this.isChangeOrder, {
            fromOrder: order?.costBasisMethod,
            fromTicket: tradeTicket?.costBasisMethod,
            fromPosition: costBasisByPosition,
          });

          // local variable, for now used to show UI message for avgCost, and hide the edit cost button
          this.methodIsAvgCost = CostBasisUtil.methodIsAvgCost(costBasisMethod);

          // before setting the method to the trade ticket, check if the method needs to be changed to FIFO
          const costBasisMethodToSet = CostBasisUtil.checkAndChangeMethodToFIFO(
            costBasisMethod,
            tradeTicket?.amountType,
            isSellAll
          );

          // set the cost basis method to the trade ticket
          if (this.tradeTicketService.actionIsBuyOrSellShort() && costBasisMethod !== null) {
            this.tradeTicketService.setCostBasisMethod(null);
          } else {
            this.tradeTicketService.setCostBasisMethod(costBasisMethodToSet.method);
          }

          if (costBasisMethodToSet.methodChanged) {
            this.methodHasAutoChangedToFIFO$.next(true);
          }
        }),
        tap(([_tradeTicket, _isSellAll, _order, _costBasisByPosition, isEditCostBasis]) => {
          // user should not be able submit Order with Spec ID for not Market Order
          this.allowSpecId = this.isSpecIdAndNotMarketOrder() && !isEditCostBasis;

          if (this.allowSpecId) {
            this.tradeTicketService.setCostBasisMethod(null, true);
          }
        }),
        tap(() => this.updateDisclaimerVisibility()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  /**
   * Private method to watch for the cost basis selection modal auto launch.
   *
   * @remarks The modal should be auto launched if the method has changed to FIFO,
   * or the selected default method is not set.
   *
   */
  private watchForCostBasisSelectionModalAutoLaunch() {
    this.isSellingAllHeldShares$
      .pipe(
        delay(0), // this is needed to match the one in trade-ticket.service / watchForTradeTicketValueChanges
        filter(
          () =>
            // Angular's change detection mechanism may not immediately unsubscribe from the observable
            // when the component is destroyed. This check is to prevent any further processing.
            this.tradeTicketService.costBasisIsVisible
        ),
        // Don't auto pop up the selection modal if it's change order page
        filter(() => !this.isChangeOrder),
        withLatestFrom(
          this.methodHasAutoChangedToFIFO$,
          this.store.pipe(select(selectUpdateDefaultCostBasis)),
          this.store.pipe(select(selectClientSelectedDefaultMethodForCurrentPosition)),
          this.store.pipe(select(selectTradeMethodsForCurrentPosition)),
          this.store.pipe(select(selectPositionForCurrentQuoteBySecurityType))
        ),
        // Auto pop up the selection modal, if:
        // -- the method is not the default method for the current position
        // -- AND the user is not selling all held shares
        // -- AND the "set as preferred" is not selected
        // -- AND we don't have the trade methods for that holding in the store
        // -- AND there is a holding
        // -- OR the method has auto changed to FIFO
        filter(
          ([
            isSellingAll,
            hasMethodChangedToFIFO,
            isAsPreferredSelected,
            isDefaultSelectedMethod,
            tradeMethods,
            holding,
          ]) => {
            return (
              (!isDefaultSelectedMethod && !isSellingAll && !isAsPreferredSelected && !tradeMethods && !!holding) ||
              hasMethodChangedToFIFO
            );
          }
        ),
        tap(() => this.loadCostBasisMethods()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForCostBasisLotLoadError() {
    this.costBasis$
      .pipe(
        delay(0), // this is needed to match the one in trade-ticket.service / watchForTradeTicketValueChanges
        filter(
          () =>
            // Angular's change detection mechanism may not immediately unsubscribe from the observable
            // when the component is destroyed. This check is to prevent any further processing.
            this.tradeTicketService.costBasisIsVisible
        ),
        debounceTime(200),
        filter((costBasisState) => costBasisState.lotsLoadError !== null),
        tap(() => this.costBasisLotLoadErrorModal.open()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForCostBasisUnavailableForHolding() {
    this.currentPosition$
      .pipe(
        delay(0), // this is needed to match the one in trade-ticket.service / watchForTradeTicketValueChanges
        filter(
          () =>
            // Angular's change detection mechanism may not immediately unsubscribe from the observable
            // when the component is destroyed. This check is to prevent any further processing.
            this.tradeTicketService.costBasisIsVisible
        ),
        filter((position) => !!position),
        withLatestFrom(this.store.pipe(select(selectCostBasisMethodByPosition))),
        tap(([_position, costBasisForPosition]) => {
          this.costBasisUnavailableForHolding = !costBasisForPosition;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private isSpecIdAndNotMarketOrder(): boolean {
    return this.tradeTicketService.specIdIsSelected() && !this.tradeTicketService.orderTypeIsMarket();
  }

  private updateDisclaimerVisibility(): void {
    this.isSpecIdDisclaimerVisible = this.isSpecIdAndNotMarketOrder();
  }
}
