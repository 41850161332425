"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProspectusEligibilityQuerySecurityIdType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ProspectusEligibilityQuerySecurityIdType;
(function (ProspectusEligibilityQuerySecurityIdType) {
  ProspectusEligibilityQuerySecurityIdType["CUSIP"] = "CUSIP";
  ProspectusEligibilityQuerySecurityIdType["VANGUARD_FUND_ID"] = "VANGUARD_FUND_ID";
  ProspectusEligibilityQuerySecurityIdType["LITERATURE_ID"] = "LITERATURE_ID";
})(ProspectusEligibilityQuerySecurityIdType = exports.ProspectusEligibilityQuerySecurityIdType || (exports.ProspectusEligibilityQuerySecurityIdType = {}));
