"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FiveTwentyNineAccountAccountTypeEnum = exports.FiveTwentyNineAccountRetirementPlanTypeCodeEnum = exports.FiveTwentyNineAccountPendingManagementTypeEnum = exports.FiveTwentyNineAccountProductTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var FiveTwentyNineAccountProductTypeEnum;
(function (FiveTwentyNineAccountProductTypeEnum) {
  FiveTwentyNineAccountProductTypeEnum["MUTUAL_FUND"] = "MUTUAL_FUND";
  FiveTwentyNineAccountProductTypeEnum["MUTUAL_FUND_RETIREMENT"] = "MUTUAL_FUND_RETIREMENT";
  FiveTwentyNineAccountProductTypeEnum["BROKERAGE"] = "BROKERAGE";
  FiveTwentyNineAccountProductTypeEnum["LEGACY_BROKERAGE"] = "LEGACY_BROKERAGE";
  FiveTwentyNineAccountProductTypeEnum["VANGUARD_529"] = "VANGUARD_529";
  FiveTwentyNineAccountProductTypeEnum["RETIREMENT_403_B"] = "RETIREMENT_403B";
  FiveTwentyNineAccountProductTypeEnum["OUTSIDE_MANUAL_INVESTMENTS"] = "OUTSIDE_MANUAL_INVESTMENTS";
  FiveTwentyNineAccountProductTypeEnum["OUTSIDE_AUTOMATIC_INVESTMENTS"] = "OUTSIDE_AUTOMATIC_INVESTMENTS";
  FiveTwentyNineAccountProductTypeEnum["PARTICIPANT"] = "PARTICIPANT";
  FiveTwentyNineAccountProductTypeEnum["SMALL_MARKET_401_K"] = "SMALL_MARKET_401K";
})(FiveTwentyNineAccountProductTypeEnum = exports.FiveTwentyNineAccountProductTypeEnum || (exports.FiveTwentyNineAccountProductTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var FiveTwentyNineAccountPendingManagementTypeEnum;
(function (FiveTwentyNineAccountPendingManagementTypeEnum) {
  FiveTwentyNineAccountPendingManagementTypeEnum["OFFBOARDED"] = "OFFBOARDED";
  FiveTwentyNineAccountPendingManagementTypeEnum["SELF_MANAGED"] = "SELF_MANAGED";
  FiveTwentyNineAccountPendingManagementTypeEnum["HYPOTHETICALLY_MANAGED"] = "HYPOTHETICALLY_MANAGED";
  FiveTwentyNineAccountPendingManagementTypeEnum["PRE_MANAGED"] = "PRE_MANAGED";
})(FiveTwentyNineAccountPendingManagementTypeEnum = exports.FiveTwentyNineAccountPendingManagementTypeEnum || (exports.FiveTwentyNineAccountPendingManagementTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var FiveTwentyNineAccountRetirementPlanTypeCodeEnum;
(function (FiveTwentyNineAccountRetirementPlanTypeCodeEnum) {
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["TYPE_403_B7_0015"] = "TYPE_403B7_0015";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["TYPE_529_PLAN_0100"] = "TYPE_529_PLAN_0100";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["EDUCATION_IRA_0021"] = "EDUCATION_IRA_0021";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["INDIVIDUAL_0000"] = "INDIVIDUAL_0000";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["IRA_BENEFICIARY_0016"] = "IRA_BENEFICIARY_0016";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["IRA_ROLLOVER_0002"] = "IRA_ROLLOVER_0002";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["KEOGH_0004"] = "KEOGH_0004";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["MONEY_PURCHASE_0014"] = "MONEY_PURCHASE_0014";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["MP_CONTRIB_0005"] = "MP_CONTRIB_0005";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["MP_DEDUCTIBLE_VOL_0007"] = "MP_DEDUCTIBLE_VOL_0007";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["MP_NON_DEDUCTIBLE_0006"] = "MP_NON_DEDUCTIBLE_0006";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["MP_ROLLOVER_0008"] = "MP_ROLLOVER_0008";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["PROFIT_SHARING_0013"] = "PROFIT_SHARING_0013";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["PS_CONTRIB_0009"] = "PS_CONTRIB_0009";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["PS_DEDUCTIBLE_VOL_0011"] = "PS_DEDUCTIBLE_VOL_0011";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["PS_NON_DEDUCTIBLE_0010"] = "PS_NON_DEDUCTIBLE_0010";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["PS_ROLLOVER_0012"] = "PS_ROLLOVER_0012";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["RETIREMENT_PLANS_0099"] = "RETIREMENT_PLANS_0099";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["ROTH_IRA_0017"] = "ROTH_IRA_0017";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["ROTH_IRA_0020"] = "ROTH_IRA_0020";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["ROTH_IRA_INHERITED_0019"] = "ROTH_IRA_INHERITED_0019";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["SEP_IRA_0003"] = "SEP_IRA_0003";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["SIMPLE_IRA_0018"] = "SIMPLE_IRA_0018";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["TRADITIONAL_IRA_0001"] = "TRADITIONAL_IRA_0001";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["VFTC_INDIVIDUAL_401_K_0022"] = "VFTC_INDIVIDUAL_401K_0022";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["VFTC_INDIVIDUAL_ROTH_401_K_0023"] = "VFTC_INDIVIDUAL_ROTH_401K_0023";
  FiveTwentyNineAccountRetirementPlanTypeCodeEnum["UNKNOWN"] = "UNKNOWN";
})(FiveTwentyNineAccountRetirementPlanTypeCodeEnum = exports.FiveTwentyNineAccountRetirementPlanTypeCodeEnum || (exports.FiveTwentyNineAccountRetirementPlanTypeCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var FiveTwentyNineAccountAccountTypeEnum;
(function (FiveTwentyNineAccountAccountTypeEnum) {
  FiveTwentyNineAccountAccountTypeEnum["INDIVIDUAL"] = "INDIVIDUAL";
  FiveTwentyNineAccountAccountTypeEnum["JOINT"] = "JOINT";
  FiveTwentyNineAccountAccountTypeEnum["JOINT_COMMUNITY_PROPERTY"] = "JOINT_COMMUNITY_PROPERTY";
  FiveTwentyNineAccountAccountTypeEnum["JOINT_COMMUNITY_PROPERTY_WROS"] = "JOINT_COMMUNITY_PROPERTY_WROS";
  FiveTwentyNineAccountAccountTypeEnum["JOINT_MARITAL_PROPERTY"] = "JOINT_MARITAL_PROPERTY";
  FiveTwentyNineAccountAccountTypeEnum["JOINT_MARITAL_PROPERTY_WROS"] = "JOINT_MARITAL_PROPERTY_WROS";
  FiveTwentyNineAccountAccountTypeEnum["JOINT_TENANTS_BY_ENTIRETY_WROS"] = "JOINT_TENANTS_BY_ENTIRETY_WROS";
  FiveTwentyNineAccountAccountTypeEnum["JOINT_TENANTS_IN_COMMON"] = "JOINT_TENANTS_IN_COMMON";
  FiveTwentyNineAccountAccountTypeEnum["JOINT_TENANTS_WROS"] = "JOINT_TENANTS_WROS";
  FiveTwentyNineAccountAccountTypeEnum["JOINT_TENANTS_ENTIRETY_WROS"] = "JOINT_TENANTS_ENTIRETY_WROS";
  FiveTwentyNineAccountAccountTypeEnum["TRUST"] = "TRUST";
  FiveTwentyNineAccountAccountTypeEnum["UNDER_WILL_TRUST"] = "UNDER_WILL_TRUST";
  FiveTwentyNineAccountAccountTypeEnum["IIG_TRUST"] = "IIG_TRUST";
  FiveTwentyNineAccountAccountTypeEnum["BANKRUPTCY_TRUST"] = "BANKRUPTCY_TRUST";
  FiveTwentyNineAccountAccountTypeEnum["LIVING_TRUST"] = "LIVING_TRUST";
  FiveTwentyNineAccountAccountTypeEnum["GRANTOR_TRUST"] = "GRANTOR_TRUST";
  FiveTwentyNineAccountAccountTypeEnum["ROLLOVER_IRA"] = "ROLLOVER_IRA";
  FiveTwentyNineAccountAccountTypeEnum["TRADITIONAL_IRA"] = "TRADITIONAL_IRA";
  FiveTwentyNineAccountAccountTypeEnum["SIMPLE_IRA"] = "SIMPLE_IRA";
  FiveTwentyNineAccountAccountTypeEnum["SEP_IRA"] = "SEP_IRA";
  FiveTwentyNineAccountAccountTypeEnum["SEP_IRA_SINGLE_PARTICIPANT"] = "SEP_IRA_SINGLE_PARTICIPANT";
  FiveTwentyNineAccountAccountTypeEnum["SEP_IRA_MULTIPLE_PARTICIPANT"] = "SEP_IRA_MULTIPLE_PARTICIPANT";
  FiveTwentyNineAccountAccountTypeEnum["ROTH_IRA"] = "ROTH_IRA";
  FiveTwentyNineAccountAccountTypeEnum["INHERITED_IRA"] = "INHERITED_IRA";
  FiveTwentyNineAccountAccountTypeEnum["INHERITED_ROTH_IRA"] = "INHERITED_ROTH_IRA";
  FiveTwentyNineAccountAccountTypeEnum["CONVERSION_ROTH_IRA"] = "CONVERSION_ROTH_IRA";
  FiveTwentyNineAccountAccountTypeEnum["UGMA"] = "UGMA";
  FiveTwentyNineAccountAccountTypeEnum["UTMA"] = "UTMA";
  FiveTwentyNineAccountAccountTypeEnum["KEOGH"] = "KEOGH";
  FiveTwentyNineAccountAccountTypeEnum["ROLLOVER_MPPP"] = "ROLLOVER_MPPP";
  FiveTwentyNineAccountAccountTypeEnum["AMENDED_MPPP"] = "AMENDED_MPPP";
  FiveTwentyNineAccountAccountTypeEnum["CONTRIB_PSP"] = "CONTRIB_PSP";
  FiveTwentyNineAccountAccountTypeEnum["NON_DEDUCTIBLE_PSP"] = "NON_DEDUCTIBLE_PSP";
  FiveTwentyNineAccountAccountTypeEnum["DEDUCTIBLE_MPPP"] = "DEDUCTIBLE_MPPP";
  FiveTwentyNineAccountAccountTypeEnum["CONTRIB_MPPP"] = "CONTRIB_MPPP";
  FiveTwentyNineAccountAccountTypeEnum["NON_DEDUCTIBLE_MPPP"] = "NON_DEDUCTIBLE_MPPP";
  FiveTwentyNineAccountAccountTypeEnum["DEDUCTIBLE_PSP"] = "DEDUCTIBLE_PSP";
  FiveTwentyNineAccountAccountTypeEnum["ROLLOVER_PSP"] = "ROLLOVER_PSP";
  FiveTwentyNineAccountAccountTypeEnum["AMENDED_PSP"] = "AMENDED_PSP";
  FiveTwentyNineAccountAccountTypeEnum["NON_ERISA_403_B7"] = "NON_ERISA_403B7";
  FiveTwentyNineAccountAccountTypeEnum["ERISA_403_B7"] = "ERISA_403B7";
  FiveTwentyNineAccountAccountTypeEnum["EDUCATION_SAVINGS"] = "EDUCATION_SAVINGS";
  FiveTwentyNineAccountAccountTypeEnum["INDIVIDUAL_401_K"] = "INDIVIDUAL_401K";
  FiveTwentyNineAccountAccountTypeEnum["INDIVIDUAL_401_K_SUSPENSE"] = "INDIVIDUAL_401K_SUSPENSE";
  FiveTwentyNineAccountAccountTypeEnum["ROTH_INDIVIDUAL_401_K"] = "ROTH_INDIVIDUAL_401K";
  FiveTwentyNineAccountAccountTypeEnum["SMALL_MARKET_401_K"] = "SMALL_MARKET_401K";
  FiveTwentyNineAccountAccountTypeEnum["AMENDED_VRP"] = "AMENDED_VRP";
  FiveTwentyNineAccountAccountTypeEnum["GUST_AMENDED_VRP"] = "GUST_AMENDED_VRP";
  FiveTwentyNineAccountAccountTypeEnum["POOLED_VRIP"] = "POOLED_VRIP";
  FiveTwentyNineAccountAccountTypeEnum["VRIP"] = "VRIP";
  FiveTwentyNineAccountAccountTypeEnum["VANGUARD_BUSINESS_ACCOUNT_SERVICE"] = "VANGUARD_BUSINESS_ACCOUNT_SERVICE";
  FiveTwentyNineAccountAccountTypeEnum["FIDUCIARY"] = "FIDUCIARY";
  FiveTwentyNineAccountAccountTypeEnum["CUSTODIAN"] = "CUSTODIAN";
  FiveTwentyNineAccountAccountTypeEnum["CUSTODIAN_MINOR"] = "CUSTODIAN_MINOR";
  FiveTwentyNineAccountAccountTypeEnum["ATTORNEY_IN_FACT"] = "ATTORNEY_IN_FACT";
  FiveTwentyNineAccountAccountTypeEnum["USUFRUCT"] = "USUFRUCT";
  FiveTwentyNineAccountAccountTypeEnum["GUARDIAN"] = "GUARDIAN";
  FiveTwentyNineAccountAccountTypeEnum["ESCROW_AGENT"] = "ESCROW_AGENT";
  FiveTwentyNineAccountAccountTypeEnum["HOSPITAL"] = "HOSPITAL";
  FiveTwentyNineAccountAccountTypeEnum["SCHOOL_DISTRICT"] = "SCHOOL_DISTRICT";
  FiveTwentyNineAccountAccountTypeEnum["ENDOWMENT"] = "ENDOWMENT";
  FiveTwentyNineAccountAccountTypeEnum["DEFINED_BENEFIT_PENSION_PLAN"] = "DEFINED_BENEFIT_PENSION_PLAN";
  FiveTwentyNineAccountAccountTypeEnum["ESTATE"] = "ESTATE";
  FiveTwentyNineAccountAccountTypeEnum["EXECUTOR_ESTATE"] = "EXECUTOR_ESTATE";
  FiveTwentyNineAccountAccountTypeEnum["LIFE_TENANT_ESTATE"] = "LIFE_TENANT_ESTATE";
  FiveTwentyNineAccountAccountTypeEnum["BANK_NOMINEE"] = "BANK_NOMINEE";
  FiveTwentyNineAccountAccountTypeEnum["PARTNERSHIP"] = "PARTNERSHIP";
  FiveTwentyNineAccountAccountTypeEnum["IIG_CORPORATION"] = "IIG_CORPORATION";
  FiveTwentyNineAccountAccountTypeEnum["CORPORATION"] = "CORPORATION";
  FiveTwentyNineAccountAccountTypeEnum["CORPORATION_BANKRUPTCY"] = "CORPORATION_BANKRUPTCY";
  FiveTwentyNineAccountAccountTypeEnum["EXEMPT_UNINCORPORATED_ORGANIZATION"] = "EXEMPT_UNINCORPORATED_ORGANIZATION";
  FiveTwentyNineAccountAccountTypeEnum["NONEXEMPT_UNINCORPORATED_ORGANIZATION"] = "NONEXEMPT_UNINCORPORATED_ORGANIZATION";
  FiveTwentyNineAccountAccountTypeEnum["FOUNDATION"] = "FOUNDATION";
  FiveTwentyNineAccountAccountTypeEnum["NONEXEMPT_UNINCOPORATED_ORGANIZATOIN"] = "NONEXEMPT_UNINCOPORATED_ORGANIZATOIN";
  FiveTwentyNineAccountAccountTypeEnum["BROKER_DEALER"] = "BROKER_DEALER";
  FiveTwentyNineAccountAccountTypeEnum["PROFESSIONAL_CORPORATION_ASSOCIATION"] = "PROFESSIONAL_CORPORATION_ASSOCIATION";
  FiveTwentyNineAccountAccountTypeEnum["PROFESSIONAL_CORPORATION"] = "PROFESSIONAL_CORPORATION";
  FiveTwentyNineAccountAccountTypeEnum["PROFESSIONAL_ASSOCIATION"] = "PROFESSIONAL_ASSOCIATION";
  FiveTwentyNineAccountAccountTypeEnum["SOLE_PROPRIETORSHIP"] = "SOLE_PROPRIETORSHIP";
  FiveTwentyNineAccountAccountTypeEnum["RETIREMENT_TRUST_PSP"] = "RETIREMENT_TRUST_PSP";
  FiveTwentyNineAccountAccountTypeEnum["RETIREMENT_TRUST_401_K"] = "RETIREMENT_TRUST_401K";
  FiveTwentyNineAccountAccountTypeEnum["RETIREMENT_TRUST_403_B7"] = "RETIREMENT_TRUST_403B7";
  FiveTwentyNineAccountAccountTypeEnum["RETIREMENT_TRUST_457"] = "RETIREMENT_TRUST_457";
  FiveTwentyNineAccountAccountTypeEnum["RETIREMENT_TRUST_PENSION_PLAN"] = "RETIREMENT_TRUST_PENSION_PLAN";
  FiveTwentyNineAccountAccountTypeEnum["RETIREMENT_TRUST_MPPP"] = "RETIREMENT_TRUST_MPPP";
  FiveTwentyNineAccountAccountTypeEnum["RETIREMENT_TRUST_401_K_PSP"] = "RETIREMENT_TRUST_401K_PSP";
  FiveTwentyNineAccountAccountTypeEnum["RETIREMENT_TRUST_DEFERRED_COMPENSATION"] = "RETIREMENT_TRUST_DEFERRED_COMPENSATION";
  FiveTwentyNineAccountAccountTypeEnum["AUDIT_97"] = "AUDIT_97";
  FiveTwentyNineAccountAccountTypeEnum["AUDIT_98"] = "AUDIT_98";
  FiveTwentyNineAccountAccountTypeEnum["PARTICIPANT"] = "PARTICIPANT";
  FiveTwentyNineAccountAccountTypeEnum["OTHER"] = "OTHER";
  FiveTwentyNineAccountAccountTypeEnum["SUNGARD"] = "SUNGARD";
  FiveTwentyNineAccountAccountTypeEnum["OUTSIDE_HOLDINGS"] = "OUTSIDE_HOLDINGS";
  FiveTwentyNineAccountAccountTypeEnum["OUTSIDE_INVESTMENT"] = "OUTSIDE_INVESTMENT";
  FiveTwentyNineAccountAccountTypeEnum["RETIREMENT_403_B_PLAN"] = "RETIREMENT_403B_PLAN";
  FiveTwentyNineAccountAccountTypeEnum["VANGUARD_529_PLAN"] = "VANGUARD_529_PLAN";
  FiveTwentyNineAccountAccountTypeEnum["LIMITED_PARTNER"] = "LIMITED_PARTNER";
  FiveTwentyNineAccountAccountTypeEnum["GENERAL_PARTNER"] = "GENERAL_PARTNER";
  FiveTwentyNineAccountAccountTypeEnum["UNKNOWN"] = "UNKNOWN";
})(FiveTwentyNineAccountAccountTypeEnum = exports.FiveTwentyNineAccountAccountTypeEnum || (exports.FiveTwentyNineAccountAccountTypeEnum = {}));
