import { CollectorEnvironment, CollectorLocation } from '@vanguard/invest-otel-lib/common';

export const environment = {
  production: true,
  otelCollectorEnvironment: CollectorEnvironment.PRODUCTION,
  otelCollectorLocation: CollectorLocation.PUBLIC, // prod is deployed externally, so use PUBLIC collector
  apiUrl: 'https://webservices.ecs.retp.c1.vanguard.com/twe-trade-web-equity-etf/',
  apiInactiveUrlUsEast1: 'https://api-inactive.ecs.us-east-1.retp.c1.vanguard.com/twe-trade-web-equity-etf/',
  apiInactiveUrlUsWest2: 'https://api-inactive.ecs.us-west-2.retp.c1.vanguard.com/twe-trade-web-equity-etf/',
  adobeEnvironment: 'production',
  deployment: 'prod',
  mc3StaticUrl: 'https://mc3-static-assets.web.vanguard.com/vg-ccc-serverless/latest/vg-ccc-serverless.js',
  medalliaUrl:
    'https://mdl-medallia-digital.vanguard.com/pkg/1.0.0/web/external_libs/wdcusprem/2940/onsite/medallia-digital-embed.js',
  mfeAccountSelector: {
    js: 'https://tradecx.web.vanguard.com/mfes/account-selector/6/web-components/index.js',
    style: 'https://tradecx.web.vanguard.com/mfes/account-selector/6/web-components/index.css',
  },
  mfeCrewDisclaimer: {
    js: 'https://tradecx.web.vanguard.com/mfes/crew-disclaimer/1/web-components/index.js',
    style: 'https://tradecx.web.vanguard.com/mfes/crew-disclaimer/1/web-components/index.css',
  },
  mfeProductSearch: {
    js: 'https://tradecx.web.vanguard.com/mfes/product-search/1/web-components/index.js',
    style: 'https://tradecx.web.vanguard.com/mfes/product-search/1/web-components/index.css',
  },
  secureSiteUrls: {
    balancesHoldings: 'https://holdings.web.vanguard.com',
    orderStatus: 'https://order-status.web.vanguard.com',
    investHub: 'https://investhub.web.vanguard.com',
    dashboard: 'https://dashboard.web.vanguard.com',
  },
  prospectus: {
    audit: 'https://personal1.vanguard.com/plu-prospectus/audit.js',
    endpointPath: 'https://personal1.vanguard.com/plu-prospectus/prospectus-edelivery-evidence/',
    baseUrl: 'https://personal1.vanguard.com/plu-prospectus/prospectus',
  },
  tealeaf: 'https://webservices.ecs.retp.c1.vanguard.com/twe-trade-web-equity-etf/tealeaf',
  vgn: {
    styles: `https://n4v.web.vanguard.com/nav-preload.css`,
    js: `https://n4v.web.vanguard.com/secure-site-components.js`,
  },
  serverlessUrl: 'https://etfs-stocks.web.vanguard.com',
  buySellRouterUrl: 'https://personal.vanguard.com/us/BuySellRouter?investmentType=EQUITY&nonRetirementMode=true',
  buySellRouterUrlCancel:
    'https://personal.vanguard.com/us/XHTML/com/vanguard/retail/web/cancelorder/view/CancelOrder.xhtml?orderStatusNGA=true',
  secureMessagesUrl: 'https://secure-messages.web.vanguard.com/',
  vgaInvestApiUrl: 'https://webservices.ecs.retp.c1.vanguard.com/vga-invest-api',
  logonURL: 'https://logon.vanguard.com/logon?site=pi',
  aizWebServiceURL: 'https://webservices.ecs.retp.c1.vanguard.com/aiz-auto-invest-etf-webservice',
  graphQLDomain: 'https://retaemdisp.retp.c1.vanguard.com/graphql/execute.json',
  appSchedulerUrl: 'https://personal1.vanguard.com/pqq-appointment-scheduler-user-interface/landing',
  investorDomain: 'https://investor.vanguard.com',
  costBasisUrl: 'https://cost-basis-method.web.vanguard.com/edit?anchorAcctId=',
  legacyTradePath: 'https://personal.vanguard.com/us/buysell/web/tradeticket/TradeTicket.xhtml?investmentType=EQUITY',
};
