import { Component, OnDestroy, OnInit } from '@angular/core';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { Account, JsonContent, OpenOrdersResponse } from '@app/etfs-equities/models';
import { AccountService, TradeTicketService } from '@app/etfs-equities/services';
import {
  createClearOrderAction,
  createClearQuoteAction,
  createClearTradeTicketAction,
  createSetIsChangeOrderAction,
  createUpdateTradeTicketTabLinkAction,
  selectCostBasisEligible,
  selectCostBasisForSelectedAccount,
  selectIsChangeOrderState,
  selectSelectedAccount,
  TayneState,
} from '@app/etfs-equities/store';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { TimestampSize } from '@vanguard/trade-ui-components-lib-ng-17';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'twe-open-orders',
  templateUrl: './open-orders-page.component.html',
  styleUrls: ['./open-orders-page.component.scss'],
})
export class OpenOrdersPageComponent implements OnInit, OnDestroy {
  //  Public observables/subjects...

  selectedAccount$: Observable<Account.Account>;
  openOrders$: Observable<OpenOrdersResponse>;
  unsubscribe$ = new Subject<void>();
  costBasisEligible$: Observable<boolean>;

  //  Public variables...

  content: JsonContent = content;
  hasAccountRetrievalError = false;
  protected readonly timestampSizeEnum = TimestampSize;

  constructor(
    public accountService: AccountService,
    private readonly store: Store<TayneState>,
    private readonly tradeTicketService: TradeTicketService
  ) {}

  // Getters/Setters...

  get isOpenOrdersLoading() {
    return this.accountService.isLoadingOpenOrders;
  }

  get orderRetrievalFailed(): boolean {
    return this.accountService.orderRetrievalFailed;
  }

  setHasAccountRetrievalError(hasError: boolean) {
    this.hasAccountRetrievalError = hasError;
  }

  ngOnInit() {
    this.selectedAccount$ = this.store.select(selectSelectedAccount);
    this.costBasisEligible$ = this.store.select(selectCostBasisEligible);

    this.selectedAccount$
      .pipe(
        withLatestFrom(this.store.select(selectCostBasisForSelectedAccount)),
        filter(([account]) => !!account),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([account, costBasisAccount]) => {
        // when cost basis data unknown or not available, assume account is cost basis eligible
        const costBasisEligible =
          costBasisAccount?.accountId === account.accountId ? costBasisAccount.tradeCostBasisEligible || false : true;
        this.openOrders$ = this.accountService.fetchOpenOrdersForAccount(account, costBasisEligible);
      });

    this.store
      .pipe(select(selectIsChangeOrderState))
      .pipe(
        tap((isChangeOrder) => {
          // when client clicks Change order button from Open Orders page and selects Keep order or clicks back, clear change order
          if (isChangeOrder) {
            this.store.dispatch(createSetIsChangeOrderAction(false));
            this.store.dispatch(createUpdateTradeTicketTabLinkAction(CONSTANTS.TRADE_PATH, null));
            this.store.dispatch(createClearQuoteAction());
            this.store.dispatch(createClearOrderAction());
            this.store.dispatch(createClearTradeTicketAction());
            this.tradeTicketService.resetForm();
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  refreshOpenOrdersForAccount(account: Account.Account, costBasisEligible: boolean): void {
    this.openOrders$ = this.accountService.refreshOpenOrdersForAccount(account, costBasisEligible);
  }

  onChildActionBtnClick() {
    this.tradeTicketService.resetForm();
  }
}
