"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TradeMethodsResponseAvailableTradingMethodsEnum = exports.TradeMethodsResponsePreSelectedMethodEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var TradeMethodsResponsePreSelectedMethodEnum;
(function (TradeMethodsResponsePreSelectedMethodEnum) {
  TradeMethodsResponsePreSelectedMethodEnum["FIRST_IN_FIRST_OUT"] = "FIRST_IN_FIRST_OUT";
  TradeMethodsResponsePreSelectedMethodEnum["MINIMUM_TAX"] = "MINIMUM_TAX";
  TradeMethodsResponsePreSelectedMethodEnum["SPECIFIC_IDENTIFICATION"] = "SPECIFIC_IDENTIFICATION";
  TradeMethodsResponsePreSelectedMethodEnum["AVERAGE_COST"] = "AVERAGE_COST";
  TradeMethodsResponsePreSelectedMethodEnum["MAXIMUM_GAIN"] = "MAXIMUM_GAIN";
  TradeMethodsResponsePreSelectedMethodEnum["HIGHEST_IN_FIRST_OUT"] = "HIGHEST_IN_FIRST_OUT";
})(TradeMethodsResponsePreSelectedMethodEnum = exports.TradeMethodsResponsePreSelectedMethodEnum || (exports.TradeMethodsResponsePreSelectedMethodEnum = {}));
/**
    * @export
    * @enum {string}
    */
var TradeMethodsResponseAvailableTradingMethodsEnum;
(function (TradeMethodsResponseAvailableTradingMethodsEnum) {
  TradeMethodsResponseAvailableTradingMethodsEnum["FIRST_IN_FIRST_OUT"] = "FIRST_IN_FIRST_OUT";
  TradeMethodsResponseAvailableTradingMethodsEnum["MINIMUM_TAX"] = "MINIMUM_TAX";
  TradeMethodsResponseAvailableTradingMethodsEnum["SPECIFIC_IDENTIFICATION"] = "SPECIFIC_IDENTIFICATION";
  TradeMethodsResponseAvailableTradingMethodsEnum["AVERAGE_COST"] = "AVERAGE_COST";
  TradeMethodsResponseAvailableTradingMethodsEnum["MAXIMUM_GAIN"] = "MAXIMUM_GAIN";
  TradeMethodsResponseAvailableTradingMethodsEnum["HIGHEST_IN_FIRST_OUT"] = "HIGHEST_IN_FIRST_OUT";
})(TradeMethodsResponseAvailableTradingMethodsEnum = exports.TradeMethodsResponseAvailableTradingMethodsEnum || (exports.TradeMethodsResponseAvailableTradingMethodsEnum = {}));
