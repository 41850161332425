export enum GatekeeperFeatureIds {
  TWE_DOLLAR_BASED_TRADES = 'TWE_Dollar_Based_Trades',
  TWE_CHANGE_ORDER = 'TWE_Change_Order',
  TWE_EQUITY_ETF_PATH_MVP = 'TWE_Equity_ETF_Path_MVP',
  TWE_VOLATILITY_BANNER = 'TWE_Volatility_Banner',
  TWE_ONETRUST = 'TWE_OneTrust',
  TWE_CANCEL_ORDER = 'TWE_Order_Cancel',
  TWE_EDIT_COST_BASIS = 'TWE_Edit_Cost_Basis',
  TWE_SERVERLESS = 'TWE_Serverless',
  TWE_ANNOUNCEMENTS_BANNER = 'TWE_Announcements_Banner',
  TOGGLE_EXTENDED_TRADING = 'Extended_Trading',
  TWE_EXTENDED_TRADE_TAB_ALWAYS_ON = 'TWE_Extended_Trade_Tab_Always_On',
  TWE_SERVERLESS_INACTIVE_API = 'TWE_Serverless_Inactive_API',
  TWE_AEM = 'TWE_AEM',
  TWE_INTEGRATION_VGA_OPENORDERS_V2 = 'TWE_Integration_VGA_OpenOrders_V2',
  TWE_INTEGRATION_VGA_ORDERDETAILS_V2 = 'TWE_Integration_VGA_OrderDetails_V2',
  TWE_EQUITY_ETF_PATH = 'TWE_Equity_ETF_Path',
  TWE_BEACON_ONLY_EQUITY_ETF = 'TWE_Beacon_Only_Equity_ETF',
  TWE_CREW_DISCLAIMER_MFE = 'TWE_Crew_Disclaimer_MFE',
  TWE_MODERN_HOLDINGS_TAB = 'Modern_Holdings_Tab',
}
