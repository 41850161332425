@import '../variables';

.#{$namespace}-space {

  $self: &;

  $size-list: (
    '0_5x': $c11n-size-space-0point5x,
    '1x': $c11n-size-space-1x,
    '1_5x': $c11n-size-space-1point5x,
    '2x': $c11n-size-space-2x,
    '2_5x': $c11n-size-space-2point5x,
    '3x': $c11n-size-space-3x,
    '4x': $c11n-size-space-4x,
    '5x': $c11n-size-space-5x,
    '6x': $c11n-size-space-6x,
    '8x': $c11n-size-space-8x,
    '10x': $c11n-size-space-10x,
    '12x': $c11n-size-space-12x,
    '16x': $c11n-size-space-16x,
  );

  @each $key, $value in $size-list {

    &-inset {
      &-#{$key} {
        padding: rem($value);
      }
    }

    &-stack {
      &-#{$key} {
        &:not(:last-child),
        &:last-child#{$self}--force {
          margin-bottom: rem($value);
        }
      }
    }

    &-inline {
      &-right {
        &-#{$key} {
          &:not(:last-child),
          &:last-child#{$self}--force {
            margin-right: rem($value);
          }
        }
      }
      &-left {
        &-#{$key} {
          &:not(:first-child),
          &:last-child#{$self}--force {
            margin-left: rem($value);
          }
        }
      }
    }
  }
}