@use 'sass:math';

@mixin c11n-text-crop(
  $line-height: $c11n-line-height-md,
  $top-adjustment: $c11n-crop-top-adj-md,
  $bottom-adjustment: $c11n-crop-bottom-adj-md
) {
  // Configured in Step 1
  $top-crop: 0.5625rem;
  $bottom-crop: 0.4375rem;
  $crop-font-size: 2.875rem;
  $crop-line-height: 1.04347826;

  // Apply values to calculate em-based margins that work with any font size
  $line-height-dif: $line-height - $crop-line-height;
  $max-top-crop: math.max(($top-crop + ($line-height-dif * ($crop-font-size * 0.5)) ), 0);
  $dynamic-top-crop: math.div($max-top-crop, $crop-font-size);
  $max-bottom-crop: math.max(($bottom-crop + ($line-height-dif * ($crop-font-size * 0.5)) ), 0);
  $dynamic-bottom-crop: math.div($max-bottom-crop, $crop-font-size);
  // Mixin output

  &::before,
  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
  }

  &::before {
    margin-bottom: -0.25rem + $top-adjustment;
  }

  &::after {
    margin-top: -0.25rem + $bottom-adjustment;
  }
}
// Mixin generated at: http://text-crop.eightshapes.com/?typeface-selection=custom-font&typeface=Lato&custom-typeface-name=Mark%20Pro&custom-typeface-url=&custom-typeface-weight=400&custom-typeface-style=normal&weight-and-style=100&size=17&line-height=1.41176471&top-crop=7&bottom-crop=6
// Updated for rem font size values and latest version of sass that no longer supports / for division
/* Usage Examples
    // to get tight cropping for our sm, md, and lg font sizes, follow this pattern

    .my-text-class {
        @include c11n-text-crop($line-height: $line-height-md, $top-adjustment: $c11n-crop-top-adj-md, $bottom-adjustment: $c11n-crop-bottom-adj-md);
        font-size: rem($c11n-size-typography-md);
    }

    .dont-do-this {
        @include c11n-text-crop;
        font-size: 16px;
        line-height: 3; // DO NOT set line height outside of the mixin, the mixin needs the line height value to calculate the crop correctly
    }
*/
@mixin c11n-text-crop-xs() {
  @include c11n-text-crop($line-height: $c11n-line-height-xs, $top-adjustment: $c11n-crop-top-adj-xs, $bottom-adjustment: $c11n-crop-bottom-adj-xs);
}
@mixin c11n-text-crop-sm() {
  @include c11n-text-crop($line-height: $c11n-line-height-sm, $top-adjustment: $c11n-crop-top-adj-sm, $bottom-adjustment: $c11n-crop-bottom-adj-sm);
}
@mixin c11n-text-crop-md() {
  @include c11n-text-crop($line-height: $c11n-line-height-md, $top-adjustment: $c11n-crop-top-adj-md, $bottom-adjustment: $c11n-crop-bottom-adj-md);
}
@mixin c11n-text-crop-md-article() {
  @include c11n-text-crop($line-height: $c11n-line-height-md-article, $top-adjustment: $c11n-crop-top-adj-md-article, $bottom-adjustment: $c11n-crop-bottom-adj-md-article);
}
@mixin c11n-text-crop-lg() {
  @include c11n-text-crop($line-height: $c11n-line-height-lg, $top-adjustment: $c11n-crop-top-adj-lg, $bottom-adjustment: $c11n-crop-bottom-adj-lg);
}
@mixin c11n-text-crop-lg-bold() {
  @include c11n-text-crop($line-height: $c11n-line-height-lg, $top-adjustment: $c11n-crop-top-adj-lg-bold, $bottom-adjustment: $c11n-crop-bottom-adj-lg-bold);
}
@mixin c11n-text-crop-xl() {
  @include c11n-text-crop($line-height: $c11n-line-height-xl, $top-adjustment: $c11n-crop-top-adj-xl, $bottom-adjustment: $c11n-crop-bottom-adj-xl);
}
@mixin c11n-text-crop-xl-headline() {
  @include c11n-text-crop($line-height: $c11n-line-height-xl, $top-adjustment: $c11n-crop-top-adj-xl-headline, $bottom-adjustment: $c11n-crop-bottom-adj-xl-headline);
}
@mixin c11n-text-crop-xl-display() {
  @include c11n-text-crop($line-height: $c11n-line-height-xl, $top-adjustment: $c11n-crop-top-adj-xl-display, $bottom-adjustment: $c11n-crop-bottom-adj-xl-display);
}
@mixin c11n-text-crop-2xl() {
  @include c11n-text-crop($line-height: $c11n-line-height-2xl, $top-adjustment: $c11n-crop-top-adj-2xl, $bottom-adjustment: $c11n-crop-bottom-adj-2xl);
}
@mixin c11n-text-crop-2xl-display() {
  @include c11n-text-crop($line-height: $c11n-line-height-2xl, $top-adjustment: $c11n-crop-top-adj-2xl-display, $bottom-adjustment: $c11n-crop-bottom-adj-2xl-display);
}
@mixin c11n-text-crop-3xl() {
  @include c11n-text-crop($line-height: $c11n-line-height-3xl, $top-adjustment: $c11n-crop-top-adj-3xl, $bottom-adjustment: $c11n-crop-bottom-adj-3xl);
}
@mixin c11n-text-crop-3xl-headline() {
  @include c11n-text-crop($line-height: $c11n-line-height-3xl, $top-adjustment: $c11n-crop-top-adj-3xl-headline, $bottom-adjustment: $c11n-crop-bottom-adj-3xl-headline);
}
@mixin c11n-text-crop-3xl-display() {
  @include c11n-text-crop($line-height: $c11n-line-height-3xl, $top-adjustment: $c11n-crop-top-adj-3xl-display, $bottom-adjustment: $c11n-crop-bottom-adj-3xl-display);
}
@mixin c11n-text-crop-4xl() {
  @include c11n-text-crop($line-height: $c11n-line-height-4xl, $top-adjustment: $c11n-crop-top-adj-4xl, $bottom-adjustment: $c11n-crop-bottom-adj-4xl);
}
@mixin c11n-text-crop-4xl-headline() {
  @include c11n-text-crop($line-height: $c11n-line-height-4xl, $top-adjustment: $c11n-crop-top-adj-4xl-headline, $bottom-adjustment: $c11n-crop-bottom-adj-4xl-headline);
}
@mixin c11n-text-crop-4xl-display() {
  @include c11n-text-crop($line-height: $c11n-line-height-4xl, $top-adjustment: $c11n-crop-top-adj-4xl-display, $bottom-adjustment: $c11n-crop-bottom-adj-4xl-display);
}
@mixin c11n-text-crop-5xl() {
  @include c11n-text-crop($line-height: $c11n-line-height-5xl, $top-adjustment: $c11n-crop-top-adj-5xl, $bottom-adjustment: $c11n-crop-bottom-adj-5xl);
}
@mixin c11n-text-crop-5xl-display() {
  @include c11n-text-crop($line-height: $c11n-line-height-5xl, $top-adjustment: $c11n-crop-top-adj-5xl-display, $bottom-adjustment: $c11n-crop-bottom-adj-5xl-display);
}
@mixin c11n-text-crop-6xl() {
  @include c11n-text-crop($line-height: $c11n-line-height-6xl, $top-adjustment: $c11n-crop-top-adj-6xl, $bottom-adjustment: $c11n-crop-bottom-adj-6xl);
}
@mixin c11n-text-crop-6xl-headline() {
  @include c11n-text-crop($line-height: $c11n-line-height-6xl, $top-adjustment: $c11n-crop-top-adj-6xl-headline, $bottom-adjustment: $c11n-crop-bottom-adj-6xl-headline);
}
@mixin c11n-text-crop-6xl-display() {
  @include c11n-text-crop($line-height: $c11n-line-height-6xl, $top-adjustment: $c11n-crop-top-adj-6xl-display, $bottom-adjustment: $c11n-crop-bottom-adj-6xl-display);
}
