@import '@vg-constellation/angular-17/styles/spacing';
@import '@vg-constellation/angular-17/styles/breakpoints';

// Override the default input text to uppercase
// Default behavior is lower case
twe-keyword-search-control {
  .product-search-container .product-search .c11n-search__form__input-row__input .c11n-input__input {
    text-transform: uppercase;

    &::placeholder {
      text-transform: none;
    }
  }

  .search-toggle-link {
    margin-top: $c11n-size-space-0point5x;
  }
}
