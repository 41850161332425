"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RegistrationComputedTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var RegistrationComputedTypeEnum;
(function (RegistrationComputedTypeEnum) {
  RegistrationComputedTypeEnum["UNKNOWN"] = "Unknown";
  RegistrationComputedTypeEnum["RETIREMENT403B_PLAN"] = "Retirement403bPlan";
  RegistrationComputedTypeEnum["INDIVIDUAL"] = "Individual";
  RegistrationComputedTypeEnum["JOINT_COMMUNITY_PROPERTY"] = "JointCommunityProperty";
  RegistrationComputedTypeEnum["JOINT_COMMUNITY_PROPERTY_WROS"] = "JointCommunityPropertyWROS";
  RegistrationComputedTypeEnum["JOINT_MARITAL_PROPERTY"] = "JointMaritalProperty";
  RegistrationComputedTypeEnum["JOINT_MARITAL_PROPERTY_WROS"] = "JointMaritalPropertyWROS";
  RegistrationComputedTypeEnum["JOINT_TENANTS_BY_ENTIRETY_WROS"] = "JointTenantsByEntiretyWROS";
  RegistrationComputedTypeEnum["JOINT_TENANTS_IN_COMMON"] = "JointTenantsInCommon";
  RegistrationComputedTypeEnum["JOINT_TENANTS_WROS"] = "JointTenantsWROS";
  RegistrationComputedTypeEnum["IIG_TRUST"] = "IigTrust";
  RegistrationComputedTypeEnum["BANKRUPTCY_TRUST"] = "BankruptcyTrust";
  RegistrationComputedTypeEnum["UNDER_WILL_TRUST"] = "UnderWillTrust";
  RegistrationComputedTypeEnum["LIVING_TRUST"] = "LivingTrust";
  RegistrationComputedTypeEnum["GRANTOR_TRUST"] = "GrantorTrust";
  RegistrationComputedTypeEnum["UGMA"] = "Ugma";
  RegistrationComputedTypeEnum["UTMA"] = "Utma";
  RegistrationComputedTypeEnum["AMENDED_VRP"] = "AmendedVrp";
  RegistrationComputedTypeEnum["GUST_AMENDED_VRP"] = "GustAmendedVrp";
  RegistrationComputedTypeEnum["POOLED_VRIP"] = "PooledVrip";
  RegistrationComputedTypeEnum["VRIP"] = "Vrip";
  RegistrationComputedTypeEnum["VANGUARD_BUSINESS_ACCOUNT_SERVICE"] = "VanguardBusinessAccountService";
  RegistrationComputedTypeEnum["FIDUCIARY"] = "Fiduciary";
  RegistrationComputedTypeEnum["CUSTODIAN"] = "Custodian";
  RegistrationComputedTypeEnum["CUSTODIAN_MINOR"] = "CustodianMinor";
  RegistrationComputedTypeEnum["ATTORNEY_IN_FACT"] = "AttorneyInFact";
  RegistrationComputedTypeEnum["USUFRUCT"] = "Usufruct";
  RegistrationComputedTypeEnum["GUARDIAN"] = "Guardian";
  RegistrationComputedTypeEnum["ESCROW_AGENT"] = "EscrowAgent";
  RegistrationComputedTypeEnum["HOSPITAL"] = "Hospital";
  RegistrationComputedTypeEnum["SCHOOL_DISTRICT"] = "SchoolDistrict";
  RegistrationComputedTypeEnum["ENDOWMENT"] = "Endowment";
  RegistrationComputedTypeEnum["DEFINED_BENEFIT_PENSION_PLAN"] = "DefinedBenefitPensionPlan";
  RegistrationComputedTypeEnum["ESTATE"] = "Estate";
  RegistrationComputedTypeEnum["EXECUTOR_ESTATE"] = "ExecutorEstate";
  RegistrationComputedTypeEnum["LIFE_TENANT_ESTATE"] = "LifeTenantEstate";
  RegistrationComputedTypeEnum["BANK_NOMINEE"] = "BankNominee";
  RegistrationComputedTypeEnum["PARTNERSHIP"] = "Partnership";
  RegistrationComputedTypeEnum["IIG_CORPORATION"] = "IigCorporation";
  RegistrationComputedTypeEnum["CORPORATION"] = "Corporation";
  RegistrationComputedTypeEnum["CORPORATION_BANKRUPCY"] = "CorporationBankrupcy";
  RegistrationComputedTypeEnum["EXEMPT_UNINCORPORATED_ORGANIZATION"] = "ExemptUnincorporatedOrganization";
  RegistrationComputedTypeEnum["FOUNDATION"] = "Foundation";
  RegistrationComputedTypeEnum["NONEXEMPT_UNINCORPORATED_ORGANIZATION"] = "NonexemptUnincorporatedOrganization";
  RegistrationComputedTypeEnum["BROKER_DEALER"] = "BrokerDealer";
  RegistrationComputedTypeEnum["PROFESSIONAL_CORPORATION_ASSOCIATION"] = "ProfessionalCorporationAssociation";
  RegistrationComputedTypeEnum["PROFESSIONAL_CORPORATION"] = "ProfessionalCorporation";
  RegistrationComputedTypeEnum["PROFESSIONAL_ASSOCIATION"] = "ProfessionalAssociation";
  RegistrationComputedTypeEnum["SOLE_PROPRIETORSHIP"] = "SoleProprietorship";
  RegistrationComputedTypeEnum["RETIREMENT_TRUST_PSP"] = "RetirementTrustPsp";
  RegistrationComputedTypeEnum["RETIREMENT_TRUST401K"] = "RetirementTrust401k";
  RegistrationComputedTypeEnum["RETIREMENT_TRUST403B7"] = "RetirementTrust403b7";
  RegistrationComputedTypeEnum["RETIREMENT_TRUST457"] = "RetirementTrust457";
  RegistrationComputedTypeEnum["RETIREMENT_TRUST_PENSION_PLAN"] = "RetirementTrustPensionPlan";
  RegistrationComputedTypeEnum["RETIREMENT_TRUST_MPPP"] = "RetirementTrustMppp";
  RegistrationComputedTypeEnum["RETIREMENT_TRUST401K_PSP"] = "RetirementTrust401kPsp";
  RegistrationComputedTypeEnum["RETIREMENT_TRUST_DEFERRED_COMPENSATION"] = "RetirementTrustDeferredCompensation";
  RegistrationComputedTypeEnum["AUDIT98"] = "Audit98";
  RegistrationComputedTypeEnum["TRADITIONAL_IRA"] = "TraditionalIra";
  RegistrationComputedTypeEnum["AUDIT97"] = "Audit97";
  RegistrationComputedTypeEnum["ROLLOVER_IRA"] = "RolloverIra";
  RegistrationComputedTypeEnum["SEP_IRA"] = "SepIra";
  RegistrationComputedTypeEnum["SEP_IRA_SINGLE_PARTICIPANT"] = "SepIraSingleParticipant";
  RegistrationComputedTypeEnum["SEP_IRA_MULTIPLE_PARTICIPANT"] = "SepIraMultipleParticipant";
  RegistrationComputedTypeEnum["KEOGH"] = "Keogh";
  RegistrationComputedTypeEnum["CONTRIB_MPPP"] = "ContribMppp";
  RegistrationComputedTypeEnum["NON_DEDUCTIBLE_MPPP"] = "NonDeductibleMppp";
  RegistrationComputedTypeEnum["DEDUCTIBLE_MPPP"] = "DeductibleMppp";
  RegistrationComputedTypeEnum["ROLLOVER_MPPP"] = "RolloverMppp";
  RegistrationComputedTypeEnum["CONTRIB_PSP"] = "ContribPsp";
  RegistrationComputedTypeEnum["NON_DEDUCTIBLE_PSP"] = "NonDeductiblePsp";
  RegistrationComputedTypeEnum["DEDUCTIBLE_PSP"] = "DeductiblePsp";
  RegistrationComputedTypeEnum["ROLLOVER_PSP"] = "RolloverPsp";
  RegistrationComputedTypeEnum["AMENDED_PSP"] = "AmendedPsp";
  RegistrationComputedTypeEnum["AMENDED_MPPP"] = "AmendedMppp";
  RegistrationComputedTypeEnum["NON_ERISA403B7"] = "NonErisa403b7";
  RegistrationComputedTypeEnum["ERISA403B7"] = "Erisa403b7";
  RegistrationComputedTypeEnum["INHERITED_IRA"] = "InheritedIra";
  RegistrationComputedTypeEnum["ROTH_IRA"] = "RothIra";
  RegistrationComputedTypeEnum["SIMPLE_IRA"] = "SimpleIra";
  RegistrationComputedTypeEnum["INHERITED_ROTH_IRA"] = "InheritedRothIra";
  RegistrationComputedTypeEnum["CONVERSION_ROTH_IRA"] = "ConversionRothIra";
  RegistrationComputedTypeEnum["EDUCATION_SAVINGS"] = "EducationSavings";
  RegistrationComputedTypeEnum["INDIVIDUAL401K"] = "Individual401k";
  RegistrationComputedTypeEnum["INDIVIDUAL401K_SUSPENSE"] = "Individual401kSuspense";
  RegistrationComputedTypeEnum["ROTH_INDIVIDUAL401K"] = "RothIndividual401k";
})(RegistrationComputedTypeEnum = exports.RegistrationComputedTypeEnum || (exports.RegistrationComputedTypeEnum = {}));
