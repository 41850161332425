"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccountRegistrationAccountTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var AccountRegistrationAccountTypeEnum;
(function (AccountRegistrationAccountTypeEnum) {
  AccountRegistrationAccountTypeEnum["UNKNOWN"] = "UNKNOWN";
  AccountRegistrationAccountTypeEnum["AMENDED_MPPP"] = "AMENDED_MPPP";
  AccountRegistrationAccountTypeEnum["AMENDED_PSP"] = "AMENDED_PSP";
  AccountRegistrationAccountTypeEnum["AMENDED_VRP"] = "AMENDED_VRP";
  AccountRegistrationAccountTypeEnum["ATTORNEY_IN_FACT"] = "ATTORNEY_IN_FACT";
  AccountRegistrationAccountTypeEnum["AUDIT_97"] = "AUDIT_97";
  AccountRegistrationAccountTypeEnum["AUDIT_98"] = "AUDIT_98";
  AccountRegistrationAccountTypeEnum["BANKRUPTCY_TRUST"] = "BANKRUPTCY_TRUST";
  AccountRegistrationAccountTypeEnum["BANK_NOMINEE"] = "BANK_NOMINEE";
  AccountRegistrationAccountTypeEnum["BROKER_DEALER"] = "BROKER_DEALER";
  AccountRegistrationAccountTypeEnum["CONTRIB_MPPP"] = "CONTRIB_MPPP";
  AccountRegistrationAccountTypeEnum["CONTRIB_PSP"] = "CONTRIB_PSP";
  AccountRegistrationAccountTypeEnum["CONVERSION_ROTH_IRA"] = "CONVERSION_ROTH_IRA";
  AccountRegistrationAccountTypeEnum["CORPORATION"] = "CORPORATION";
  AccountRegistrationAccountTypeEnum["CORPORATION_BANKRUPTCY"] = "CORPORATION_BANKRUPTCY";
  AccountRegistrationAccountTypeEnum["CUSTODIAN"] = "CUSTODIAN";
  AccountRegistrationAccountTypeEnum["CUSTODIAN_MINOR"] = "CUSTODIAN_MINOR";
  AccountRegistrationAccountTypeEnum["DEDUCTIBLE_MPPP"] = "DEDUCTIBLE_MPPP";
  AccountRegistrationAccountTypeEnum["DEDUCTIBLE_PSP"] = "DEDUCTIBLE_PSP";
  AccountRegistrationAccountTypeEnum["DEFINED_BENEFIT_PENSION_PLAN"] = "DEFINED_BENEFIT_PENSION_PLAN";
  AccountRegistrationAccountTypeEnum["EDUCATION_SAVINGS"] = "EDUCATION_SAVINGS";
  AccountRegistrationAccountTypeEnum["ENDOWMENT"] = "ENDOWMENT";
  AccountRegistrationAccountTypeEnum["ERISA_403_B7"] = "ERISA_403B7";
  AccountRegistrationAccountTypeEnum["ESCROW_AGENT"] = "ESCROW_AGENT";
  AccountRegistrationAccountTypeEnum["ESTATE"] = "ESTATE";
  AccountRegistrationAccountTypeEnum["EXECUTOR_ESTATE"] = "EXECUTOR_ESTATE";
  AccountRegistrationAccountTypeEnum["EXEMPT_UNINCORPORATED_ORGANIZATION"] = "EXEMPT_UNINCORPORATED_ORGANIZATION";
  AccountRegistrationAccountTypeEnum["FIDUCIARY"] = "FIDUCIARY";
  AccountRegistrationAccountTypeEnum["FOUNDATION"] = "FOUNDATION";
  AccountRegistrationAccountTypeEnum["GRANTOR_TRUST"] = "GRANTOR_TRUST";
  AccountRegistrationAccountTypeEnum["GUARDIAN"] = "GUARDIAN";
  AccountRegistrationAccountTypeEnum["GUST_AMENDED_VRP"] = "GUST_AMENDED_VRP";
  AccountRegistrationAccountTypeEnum["HOSPITAL"] = "HOSPITAL";
  AccountRegistrationAccountTypeEnum["IIG_CORPORATION"] = "IIG_CORPORATION";
  AccountRegistrationAccountTypeEnum["IIG_TRUST"] = "IIG_TRUST";
  AccountRegistrationAccountTypeEnum["INDIVIDUAL"] = "INDIVIDUAL";
  AccountRegistrationAccountTypeEnum["INDIVIDUAL_401_K"] = "INDIVIDUAL_401K";
  AccountRegistrationAccountTypeEnum["INDIVIDUAL_401_K_SUSPENSE"] = "INDIVIDUAL_401K_SUSPENSE";
  AccountRegistrationAccountTypeEnum["INHERITED_IRA"] = "INHERITED_IRA";
  AccountRegistrationAccountTypeEnum["INHERITED_ROTH_IRA"] = "INHERITED_ROTH_IRA";
  AccountRegistrationAccountTypeEnum["JOINT_COMMUNITY_PROPERTY"] = "JOINT_COMMUNITY_PROPERTY";
  AccountRegistrationAccountTypeEnum["JOINT_COMMUNITY_PROPERTY_WROS"] = "JOINT_COMMUNITY_PROPERTY_WROS";
  AccountRegistrationAccountTypeEnum["JOINT_MARITAL_PROPERTY"] = "JOINT_MARITAL_PROPERTY";
  AccountRegistrationAccountTypeEnum["JOINT_MARITAL_PROPERTY_WROS"] = "JOINT_MARITAL_PROPERTY_WROS";
  AccountRegistrationAccountTypeEnum["JOINT_TENANTS_BY_ENTIRETY_WROS"] = "JOINT_TENANTS_BY_ENTIRETY_WROS";
  AccountRegistrationAccountTypeEnum["JOINT_TENANTS_IN_COMMON"] = "JOINT_TENANTS_IN_COMMON";
  AccountRegistrationAccountTypeEnum["JOINT_TENANTS_WROS"] = "JOINT_TENANTS_WROS";
  AccountRegistrationAccountTypeEnum["KEOGH"] = "KEOGH";
  AccountRegistrationAccountTypeEnum["LIFE_TENANT_ESTATE"] = "LIFE_TENANT_ESTATE";
  AccountRegistrationAccountTypeEnum["LIVING_TRUST"] = "LIVING_TRUST";
  AccountRegistrationAccountTypeEnum["NONEXEMPT_UNINCORPORATED_ORGANIZATION"] = "NONEXEMPT_UNINCORPORATED_ORGANIZATION";
  AccountRegistrationAccountTypeEnum["NON_DEDUCTIBLE_MPPP"] = "NON_DEDUCTIBLE_MPPP";
  AccountRegistrationAccountTypeEnum["NON_DEDUCTIBLE_PSP"] = "NON_DEDUCTIBLE_PSP";
  AccountRegistrationAccountTypeEnum["NON_ERISA_403_B7"] = "NON_ERISA_403B7";
  AccountRegistrationAccountTypeEnum["OUTSIDE_HOLDINGS"] = "OUTSIDE_HOLDINGS";
  AccountRegistrationAccountTypeEnum["OUTSIDE_INVESTMENT"] = "OUTSIDE_INVESTMENT";
  AccountRegistrationAccountTypeEnum["PARTNERSHIP"] = "PARTNERSHIP";
  AccountRegistrationAccountTypeEnum["POOLED_VRIP"] = "POOLED_VRIP";
  AccountRegistrationAccountTypeEnum["PROFESSIONAL_ASSOCIATION"] = "PROFESSIONAL_ASSOCIATION";
  AccountRegistrationAccountTypeEnum["PROFESSIONAL_CORPORATION"] = "PROFESSIONAL_CORPORATION";
  AccountRegistrationAccountTypeEnum["PROFESSIONAL_CORPORATION_ASSOCIATION"] = "PROFESSIONAL_CORPORATION_ASSOCIATION";
  AccountRegistrationAccountTypeEnum["RETIREMENT_403_B_PLAN"] = "RETIREMENT_403B_PLAN";
  AccountRegistrationAccountTypeEnum["RETIREMENT_TRUST_401_K"] = "RETIREMENT_TRUST_401K";
  AccountRegistrationAccountTypeEnum["RETIREMENT_TRUST_401_K_PSP"] = "RETIREMENT_TRUST_401K_PSP";
  AccountRegistrationAccountTypeEnum["RETIREMENT_TRUST_403_B7"] = "RETIREMENT_TRUST_403B7";
  AccountRegistrationAccountTypeEnum["RETIREMENT_TRUST_457"] = "RETIREMENT_TRUST_457";
  AccountRegistrationAccountTypeEnum["RETIREMENT_TRUST_DEFERRED_COMPENSATION"] = "RETIREMENT_TRUST_DEFERRED_COMPENSATION";
  AccountRegistrationAccountTypeEnum["RETIREMENT_TRUST_MPPP"] = "RETIREMENT_TRUST_MPPP";
  AccountRegistrationAccountTypeEnum["RETIREMENT_TRUST_PENSION_PLAN"] = "RETIREMENT_TRUST_PENSION_PLAN";
  AccountRegistrationAccountTypeEnum["RETIREMENT_TRUST_PSP"] = "RETIREMENT_TRUST_PSP";
  AccountRegistrationAccountTypeEnum["ROLLOVER_IRA"] = "ROLLOVER_IRA";
  AccountRegistrationAccountTypeEnum["ROLLOVER_MPPP"] = "ROLLOVER_MPPP";
  AccountRegistrationAccountTypeEnum["ROLLOVER_PSP"] = "ROLLOVER_PSP";
  AccountRegistrationAccountTypeEnum["ROTH_INDIVIDUAL_401_K"] = "ROTH_INDIVIDUAL_401K";
  AccountRegistrationAccountTypeEnum["ROTH_IRA"] = "ROTH_IRA";
  AccountRegistrationAccountTypeEnum["SCHOOL_DISTRICT"] = "SCHOOL_DISTRICT";
  AccountRegistrationAccountTypeEnum["SEP_IRA"] = "SEP_IRA";
  AccountRegistrationAccountTypeEnum["SEP_IRA_MULTIPLE_PARTICIPANT"] = "SEP_IRA_MULTIPLE_PARTICIPANT";
  AccountRegistrationAccountTypeEnum["SEP_IRA_SINGLE_PARTICIPANT"] = "SEP_IRA_SINGLE_PARTICIPANT";
  AccountRegistrationAccountTypeEnum["SIMPLE_IRA"] = "SIMPLE_IRA";
  AccountRegistrationAccountTypeEnum["SOLE_PROPRIETORSHIP"] = "SOLE_PROPRIETORSHIP";
  AccountRegistrationAccountTypeEnum["TRADITIONAL_IRA"] = "TRADITIONAL_IRA";
  AccountRegistrationAccountTypeEnum["UGMA"] = "UGMA";
  AccountRegistrationAccountTypeEnum["USUFRUCT"] = "USUFRUCT";
  AccountRegistrationAccountTypeEnum["UTMA"] = "UTMA";
  AccountRegistrationAccountTypeEnum["VANGUARD_BUSINESS_ACCOUNT_SERVICE"] = "VANGUARD_BUSINESS_ACCOUNT_SERVICE";
  AccountRegistrationAccountTypeEnum["VRIP"] = "VRIP";
})(AccountRegistrationAccountTypeEnum = exports.AccountRegistrationAccountTypeEnum || (exports.AccountRegistrationAccountTypeEnum = {}));
