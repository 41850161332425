"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TradeTransactionInstructionEnum = exports.TradeTransactionCostBasisMethodEnum = exports.TradeTransactionDurationEnum = exports.TradeTransactionCategoryEnum = exports.TradeTransactionAmountTypeEnum = exports.TradeTransactionBusinessOriginTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var TradeTransactionBusinessOriginTypeEnum;
(function (TradeTransactionBusinessOriginTypeEnum) {
  TradeTransactionBusinessOriginTypeEnum["ONLINE"] = "BROKERAGE_ORDER_CHANGE_ONLINE";
  TradeTransactionBusinessOriginTypeEnum["MOBILE"] = "BROKERAGE_ORDER_CHANGE_MOBILE";
})(TradeTransactionBusinessOriginTypeEnum = exports.TradeTransactionBusinessOriginTypeEnum || (exports.TradeTransactionBusinessOriginTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var TradeTransactionAmountTypeEnum;
(function (TradeTransactionAmountTypeEnum) {
  TradeTransactionAmountTypeEnum["DOLLARS"] = "DOLLARS";
  TradeTransactionAmountTypeEnum["SHARES"] = "SHARES";
  TradeTransactionAmountTypeEnum["PERCENTAGE"] = "PERCENTAGE";
})(TradeTransactionAmountTypeEnum = exports.TradeTransactionAmountTypeEnum || (exports.TradeTransactionAmountTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var TradeTransactionCategoryEnum;
(function (TradeTransactionCategoryEnum) {
  TradeTransactionCategoryEnum["MARKET"] = "MARKET";
  TradeTransactionCategoryEnum["LIMIT"] = "LIMIT";
  TradeTransactionCategoryEnum["STOP"] = "STOP";
  TradeTransactionCategoryEnum["STOP_LIMIT"] = "STOP_LIMIT";
  TradeTransactionCategoryEnum["MARKET_ON_CLOSE"] = "MARKET_ON_CLOSE";
  TradeTransactionCategoryEnum["OR_BETTER"] = "OR_BETTER";
  TradeTransactionCategoryEnum["WITH_OR_WITHOUT"] = "WITH_OR_WITHOUT";
  TradeTransactionCategoryEnum["CLOSED"] = "CLOSED";
})(TradeTransactionCategoryEnum = exports.TradeTransactionCategoryEnum || (exports.TradeTransactionCategoryEnum = {}));
/**
    * @export
    * @enum {string}
    */
var TradeTransactionDurationEnum;
(function (TradeTransactionDurationEnum) {
  TradeTransactionDurationEnum["DAY"] = "DAY";
  TradeTransactionDurationEnum["GOOD_TILL_CANCELED"] = "GOOD_TILL_CANCELED";
  TradeTransactionDurationEnum["EVENING"] = "EVENING";
  TradeTransactionDurationEnum["GOOD_TILL_DAY"] = "GOOD_TILL_DAY";
  TradeTransactionDurationEnum["OPENING_ONLY_ORDER"] = "OPENING_ONLY_ORDER";
  TradeTransactionDurationEnum["IMMEDIATE_OR_CANCEL"] = "IMMEDIATE_OR_CANCEL";
  TradeTransactionDurationEnum["GOOD_TILL_EXECUTED"] = "GOOD_TILL_EXECUTED";
  TradeTransactionDurationEnum["FILL_OR_KILL"] = "FILL_OR_KILL";
})(TradeTransactionDurationEnum = exports.TradeTransactionDurationEnum || (exports.TradeTransactionDurationEnum = {}));
/**
    * @export
    * @enum {string}
    */
var TradeTransactionCostBasisMethodEnum;
(function (TradeTransactionCostBasisMethodEnum) {
  TradeTransactionCostBasisMethodEnum["FIRST_IN_FIRST_OUT"] = "FIRST_IN_FIRST_OUT";
  TradeTransactionCostBasisMethodEnum["SPECIFIC_IDENTIFICATION"] = "SPECIFIC_IDENTIFICATION";
  TradeTransactionCostBasisMethodEnum["HIGHEST_IN_FIRST_OUT"] = "HIGHEST_IN_FIRST_OUT";
  TradeTransactionCostBasisMethodEnum["MINIMUM_TAX"] = "MINIMUM_TAX";
  TradeTransactionCostBasisMethodEnum["AVERAGE_COST"] = "AVERAGE_COST";
  TradeTransactionCostBasisMethodEnum["MAXIMUM_GAIN"] = "MAXIMUM_GAIN";
})(TradeTransactionCostBasisMethodEnum = exports.TradeTransactionCostBasisMethodEnum || (exports.TradeTransactionCostBasisMethodEnum = {}));
/**
    * @export
    * @enum {string}
    */
var TradeTransactionInstructionEnum;
(function (TradeTransactionInstructionEnum) {
  TradeTransactionInstructionEnum["BUY"] = "BUY";
  TradeTransactionInstructionEnum["SELL"] = "SELL";
  TradeTransactionInstructionEnum["EXCHANGE"] = "EXCHANGE";
  TradeTransactionInstructionEnum["BUY_TO_COVER"] = "BUY_TO_COVER";
  TradeTransactionInstructionEnum["SELL_SHORT"] = "SELL_SHORT";
  TradeTransactionInstructionEnum["SELLOCEX"] = "SELLOCEX";
})(TradeTransactionInstructionEnum = exports.TradeTransactionInstructionEnum || (exports.TradeTransactionInstructionEnum = {}));
