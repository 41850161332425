"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccountRoleAccountServiceIdEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var AccountRoleAccountServiceIdEnum;
(function (AccountRoleAccountServiceIdEnum) {
  AccountRoleAccountServiceIdEnum["_8"] = "8";
  AccountRoleAccountServiceIdEnum["_22"] = "22";
  AccountRoleAccountServiceIdEnum["_38"] = "38";
  AccountRoleAccountServiceIdEnum["_61"] = "61";
  AccountRoleAccountServiceIdEnum["_90"] = "90";
  AccountRoleAccountServiceIdEnum["_4031"] = "4031";
})(AccountRoleAccountServiceIdEnum = exports.AccountRoleAccountServiceIdEnum || (exports.AccountRoleAccountServiceIdEnum = {}));
