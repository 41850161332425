"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClientAddressStandardizedAddressStatusEnum = exports.ClientAddressDefectReasonEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ClientAddressDefectReasonEnum;
(function (ClientAddressDefectReasonEnum) {
  ClientAddressDefectReasonEnum["ADDRESS_IS_NOT_EVALUATED"] = "ADDRESS_IS_NOT_EVALUATED";
  ClientAddressDefectReasonEnum["ADDRESS_LINE_MORE_THAN_40_CHARACTERS"] = "ADDRESS_LINE_MORE_THAN_40_CHARACTERS";
  ClientAddressDefectReasonEnum["APARTMENT_INVALID"] = "APARTMENT_INVALID";
  ClientAddressDefectReasonEnum["APARTMENT_MISSING"] = "APARTMENT_MISSING";
  ClientAddressDefectReasonEnum["APARTMENT_RANGE_NOT_FOUND"] = "APARTMENT_RANGE_NOT_FOUND";
  ClientAddressDefectReasonEnum["CANNOT_PARSE_ADDRESS"] = "CANNOT_PARSE_ADDRESS";
  ClientAddressDefectReasonEnum["CLIENT_PREFERENCE"] = "CLIENT_PREFERENCE";
  ClientAddressDefectReasonEnum["CODE1_NOT_AVAILABLE"] = "CODE1_NOT_AVAILABLE";
  ClientAddressDefectReasonEnum["DPV_TURNED_OFF"] = "DPV_TURNED_OFF";
  ClientAddressDefectReasonEnum["EXTRANEOUS_INFO_ON_ADDRESS_LINE"] = "EXTRANEOUS_INFO_ON_ADDRESS_LINE";
  ClientAddressDefectReasonEnum["HOUSE_NUMBER_INVALID"] = "HOUSE_NUMBER_INVALID";
  ClientAddressDefectReasonEnum["HOUSE_OR_RANGE_NOT_FOUND"] = "HOUSE_OR_RANGE_NOT_FOUND";
  ClientAddressDefectReasonEnum["INSUFFICIENT_ADDRESS"] = "INSUFFICIENT_ADDRESS";
  ClientAddressDefectReasonEnum["MORE_THAN_FOUR_ADDRESS_LINES"] = "MORE_THAN_FOUR_ADDRESS_LINES";
  ClientAddressDefectReasonEnum["MORE_THAN_TWO_ADDRESS_LINES"] = "MORE_THAN_TWO_ADDRESS_LINES";
  ClientAddressDefectReasonEnum["MULTIPLE_MATCHES_FOUND"] = "MULTIPLE_MATCHES_FOUND";
  ClientAddressDefectReasonEnum["NON_CONSECUTIVE_ADDRESS_LINES"] = "NON_CONSECUTIVE_ADDRESS_LINES";
  ClientAddressDefectReasonEnum["NO_DEFECT"] = "NO_DEFECT";
  ClientAddressDefectReasonEnum["POST_OFFICE_INVALID"] = "POST_OFFICE_INVALID";
  ClientAddressDefectReasonEnum["POST_OFFICE_MISSING"] = "POST_OFFICE_MISSING";
  ClientAddressDefectReasonEnum["PRIMARY_NUMBER_IS_MISSING"] = "PRIMARY_NUMBER_IS_MISSING";
  ClientAddressDefectReasonEnum["STREET_NOT_FOUND"] = "STREET_NOT_FOUND";
  ClientAddressDefectReasonEnum["ZIPCODE_NOT_FOUND"] = "ZIPCODE_NOT_FOUND";
})(ClientAddressDefectReasonEnum = exports.ClientAddressDefectReasonEnum || (exports.ClientAddressDefectReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientAddressStandardizedAddressStatusEnum;
(function (ClientAddressStandardizedAddressStatusEnum) {
  ClientAddressStandardizedAddressStatusEnum["ADDRESS_NOT_EVALUATED"] = "ADDRESS_NOT_EVALUATED";
  ClientAddressStandardizedAddressStatusEnum["NON_STANDARDIZED_ADDRESS"] = "NON_STANDARDIZED_ADDRESS";
  ClientAddressStandardizedAddressStatusEnum["STANDARDIZED_ADDRESS"] = "STANDARDIZED_ADDRESS";
})(ClientAddressStandardizedAddressStatusEnum = exports.ClientAddressStandardizedAddressStatusEnum || (exports.ClientAddressStandardizedAddressStatusEnum = {}));
