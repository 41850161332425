"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProcessedTradeTransactionDetailErrorType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ProcessedTradeTransactionDetailErrorType;
(function (ProcessedTradeTransactionDetailErrorType) {
  ProcessedTradeTransactionDetailErrorType["INTERNAL_SERVER_ERROR"] = "INTERNAL_SERVER_ERROR";
  ProcessedTradeTransactionDetailErrorType["EXTERNAL_SERVICE_ERROR"] = "EXTERNAL_SERVICE_ERROR";
  ProcessedTradeTransactionDetailErrorType["INPUT_VALIDATION_ERROR"] = "INPUT_VALIDATION_ERROR";
  ProcessedTradeTransactionDetailErrorType["NOT_NULL"] = "NOT_NULL";
  ProcessedTradeTransactionDetailErrorType["NOT_EMPTY"] = "NOT_EMPTY";
  ProcessedTradeTransactionDetailErrorType["SAFE_STRING"] = "SAFE_STRING";
  ProcessedTradeTransactionDetailErrorType["P_O_I_D"] = "P_O_I_D";
})(ProcessedTradeTransactionDetailErrorType = exports.ProcessedTradeTransactionDetailErrorType || (exports.ProcessedTradeTransactionDetailErrorType = {}));
