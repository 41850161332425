"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExchangeTradingInstructionsOrderDuration = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ExchangeTradingInstructionsOrderDuration;
(function (ExchangeTradingInstructionsOrderDuration) {
  ExchangeTradingInstructionsOrderDuration["DAY"] = "DAY";
  ExchangeTradingInstructionsOrderDuration["GOOD_TILL_CANCEL"] = "GOOD_TILL_CANCEL";
  ExchangeTradingInstructionsOrderDuration["OPENING_ONLY_ORDER"] = "OPENING_ONLY_ORDER";
  ExchangeTradingInstructionsOrderDuration["IMMEDIATE_OR_CANCEL"] = "IMMEDIATE_OR_CANCEL";
  ExchangeTradingInstructionsOrderDuration["GOOD_TILL_EXECUTED"] = "GOOD_TILL_EXECUTED";
  ExchangeTradingInstructionsOrderDuration["GOOD_TILL_DAY"] = "GOOD_TILL_DAY";
  ExchangeTradingInstructionsOrderDuration["EVENING"] = "EVENING";
  ExchangeTradingInstructionsOrderDuration["FILL_OR_KILL"] = "FILL_OR_KILL";
})(ExchangeTradingInstructionsOrderDuration = exports.ExchangeTradingInstructionsOrderDuration || (exports.ExchangeTradingInstructionsOrderDuration = {}));
