"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
__exportStar(require("./typescript-axios-api/account-api"), exports);
__exportStar(require("./typescript-axios-api/funds-api"), exports);
__exportStar(require("./typescript-axios-api/logging-api"), exports);
__exportStar(require("./typescript-axios-api/money-movement-api"), exports);
__exportStar(require("./typescript-axios-api/orders-api"), exports);
__exportStar(require("./typescript-axios-api/process-api"), exports);
__exportStar(require("./typescript-axios-api/products-api"), exports);
__exportStar(require("./typescript-axios-api/prospectus-api"), exports);
__exportStar(require("./typescript-axios-api/version-api"), exports);
