@use 'sass:math';
@import './tokens';
@import '../functions';

// Semantic colors
$white: $c11n-color-ink-000;
$black: $c11n-color-ink-800;
$ghost: rgba($black,0.1);
$placeholder-color: $c11n-color-ink-400;
$alert-color: $c11n-color-alert-400;
$success-color: $c11n-color-success-400;
$interactive-color: $c11n-color-interactive-primary;
$subtle-color: $c11n-color-ink-400;
$brand-color: $c11n-color-red-600;
$focus-color: #0f62c5;
$scrim-color: rgba($c11n-color-ink-800, .3);

// Old names for semantic colors
$error-color: #ba3d21;

// TODO: replace with tokens when new structure is introduced
// Positive/negative colors
$c11n-color-text-positive-on-light: $c11n-color-success-400;
$c11n-color-text-negative-on-light: #ba3d21;

$namespace: 'c11n' !default;
$default-font: $c11n-font-family-sans;
$default-color: $c11n-color-ink-800;
$enabled-color: $c11n-color-red-600;
// Unitless line heights
$c11n-line-height-xs: math.div(strip-unit($c11n-size-line-height-xs), strip-unit($c11n-size-typography-xs));
$c11n-line-height-sm: math.div(strip-unit($c11n-size-line-height-sm), strip-unit($c11n-size-typography-sm));
$c11n-line-height-md: math.div(strip-unit($c11n-size-line-height-md), strip-unit($c11n-size-typography-md));
$c11n-line-height-md-article: math.div(strip-unit($c11n-size-line-height-md-article), strip-unit($c11n-size-typography-md));
$c11n-line-height-lg: math.div(strip-unit($c11n-size-line-height-lg), strip-unit($c11n-size-typography-lg));
$c11n-line-height-xl: math.div(strip-unit($c11n-size-line-height-xl), strip-unit($c11n-size-typography-xl));
$c11n-line-height-2xl: math.div(strip-unit($c11n-size-line-height-2xl), strip-unit($c11n-size-typography-2xl));
$c11n-line-height-3xl: math.div(strip-unit($c11n-size-line-height-3xl), strip-unit($c11n-size-typography-3xl));
$c11n-line-height-4xl: math.div(strip-unit($c11n-size-line-height-4xl), strip-unit($c11n-size-typography-4xl));
$c11n-line-height-5xl: math.div(strip-unit($c11n-size-line-height-5xl), strip-unit($c11n-size-typography-5xl));
$c11n-line-height-6xl: math.div(strip-unit($c11n-size-line-height-6xl), strip-unit($c11n-size-typography-6xl));
// text-crop vars
$c11n-crop-top-adj-xs: rem(-0.5);
$c11n-crop-bottom-adj-xs: rem(0);
$c11n-crop-top-adj-sm: rem(-1.5);
$c11n-crop-bottom-adj-sm: rem(-0.5);
$c11n-crop-top-adj-md: rem(-3);
$c11n-crop-bottom-adj-md: rem(-1);
$c11n-crop-top-adj-md-article: rem(-7.375);
$c11n-crop-bottom-adj-md-article: rem(-4.5);
$c11n-crop-top-adj-lg: rem(-5.5);
$c11n-crop-bottom-adj-lg: rem(-2);
$c11n-crop-top-adj-lg-bold: rem(-4.5);
$c11n-crop-bottom-adj-lg-bold: rem(-3);
$c11n-crop-top-adj-xl: rem(-4.25);
$c11n-crop-bottom-adj-xl: rem(0.0625);
$c11n-crop-top-adj-xl-headline: rem(-4.25);
$c11n-crop-bottom-adj-xl-headline: rem(0.25);
$c11n-crop-top-adj-xl-display: rem(-2.5);
$c11n-crop-bottom-adj-xl-display: rem(-2.5);
$c11n-crop-top-adj-2xl: rem(-6.5);
$c11n-crop-bottom-adj-2xl: rem(-1.5);
$c11n-crop-top-adj-2xl-display: rem(-4.75);
$c11n-crop-bottom-adj-2xl-display: rem(-4.25);
$c11n-crop-top-adj-3xl: rem(-7.25);
$c11n-crop-bottom-adj-3xl: rem(-0.25);
$c11n-crop-top-adj-3xl-headline: rem(-7.5);
$c11n-crop-bottom-adj-3xl-headline: rem(0.125);
$c11n-crop-top-adj-3xl-display: rem(-5.5);
$c11n-crop-bottom-adj-3xl-display: rem(-4);
$c11n-crop-top-adj-4xl: rem(-12.5);
$c11n-crop-bottom-adj-4xl: rem(-3.375);
$c11n-crop-top-adj-4xl-headline: rem(-12.875);
$c11n-crop-bottom-adj-4xl-headline: rem(-3);
$c11n-crop-top-adj-4xl-display: rem(-10);
$c11n-crop-bottom-adj-4xl-display: rem(-8);
$c11n-crop-top-adj-5xl: rem(-13.5);
$c11n-crop-bottom-adj-5xl: rem(-2.375);
$c11n-crop-top-adj-5xl-display: rem(-11);
$c11n-crop-bottom-adj-5xl-display: rem(-8);
$c11n-crop-top-adj-6xl: rem(-19);
$c11n-crop-bottom-adj-6xl: rem(-4.5);
$c11n-crop-top-adj-6xl-headline: rem(-19.5);
$c11n-crop-bottom-adj-6xl-headline: rem(-4.25);
$c11n-crop-top-adj-6xl-display: rem(-15);
$c11n-crop-bottom-adj-6xl-display: rem(-12);

$small: 1;
$animation-timing-expand-large: 300ms;
$animation-timing-expand-medium: 250ms;
$animation-timing-expand-small: 100ms;
$animation-timing-collapse-large: 250ms;
$animation-timing-collapse-medium: 200ms;
$animation-timing-collapse-small: 100ms;
$animation-easing-decelerate-web: cubic-bezier(0, 0, 0.2, 1);
$animation-easing-standard-web: cubic-bezier(0.4, 0, 0.2, 1);
$animation-easing-accelerate-web: cubic-bezier(0.4, 0, 1, 1);

$c11n-text-xl-heavy-underline-padding: rem(1.5);
$c11n-text-xl-bold-underline-padding: rem(0);
$c11n-text-xl-normal-underline-padding: rem(2);
$c11n-text-lg-heavy-underline-padding: rem(2);
$c11n-text-lg-bold-underline-padding: rem(0.5);
$c11n-text-lg-normal-underline-padding: rem(2);
$c11n-text-md-heavy-underline-padding: rem(2.5);
$c11n-text-md-bold-underline-padding: rem(1.5);
$c11n-text-md-normal-underline-padding: rem(2.5);
$c11n-text-sm-heavy-underline-padding: rem(2);
$c11n-text-sm-bold-underline-padding: rem(1);
$c11n-text-sm-normal-underline-padding: rem(2);

//c11n z-index and box-shadow maps
$c11n-z-index: (
  0: $c11n-elevation-z-index-0, 
  100: $c11n-elevation-z-index-100, 
  400: $c11n-elevation-z-index-400, 
  800: $c11n-elevation-z-index-800, 
  1600: $c11n-elevation-z-index-1600, 
  3200: $c11n-elevation-z-index-3200
);
$c11n-box-shadow: (
  0: $c11n-elevation-box-shadow-0, 
  100: $c11n-elevation-box-shadow-100, 
  400: $c11n-elevation-box-shadow-400, 
  800: $c11n-elevation-box-shadow-800, 
  1600: $c11n-elevation-box-shadow-1600, 
  3200: $c11n-elevation-box-shadow-3200
);

@import '../extends';
@import '../mixins';
