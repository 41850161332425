import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { gatekeeperGuardActivateFn } from '@app/core/guards';

import { CONSTANTS } from './constants';
import {
  cancelSuccessGuard,
  confirmGuard,
  extendedHoursGuard,
  holdingsCanMatch,
  openOrdersHoldingsGuard,
  previewGuard,
  queryParamsGuard,
  ttsOrderCancelGuard,
  ttsOrderChangeGuard,
} from './guards';
import { selectSharesGuard } from './guards/select-shares/select-shares.guard';
import { TradeRoute } from './models';
import * as Pages from './pages';

const routes: TradeRoute[] = [
  {
    path: 'ticket',
    component: Pages.TradePageComponent,
    canActivate: [ttsOrderChangeGuard],
    data: {
      pageTitle: CONSTANTS.APP_TITLE,
      pageHeading: 'Trade ETFs or Stocks',
      analytics: {
        processStartBrokeragePath: true,
        processType: CONSTANTS.ADOBE_PROCESS_TYPE,
        processStep: `${CONSTANTS.ADOBE_PROCESS_TYPE} - Process Start`,
      },
    },
  },
  {
    path: 'ticket/preview',
    component: Pages.PreviewPageComponent,
    canActivate: [previewGuard],
    data: {
      pageTitle: `Order Preview - ${CONSTANTS.APP_TITLE}`,
      pageHeading: 'Order Preview',
      analytics: {
        processPreviewBrokeragePath: true,
        processType: CONSTANTS.ADOBE_PROCESS_TYPE,
        processStep: `${CONSTANTS.ADOBE_PROCESS_TYPE} - Process Review & Submit`,
      },
    },
  },
  {
    path: 'ticket/select-shares',
    component: Pages.SelectSharesPageComponent,
    canActivate: [selectSharesGuard],
    data: {
      pageTitle: `Select Shares - ${CONSTANTS.APP_TITLE}`,
      pageHeading: 'Select Shares',
      analytics: {
        processType: CONSTANTS.ADOBE_PROCESS_TYPE,
        processStep: `${CONSTANTS.ADOBE_PROCESS_TYPE} - Select Shares`,
      },
    },
  },
  {
    path: 'edit-cost-basis/select-shares',
    component: Pages.SelectSharesPageComponent,
    canActivate: [selectSharesGuard],
    data: {
      pageTitle: `Select Shares - ${CONSTANTS.APP_TITLE}`,
      pageHeading: 'Select Shares',
      analytics: {
        processType: CONSTANTS.ADOBE_PROCESS_TYPE,
        processStep: `${CONSTANTS.ADOBE_PROCESS_TYPE} - Select Shares`,
      },
    },
  },
  {
    path: 'extended-trading/select-shares',
    component: Pages.SelectSharesPageComponent,
    canActivate: [selectSharesGuard],
    data: {
      pageTitle: `Select Shares - ${CONSTANTS.APP_TITLE}`,
      pageHeading: 'Select Shares',
      analytics: {
        processType: CONSTANTS.ADOBE_PROCESS_TYPE,
        processStep: `${CONSTANTS.ADOBE_PROCESS_TYPE} - Select Shares`,
      },
    },
  },
  {
    path: 'ticket/confirmation',
    component: Pages.ConfirmPageComponent,
    canActivate: [confirmGuard],
    data: {
      pageTitle: `Order Confirmation - ${CONSTANTS.APP_TITLE}`,
      pageHeading: 'Order Confirmation',
      hideHeader: true,
      hideContactUsTradeHelp: true,
      analytics: {
        processCompleteBrokeragePath: true,
        processType: CONSTANTS.ADOBE_PROCESS_TYPE,
        processStep: `${CONSTANTS.ADOBE_PROCESS_TYPE} - Process Complete`,
      },
      showVgnHeaderAndFooter: true,
    },
  },
  {
    path: 'holdings',
    canActivate: [openOrdersHoldingsGuard],
    canMatch: [holdingsCanMatch],
    component: Pages.ModernHoldingsPageComponent,
    data: {
      pageTitle: `Holdings - ${CONSTANTS.APP_TITLE}`,
      pageHeading: 'Account Holdings',
      analytics: {
        tagging: true,
      },
    },
  },
  {
    path: 'holdings',
    canActivate: [openOrdersHoldingsGuard],
    component: Pages.HoldingsPageComponent,
    data: {
      pageTitle: `Holdings - ${CONSTANTS.APP_TITLE}`,
      pageHeading: 'Account Holdings',
      analytics: {
        tagging: true,
      },
    },
  },
  {
    path: 'orders',
    canActivate: [openOrdersHoldingsGuard],
    component: Pages.OpenOrdersPageComponent,
    data: {
      pageTitle: `Open Orders - ${CONSTANTS.APP_TITLE}`,
      pageHeading: 'Open Orders',
      analytics: {
        tagging: true,
      },
    },
  },
  {
    path: 'extended-trading',
    canActivate: [extendedHoursGuard],
    component: Pages.ExtendedTradingComponent,
    data: {
      pageTitle: `Extended Trading - ${CONSTANTS.APP_TITLE}`,
      pageHeading: 'Extended Trading',
      analytics: {
        tagging: true,
      },
    },
  },
  {
    path: 'extended-trading/preview',
    component: Pages.PreviewPageComponent,
    canActivate: [previewGuard],
    data: {
      pageTitle: `Order Preview - ${CONSTANTS.APP_TITLE}`,
      pageHeading: 'Order Preview',
      analytics: {
        processPreviewBrokeragePath: true,
        processType: CONSTANTS.ADOBE_PROCESS_TYPE,
        processStep: `${CONSTANTS.ADOBE_PROCESS_TYPE} - Process Review & Submit`,
      },
    },
  },
  {
    path: 'cancel-order',
    component: Pages.CancelPageComponent,
    canActivate: [gatekeeperGuardActivateFn, ttsOrderCancelGuard, queryParamsGuard(['accountId', 'orderId'])],
    data: {
      pageTitle: 'Cancel Order | Vanguard',
      hideContactUsTradeHelp: true,
      hideNav: true,
      analytics: {
        processStartBrokeragePath: true,
        processType: CONSTANTS.ADOBE_PROCESS_TYPE,
        processStep: `${CONSTANTS.ADOBE_PROCESS_TYPE} - Cancel Start`,
      },
    },
  },
  {
    path: 'cancel-success',
    component: Pages.CancelSuccessPageComponent,
    canActivate: [cancelSuccessGuard],
    data: {
      pageTitle: 'Cancel Success | Vanguard',
      showVgnHeaderAndFooter: true,
      hideHeader: true,
      hideContactUsTradeHelp: true,
      analytics: {
        processStartBrokeragePath: true,
        processType: CONSTANTS.ADOBE_PROCESS_TYPE,
        processStep: `${CONSTANTS.ADOBE_PROCESS_TYPE} - Cancel Confirm`,
      },
    },
  },
  {
    path: 'edit-cost-basis',
    component: Pages.EditCostBasisComponent,
    canActivate: [gatekeeperGuardActivateFn, ttsOrderChangeGuard, queryParamsGuard(['accountId', 'orderId'])],
    data: {
      pageTitle: `Edit Cost Basis - ${CONSTANTS.APP_TITLE}`,
      analytics: {
        processStartBrokeragePath: true,
        processType: CONSTANTS.ADOBE_PROCESS_TYPE,
        processStep: `${CONSTANTS.ADOBE_PROCESS_TYPE} - Edit Cost Basis Start`,
      },
    },
  },
  {
    path: '',
    redirectTo: CONSTANTS.TRADE_PATH,
    pathMatch: 'full',
  },
];

@NgModule({
  providers: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EtfsEquitiesRoutingModule {}
