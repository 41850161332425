
// Do not edit directly
// Generated on Mon, 11 Nov 2024 20:36:41 GMT

$c11n-color-ink-100: #f5f6f6; // primary-neutral-secondary
$c11n-color-ink-200: #e8e9e9; // primary-neutral-secondary
$c11n-color-ink-300: #cbcece; // primary-neutral-secondary
$c11n-color-ink-400: #717777; // primary-neutral-secondary
$c11n-color-ink-600: #3f4444; // primary-neutral-secondary
$c11n-color-ink-800: #040505; // primary-neutral-secondary
$c11n-color-ink-000: #ffffff; // primary-neutral-secondary
$c11n-color-red-100: #ffeded; // primary-neutral-secondary
$c11n-color-red-400: #f6001e; // primary-neutral-secondary
$c11n-color-red-600: #c20029; // primary-neutral-secondary
$c11n-color-red-800: #660026; // primary-neutral-secondary
$c11n-color-yellow-100: #fff7de; // primary-neutral-secondary
$c11n-color-yellow-400: #ffad00; // primary-neutral-secondary
$c11n-color-yellow-600: #bf8200; // primary-neutral-secondary
$c11n-color-turquoise-100: #def5f0; // primary-neutral-secondary
$c11n-color-turquoise-400: #00bda3; // primary-neutral-secondary
$c11n-color-turquoise-600: #007873; // primary-neutral-secondary
$c11n-color-dark-turquoise-100: #e7efef; // data-viz-primary-secondary
$c11n-color-dark-turquoise-200: #c3d8d7; // data-viz-primary-secondary
$c11n-color-dark-turquoise-300: #88b1af; // data-viz-primary-secondary
$c11n-color-dark-turquoise-400: #4c8a87; // data-viz-primary-secondary
$c11n-color-dark-turquoise-500: #11635f; // data-viz-primary-secondary
$c11n-color-bright-yellow-100: #fff7e6; // data-viz-primary-secondary
$c11n-color-bright-yellow-200: #ffebbf; // data-viz-primary-secondary
$c11n-color-bright-yellow-300: #ffd680; // data-viz-primary-secondary
$c11n-color-bright-yellow-400: #ffc240; // data-viz-primary-secondary
$c11n-color-bright-yellow-500: #ffad00; // data-viz-primary-secondary
$c11n-color-vanguard-red-100: #f9e6ea; // data-viz-primary-secondary
$c11n-color-vanguard-red-200: #f0bfca; // data-viz-primary-secondary
$c11n-color-vanguard-red-300: #e18095; // data-viz-primary-secondary
$c11n-color-vanguard-red-400: #d1405f; // data-viz-primary-secondary
$c11n-color-vanguard-red-500: #c20029; // data-viz-primary-secondary
$c11n-color-dark-yellow-100: #f9f3e6; // data-viz-primary-secondary
$c11n-color-dark-yellow-200: #efe0bf; // data-viz-primary-secondary
$c11n-color-dark-yellow-300: #dfc180; // data-viz-primary-secondary
$c11n-color-dark-yellow-400: #cfa140; // data-viz-primary-secondary
$c11n-color-dark-yellow-500: #bf8200; // data-viz-primary-secondary
$c11n-color-dark-red-100: #f0e6e9; // data-viz-primary-secondary
$c11n-color-dark-red-200: #d9bfc9; // data-viz-primary-secondary
$c11n-color-dark-red-300: #b38093; // data-viz-primary-secondary
$c11n-color-dark-red-400: #8d405c; // data-viz-primary-secondary
$c11n-color-dark-red-500: #670026; // data-viz-primary-secondary
$c11n-color-bright-turquoise-100: #e8f6f5; // data-viz-primary-secondary
$c11n-color-bright-turquoise-200: #c6e8e6; // data-viz-primary-secondary
$c11n-color-bright-turquoise-300: #8dd1ce; // data-viz-primary-secondary
$c11n-color-bright-turquoise-400: #54bab5; // data-viz-primary-secondary
$c11n-color-bright-turquoise-500: #1ba39c; // data-viz-primary-secondary
$c11n-color-bright-red-100: #fee5e9; // data-viz-primary-secondary
$c11n-color-bright-red-200: #fdbfc7; // data-viz-primary-secondary
$c11n-color-bright-red-300: #fb808f; // data-viz-primary-secondary
$c11n-color-bright-red-400: #f84056; // data-viz-primary-secondary
$c11n-color-bright-red-500: #f6001e; // data-viz-primary-secondary
$c11n-color-fuschia-100: #f9e6f2; // data-viz-extended
$c11n-color-fuschia-200: #f1bfdf; // data-viz-extended
$c11n-color-fuschia-300: #e380bf; // data-viz-extended
$c11n-color-fuschia-400: #d4409e; // data-viz-extended
$c11n-color-fuschia-500: #c6007e; // data-viz-extended
$c11n-color-tangerine-100: #fdf1e6; // data-viz-extended
$c11n-color-tangerine-200: #fbdbbf; // data-viz-extended
$c11n-color-tangerine-300: #f6b880; // data-viz-extended
$c11n-color-tangerine-400: #f29440; // data-viz-extended
$c11n-color-tangerine-500: #ed7000; // data-viz-extended
$c11n-color-amethyst-100: #f1ebf8; // data-viz-extended
$c11n-color-amethyst-200: #ddceef; // data-viz-extended
$c11n-color-amethyst-300: #ba9dde; // data-viz-extended
$c11n-color-amethyst-400: #986cce; // data-viz-extended
$c11n-color-amethyst-500: #753bbd; // data-viz-extended
$c11n-color-cobalt-100: #e6ebf6; // data-viz-extended
$c11n-color-cobalt-200: #bfcce7; // data-viz-extended
$c11n-color-cobalt-300: #8099d0; // data-viz-extended
$c11n-color-cobalt-400: #4065b8; // data-viz-extended
$c11n-color-cobalt-500: #0032a0; // data-viz-extended
$c11n-color-raspberry-100: #f3e6ed; // data-viz-extended
$c11n-color-raspberry-200: #e0bfd3; // data-viz-extended
$c11n-color-raspberry-300: #c180a7; // data-viz-extended
$c11n-color-raspberry-400: #a1407a; // data-viz-extended
$c11n-color-raspberry-500: #82004e; // data-viz-extended
$c11n-color-purple-100: #ebe8f2; // data-viz-extended
$c11n-color-purple-200: #cdc6df; // data-viz-extended
$c11n-color-purple-300: #9c8ec0; // data-viz-extended
$c11n-color-purple-400: #6a55a0; // data-viz-extended
$c11n-color-purple-500: #381c80; // data-viz-extended
$c11n-color-dark-green-100: #e6eeea; // data-viz-extended
$c11n-color-dark-green-200: #bfd6ca; // data-viz-extended
$c11n-color-dark-green-300: #80ac94; // data-viz-extended
$c11n-color-dark-green-400: #40835f; // data-viz-extended
$c11n-color-dark-green-500: #005929; // data-viz-extended
$c11n-color-azure-100: #e6f1fc; // data-viz-extended
$c11n-color-azure-200: #bfdcf7; // data-viz-extended
$c11n-color-azure-300: #80b8ef; // data-viz-extended
$c11n-color-azure-400: #4094e6; // data-viz-extended
$c11n-color-azure-500: #0071de; // data-viz-extended
$c11n-color-bright-green-100: #e9f6e9; // data-viz-extended
$c11n-color-bright-green-200: #c7e9c8; // data-viz-extended
$c11n-color-bright-green-300: #8fd392; // data-viz-extended
$c11n-color-bright-green-400: #57be5b; // data-viz-extended
$c11n-color-bright-green-500: #1fa824; // data-viz-extended
$c11n-color-rust-100: #fbe9ea; // data-viz-extended
$c11n-color-rust-200: #f4c9cb; // data-viz-extended
$c11n-color-rust-300: #e99398; // data-viz-extended
$c11n-color-rust-400: #dd5d64; // data-viz-extended
$c11n-color-rust-500: #d22730; // data-viz-extended
$c11n-color-alert-100: #f8ece9; // semantic
$c11n-color-alert-400: #ba3d21; // semantic
$c11n-color-success-100: #e8f5e8; // semantic
$c11n-color-success-400: #1d9f22; // semantic
$c11n-color-interactive-primary: #145bff; // semantic
$c11n-color-interactive-secondary: #040505; // semantic
$c11n-color-internal-100: #f6f8f1; // internal
$c11n-color-internal-400: #bbcf9c; // internal
$c11n-color-internal-600: #708d44; // internal
$c11n-color-internal-800: #556b33; // internal
$c11n-elevation-box-shadow-0: 0 0 0 0 rgba(4, 5, 5, 0.0);
$c11n-elevation-box-shadow-100: 0 0 0.25rem 0.0625rem rgba(4, 5, 5, 0.06),0 0.0625rem 0.0625rem 0 rgba(4, 5, 5, 0.18);
$c11n-elevation-box-shadow-400: 0 0 0.25rem 0.0625rem rgba(4, 5, 5, 0.06),0 0.0625rem 0.0625rem 0 rgba(4, 5, 5, 0.08),0 0.125rem 0.25rem 0 rgba(4, 5, 5, 0.16);
$c11n-elevation-box-shadow-800: 0 0 0.25rem 0.0625rem rgba(4, 5, 5, 0.06),0 0.0625rem 0.0625rem 0 rgba(4, 5, 5, 0.06),0 0.125rem 0.25rem 0 rgba(4, 5, 5, 0.08),0 0.25rem 0.5rem 0 rgba(4, 5, 5, 0.12);
$c11n-elevation-box-shadow-1600: 0 0 0.25rem 0.0625rem rgba(4, 5, 5, 0.06),0 0.0625rem 0.0625rem 0 rgba(4, 5, 5, 0.05),0 0.125rem 0.25rem 0 rgba(4, 5, 5, 0.05),0 0.25rem 0.5rem 0 rgba(4, 5, 5, 0.10),0 0.5rem 1rem 0.25rem rgba(4, 5, 5, 0.10);
$c11n-elevation-box-shadow-3200: 0 0 0.25rem 0.0625rem rgba(4, 5, 5, 0.06),0 0.0625rem 0.0625rem 0 rgba(4, 5, 5, 0.04),0 0.125rem 0.25rem 0 rgba(4, 5, 5, 0.04),0 0.25rem 0.5rem 0 rgba(4, 5, 5, 0.04),0 0.5rem 1rem 0 rgba(4, 5, 5, 0.08),0 1rem 2rem 0.25rem rgba(4, 5, 5, 0.08);
$c11n-elevation-z-index-0: 0;
$c11n-elevation-z-index-100: 100;
$c11n-elevation-z-index-400: 400;
$c11n-elevation-z-index-800: 800;
$c11n-elevation-z-index-1600: 1600;
$c11n-elevation-z-index-2400: 2400;
$c11n-elevation-z-index-3200: 3200;
$c11n-grid-min-width-xs: 0px;
$c11n-grid-min-width-sm: 352px;
$c11n-grid-min-width-md: 541px;
$c11n-grid-min-width-lg: 721px;
$c11n-grid-min-width-xl: 961px;
$c11n-grid-min-width-xxl: 1141px;
$c11n-grid-max-width-xs: 351px;
$c11n-grid-max-width-sm: 540px;
$c11n-grid-max-width-md: 720px;
$c11n-grid-max-width-lg: 960px;
$c11n-grid-max-width-xl: 1140px;
$c11n-grid-max-width-xxl: 1320px;
$c11n-grid-breakpoint-sm: 576px;
$c11n-grid-breakpoint-md: 768px;
$c11n-grid-breakpoint-lg: 992px;
$c11n-grid-breakpoint-xl: 1200px;
$c11n-grid-breakpoint-xxl: 1400px;
$c11n-size-border-width-thin: 1px; // Used for input borders
$c11n-size-border-width-thick: 2px; // used for secondary buttons, dividers
$c11n-size-border-width-pipe: 4px; // used for banners, tabs, callouts
$c11n-size-border-radius-base: 0px; // Default border-radius, used on inputs, cards
$c11n-size-border-radius-sm: 2px; // Used for elements the UI can affect, like chips and tags
$c11n-size-border-radius-full: 50%; // Used for buttons
$c11n-size-line-height-xs: 16px;
$c11n-size-line-height-sm: 20px;
$c11n-size-line-height-md: 24px;
$c11n-size-line-height-md-article: 32px; // Used for long form text
$c11n-size-line-height-lg: 32px;
$c11n-size-line-height-xl: 32px;
$c11n-size-line-height-2xl: 40px;
$c11n-size-line-height-3xl: 48px;
$c11n-size-line-height-4xl: 64px;
$c11n-size-line-height-5xl: 72px;
$c11n-size-line-height-6xl: 96px;
$c11n-size-space-0point5x: 4px;
$c11n-size-space-1x: 8px;
$c11n-size-space-1point5x: 12px;
$c11n-size-space-2x: 16px;
$c11n-size-space-2point5x: 20px;
$c11n-size-space-3x: 24px;
$c11n-size-space-3point5x: 28px;
$c11n-size-space-4x: 32px;
$c11n-size-space-4point5x: 36px;
$c11n-size-space-5x: 40px;
$c11n-size-space-5point5x: 44px;
$c11n-size-space-6x: 48px;
$c11n-size-space-8x: 64px;
$c11n-size-space-9x: 72px;
$c11n-size-space-10x: 80px;
$c11n-size-space-12x: 96px;
$c11n-size-space-16x: 128px;
$c11n-size-space-18x: 144px;
$c11n-size-typography-xs: 11px;
$c11n-size-typography-sm: 14px;
$c11n-size-typography-md: 17px;
$c11n-size-typography-lg: 23px;
$c11n-size-typography-xl: 28px;
$c11n-size-typography-2xl: 34px;
$c11n-size-typography-3xl: 46px;
$c11n-size-typography-4xl: 57px;
$c11n-size-typography-5xl: 68px;
$c11n-size-typography-6xl: 92px;
$c11n-size-typography-loose: 1.5px;
$c11n-size-typography-default: 0px;
$c11n-size-typography-tight: -0.5px;
$c11n-size-typography-tighter: -1.5px;
$c11n-size-typography-tightest: -2px;
$c11n-font-family-sans: 'FF Mark', Arial, sans-serif;
$c11n-font-family-sans-narrow: 'FF Mark Narrow', Arial, sans-serif;
$c11n-font-family-display: Thorndale, 'Times New Roman', serif;
$c11n-font-weight-normal: 400;
$c11n-font-weight-bold: 700;
$c11n-font-weight-heavy: 900;
$c11n-component-button-border-radius: 50% !default;
$c11n-component-button-border-color-primary-hover: #040505 !default;
$c11n-component-button-border-color-secondary-default: #040505 !default;
$c11n-component-button-border-color-on-dark-hover: #ffffff !default;
$c11n-component-button-border-width: 2px !default;
$c11n-component-button-font-family: 'FF Mark', Arial, sans-serif !default;
$c11n-component-button-font-size-large: 17px !default;
$c11n-component-button-font-size-medium: 17px !default;
$c11n-component-button-font-size-small: 14px !default;
$c11n-component-button-font-weight: 700 !default;
$c11n-component-button-height-large: 64px !default;
$c11n-component-button-height-medium: 48px !default;
$c11n-component-button-height-small: 32px !default;
$c11n-component-button-icon-space: 8px !default; // left spacing
$c11n-component-button-icon-svg: 'plus-small' !default;
$c11n-component-button-inset-large: 32px !default;
$c11n-component-button-inset-medium: 24px !default;
$c11n-component-button-inset-small: 12px !default;
$c11n-component-button-label-text-size: 17px !default;
$c11n-component-button-label-text-color-on-dark-default: #040505 !default;
$c11n-component-button-label-text-color-on-dark-hover: #ffffff !default;
$c11n-component-button-min-width-large: 128px !default;
$c11n-component-button-min-width-medium: 128px !default;
$c11n-component-button-background-color-primary-default: #040505 !default;
$c11n-component-button-background-color-primary-hover: #ffffff !default;
$c11n-component-button-background-color-secondary-hover: #040505 !default;
$c11n-component-button-background-color-on-dark-default: #ffffff !default;