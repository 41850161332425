"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BankAccountMoneyTransferTypeEnum = exports.BankAccountBankTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var BankAccountBankTypeEnum;
(function (BankAccountBankTypeEnum) {
  BankAccountBankTypeEnum["DOMESTIC"] = "Domestic";
  BankAccountBankTypeEnum["FOREIGN"] = "Foreign";
})(BankAccountBankTypeEnum = exports.BankAccountBankTypeEnum || (exports.BankAccountBankTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var BankAccountMoneyTransferTypeEnum;
(function (BankAccountMoneyTransferTypeEnum) {
  BankAccountMoneyTransferTypeEnum["ACH"] = "ACH";
  BankAccountMoneyTransferTypeEnum["WIRE"] = "WIRE";
  BankAccountMoneyTransferTypeEnum["ALL"] = "ALL";
})(BankAccountMoneyTransferTypeEnum = exports.BankAccountMoneyTransferTypeEnum || (exports.BankAccountMoneyTransferTypeEnum = {}));
