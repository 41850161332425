import { Component, OnInit } from '@angular/core';
import { Account, JsonContent } from '@app/etfs-equities/models';
import { AccountService } from '@app/etfs-equities/services';
import {
  selectBondHoldings,
  selectCDHoldings,
  selectEtfHoldings,
  selectMutualFundHoldings,
  selectOptionHoldings,
  selectSelectedAccount,
  selectStockHoldings,
  TayneState,
} from '@app/etfs-equities/store';
import content from '@content/content.json';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-modern-holdings-page',
  templateUrl: './modern-holdings-page.component.html',
  styleUrl: './modern-holdings-page.component.scss',
})
export class ModernHoldingsPageComponent implements OnInit {
  // Public variables...
  content: JsonContent = content;
  hasAccountRetrievalError = false;

  // Public observables...
  selectedAccount$: Observable<Account.Account>;
  stocks$: Observable<Account.MappedHolding[]>;
  etfs$: Observable<Account.MappedHolding[]>;
  options$: Observable<Account.MappedHolding[]>;
  mutualFunds$: Observable<Account.MappedHolding[]>;
  CDs$: Observable<Account.MappedHolding[]>;
  bonds$: Observable<Account.MappedHolding[]>;

  constructor(private readonly store: Store<TayneState>, private readonly accountService: AccountService) {}

  get isRefreshingAccounts() {
    return this.accountService.isRefreshingAccounts;
  }

  get hasCriticalHoldingError() {
    return this.accountService.hasCriticalHoldingError;
  }

  ngOnInit() {
    this.selectedAccount$ = this.store.select(selectSelectedAccount);
    this.stocks$ = this.store.select(selectStockHoldings);
    this.etfs$ = this.store.select(selectEtfHoldings);
    this.options$ = this.store.select(selectOptionHoldings);
    this.mutualFunds$ = this.store.select(selectMutualFundHoldings);
    this.CDs$ = this.store.select(selectCDHoldings);
    this.bonds$ = this.store.select(selectBondHoldings);
  }

  setHasAccountRetrievalError(hasError: boolean) {
    this.hasAccountRetrievalError = hasError;
  }
}
