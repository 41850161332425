"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartialDetailedQuotePriceChangeDirectionEnum = exports.PartialDetailedQuoteVendorEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var PartialDetailedQuoteVendorEnum;
(function (PartialDetailedQuoteVendorEnum) {
  PartialDetailedQuoteVendorEnum["FACTSET"] = "FACTSET";
  PartialDetailedQuoteVendorEnum["REFINITIV"] = "REFINITIV";
})(PartialDetailedQuoteVendorEnum = exports.PartialDetailedQuoteVendorEnum || (exports.PartialDetailedQuoteVendorEnum = {}));
/**
    * @export
    * @enum {string}
    */
var PartialDetailedQuotePriceChangeDirectionEnum;
(function (PartialDetailedQuotePriceChangeDirectionEnum) {
  PartialDetailedQuotePriceChangeDirectionEnum["POSITIVE"] = "POSITIVE";
  PartialDetailedQuotePriceChangeDirectionEnum["NEGATIVE"] = "NEGATIVE";
  PartialDetailedQuotePriceChangeDirectionEnum["NEUTRAL"] = "NEUTRAL";
})(PartialDetailedQuotePriceChangeDirectionEnum = exports.PartialDetailedQuotePriceChangeDirectionEnum || (exports.PartialDetailedQuotePriceChangeDirectionEnum = {}));
