"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CostBasisDefaultMethodRequestModelCbaMethodCodeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var CostBasisDefaultMethodRequestModelCbaMethodCodeEnum;
(function (CostBasisDefaultMethodRequestModelCbaMethodCodeEnum) {
  CostBasisDefaultMethodRequestModelCbaMethodCodeEnum["FIRST_IN_FIRST_OUT"] = "FIRST_IN_FIRST_OUT";
  CostBasisDefaultMethodRequestModelCbaMethodCodeEnum["MINIMUM_TAX"] = "MINIMUM_TAX";
  CostBasisDefaultMethodRequestModelCbaMethodCodeEnum["SPECIFIC_IDENTIFICATION"] = "SPECIFIC_IDENTIFICATION";
  CostBasisDefaultMethodRequestModelCbaMethodCodeEnum["AVERAGE_COST"] = "AVERAGE_COST";
  CostBasisDefaultMethodRequestModelCbaMethodCodeEnum["MAXIMUM_GAIN"] = "MAXIMUM_GAIN";
  CostBasisDefaultMethodRequestModelCbaMethodCodeEnum["HIGHEST_IN_FIRST_OUT"] = "HIGHEST_IN_FIRST_OUT";
})(CostBasisDefaultMethodRequestModelCbaMethodCodeEnum = exports.CostBasisDefaultMethodRequestModelCbaMethodCodeEnum || (exports.CostBasisDefaultMethodRequestModelCbaMethodCodeEnum = {}));
