"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VgaCostBasisOrderCostBasisMethodEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var VgaCostBasisOrderCostBasisMethodEnum;
(function (VgaCostBasisOrderCostBasisMethodEnum) {
  VgaCostBasisOrderCostBasisMethodEnum["FIRST_IN_FIRST_OUT"] = "FIRST_IN_FIRST_OUT";
  VgaCostBasisOrderCostBasisMethodEnum["MINIMUM_TAX"] = "MINIMUM_TAX";
  VgaCostBasisOrderCostBasisMethodEnum["AVERAGE_COST"] = "AVERAGE_COST";
  VgaCostBasisOrderCostBasisMethodEnum["SPECIFIC_IDENTIFICATION"] = "SPECIFIC_IDENTIFICATION";
  VgaCostBasisOrderCostBasisMethodEnum["HIGHEST_IN_FIRST_OUT"] = "HIGHEST_IN_FIRST_OUT";
  VgaCostBasisOrderCostBasisMethodEnum["MAXIMUM_GAIN"] = "MAXIMUM_GAIN";
  VgaCostBasisOrderCostBasisMethodEnum["DFLT"] = "DFLT";
  VgaCostBasisOrderCostBasisMethodEnum["SPEC"] = "SPEC";
})(VgaCostBasisOrderCostBasisMethodEnum = exports.VgaCostBasisOrderCostBasisMethodEnum || (exports.VgaCostBasisOrderCostBasisMethodEnum = {}));
