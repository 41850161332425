<ng-container
  *ngIf="{
    accountIsMargin: (accountIsMargin$ | async)
  } as view"
>
  <h3 #header class="holdings-table-header c11n-text-md-bold" role="rowheader">{{ title }}</h3>
  <hr class="page-header-hr c11n-space-stack-3x c11n-space--force" />

  <div class="container-fluid c11n-space-stack-4x c11n-space--force">
    <tcx-product-list-table
      [columnDescriptions]="columnDescriptions"
      [rows]="getTableRows(view.accountIsMargin)"
      [showPaginationWithOnePage]="false"
      [itemsPerPage]="15"
      [tableLabel]="title"
      (pageNumberChanged)="handlePaginationChange($event, header)"
    ></tcx-product-list-table>
  </div>
</ng-container>
