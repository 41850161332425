@use 'sass:string';
@use 'sass:math';

// Valid options for $type are:
// 'regular'
// 'regular-italic' or 'italic'
// 'bold'
// 'bold-italic'
// 'heavy'
// 'heavy-italic'
// 'narrow'
// 'narrow-italic'
// 'narrow-bold'
// 'narrow-bold-italic'
// 'table' (only supported for 'md' and 'sm' size)
// 'article' (only supported for 'md' size)
// 
// Example use: @include c11n-font-rules('regular-italic');
//
// TODO: list recommended valid options for 'display' font
//
// To include font-sizing you must use the t-shirt sizes or 
// equivalent font-size value as follows:
// 'xs' or 11, 
// 'sm' or 14, 
// 'md' or 17, 
// 'lg' or 23, 
// 'xl' or 28, 
// '2xl' or 34,
// '3xl' or 46, 
// '4xl' or 57, 
// '5xl' or 68, 
// '6xl' or 92
// 
// @include c11n-font-rules('regular', 'lg');

@mixin c11n-font-rules ($type: 'regular', $size: 'md') {
  @if not string.index($type, 'narrow') and not string.index($type, 'display') {
    font-family: $c11n-font-family-sans;
  }
  @if string.index($type, 'narrow') {
    font-family: $c11n-font-family-sans-narrow;
  }
  @if string.index($type, 'display') {
    font-family: $c11n-font-family-display;
  }
  @if not string.index($type, 'bold') and not string.index($type, 'heavy') {
    font-weight: $c11n-font-weight-normal;
  }
  @if string.index($type, 'bold') {
    font-weight: $c11n-font-weight-bold;
  }
  @if string.index($type, 'heavy') {
    font-weight: $c11n-font-weight-heavy;
  }
  @if string.index($type, 'italic') {
    font-style: italic;
  }
  @if not string.index($type, 'italic') {
    font-style: normal;
  }
  @if $size != 0 {
    // extra-small
    @if $size == $c11n-size-typography-xs or $size == 'xs' {
      font-size: rem($c11n-size-typography-xs);
      line-height: $c11n-line-height-xs;
    }
    // small
    @if $size == $c11n-size-typography-sm or $size == 'sm' {
      font-size: rem($c11n-size-typography-sm);
      line-height: $c11n-line-height-sm;
      @if $type == 'table' or $type == 'narrow' {
        font-variant-numeric: tabular-nums;
      }
    }
    // medium
    @if $size == $c11n-size-typography-md or $size == 'md' {
      font-size: rem($c11n-size-typography-md);
      @if $type == 'article' {
        line-height: $c11n-line-height-md-article;
      }
      @else {
        line-height: $c11n-line-height-md;
      }
      @if $type == 'table' or $type == 'narrow' {
        font-variant-numeric: tabular-nums;
      }
    }
    // large
    @if $size == $c11n-size-typography-lg or $size == 'lg' {
      font-size: rem($c11n-size-typography-lg);
      line-height: $c11n-line-height-lg;
    }
    // extra large
    @if $size == $c11n-size-typography-xl or $size == 'xl' {
      font-size: rem($c11n-size-typography-xl);
      line-height: $c11n-line-height-xl;
    }
    // 2xl
    @if $size == $c11n-size-typography-2xl or $size == '2xl' {
      font-size: rem($c11n-size-typography-2xl);
      line-height: $c11n-line-height-2xl;
    }
    // 3xl
    @if $size == $c11n-size-typography-3xl or $size == '3xl' {
      font-size: rem($c11n-size-typography-3xl);
      line-height: $c11n-line-height-3xl;
    }
    // 4xl
    @if $size == $c11n-size-typography-4xl or $size == '4xl' {
      font-size: rem($c11n-size-typography-4xl);
      line-height: $c11n-line-height-4xl;
    }
    // 5xl
    @if $size == $c11n-size-typography-5xl or $size == '5xl' {
      font-size: rem($c11n-size-typography-5xl);
      line-height: $c11n-line-height-5xl;
    }
    // 6xl
    @if $size == $c11n-size-typography-6xl or $size == '6xl' {
      font-size: rem($c11n-size-typography-6xl);
      line-height: $c11n-line-height-6xl;
    }
  }
}
