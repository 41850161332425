"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResourceType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ResourceType;
(function (ResourceType) {
  ResourceType["ACCOUNTS_POSITIONS"] = "ACCOUNTS_POSITIONS";
  ResourceType["ACCOUNTS"] = "ACCOUNTS";
  ResourceType["ACCOUNTS_CLOSED_POSITIONS"] = "ACCOUNTS_CLOSED_POSITIONS";
})(ResourceType = exports.ResourceType || (exports.ResourceType = {}));
