"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RegisteredRolesRoleSubTypeEnum = exports.RegisteredRolesPermissionTypeEnum = exports.RegisteredRolesRoleTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var RegisteredRolesRoleTypeEnum;
(function (RegisteredRolesRoleTypeEnum) {
  RegisteredRolesRoleTypeEnum["VINA"] = "vina";
  RegisteredRolesRoleTypeEnum["AA"] = "AA";
  RegisteredRolesRoleTypeEnum["AC"] = "AC";
  RegisteredRolesRoleTypeEnum["AD"] = "AD";
  RegisteredRolesRoleTypeEnum["ADMN"] = "ADMN";
  RegisteredRolesRoleTypeEnum["AG"] = "AG";
  RegisteredRolesRoleTypeEnum["AI"] = "AI";
  RegisteredRolesRoleTypeEnum["AL"] = "AL";
  RegisteredRolesRoleTypeEnum["AN"] = "AN";
  RegisteredRolesRoleTypeEnum["AOAD"] = "AOAD";
  RegisteredRolesRoleTypeEnum["AOSI"] = "AOSI";
  RegisteredRolesRoleTypeEnum["AOTB"] = "AOTB";
  RegisteredRolesRoleTypeEnum["AS"] = "AS";
  RegisteredRolesRoleTypeEnum["ASP"] = "ASP";
  RegisteredRolesRoleTypeEnum["GASF"] = "GASF";
  RegisteredRolesRoleTypeEnum["ASPT"] = "ASPT";
  RegisteredRolesRoleTypeEnum["ASSC"] = "ASSC";
  RegisteredRolesRoleTypeEnum["AT"] = "AT";
  RegisteredRolesRoleTypeEnum["ATSA"] = "ATSA";
  RegisteredRolesRoleTypeEnum["AU"] = "AU";
  RegisteredRolesRoleTypeEnum["AUOF"] = "AUOF";
  RegisteredRolesRoleTypeEnum["AZCA"] = "AZCA";
  RegisteredRolesRoleTypeEnum["AZCS"] = "AZCS";
  RegisteredRolesRoleTypeEnum["AZMG"] = "AZMG";
  RegisteredRolesRoleTypeEnum["AZNA"] = "AZNA";
  RegisteredRolesRoleTypeEnum["AZOR"] = "AZOR";
  RegisteredRolesRoleTypeEnum["BE"] = "BE";
  RegisteredRolesRoleTypeEnum["BEAL"] = "BEAL";
  RegisteredRolesRoleTypeEnum["BEBE"] = "BEBE";
  RegisteredRolesRoleTypeEnum["BK"] = "BK";
  RegisteredRolesRoleTypeEnum["BL"] = "BL";
  RegisteredRolesRoleTypeEnum["BN"] = "BN";
  RegisteredRolesRoleTypeEnum["BNAL"] = "BNAL";
  RegisteredRolesRoleTypeEnum["BNBN"] = "BNBN";
  RegisteredRolesRoleTypeEnum["BO"] = "BO";
  RegisteredRolesRoleTypeEnum["BR"] = "BR";
  RegisteredRolesRoleTypeEnum["GASD"] = "GASD";
  RegisteredRolesRoleTypeEnum["BRKR"] = "BRKR";
  RegisteredRolesRoleTypeEnum["BSSA"] = "BSSA";
  RegisteredRolesRoleTypeEnum["BT"] = "BT";
  RegisteredRolesRoleTypeEnum["BU"] = "BU";
  RegisteredRolesRoleTypeEnum["CABN"] = "CABN";
  RegisteredRolesRoleTypeEnum["CANN"] = "CANN";
  RegisteredRolesRoleTypeEnum["CASC"] = "CASC";
  RegisteredRolesRoleTypeEnum["CC"] = "CC";
  RegisteredRolesRoleTypeEnum["CCNS"] = "CCNS";
  RegisteredRolesRoleTypeEnum["CE"] = "CE";
  RegisteredRolesRoleTypeEnum["CFRM"] = "CFRM";
  RegisteredRolesRoleTypeEnum["CG"] = "CG";
  RegisteredRolesRoleTypeEnum["CH"] = "CH";
  RegisteredRolesRoleTypeEnum["CL"] = "CL";
  RegisteredRolesRoleTypeEnum["CLHS"] = "CLHS";
  RegisteredRolesRoleTypeEnum["CLNT"] = "CLNT";
  RegisteredRolesRoleTypeEnum["CLSA"] = "CLSA";
  RegisteredRolesRoleTypeEnum["CM"] = "CM";
  RegisteredRolesRoleTypeEnum["CO"] = "CO";
  RegisteredRolesRoleTypeEnum["COBN"] = "COBN";
  RegisteredRolesRoleTypeEnum["CONS"] = "CONS";
  RegisteredRolesRoleTypeEnum["COTR"] = "COTR";
  RegisteredRolesRoleTypeEnum["CP"] = "CP";
  RegisteredRolesRoleTypeEnum["CR"] = "CR";
  RegisteredRolesRoleTypeEnum["CREW"] = "CREW";
  RegisteredRolesRoleTypeEnum["CS"] = "CS";
  RegisteredRolesRoleTypeEnum["CU"] = "CU";
  RegisteredRolesRoleTypeEnum["CUAL"] = "CUAL";
  RegisteredRolesRoleTypeEnum["CUCM"] = "CUCM";
  RegisteredRolesRoleTypeEnum["CUCO"] = "CUCO";
  RegisteredRolesRoleTypeEnum["CUCU"] = "CUCU";
  RegisteredRolesRoleTypeEnum["CUEA"] = "CUEA";
  RegisteredRolesRoleTypeEnum["CUGU"] = "CUGU";
  RegisteredRolesRoleTypeEnum["CULT"] = "CULT";
  RegisteredRolesRoleTypeEnum["CUNM"] = "CUNM";
  RegisteredRolesRoleTypeEnum["CUPA"] = "CUPA";
  RegisteredRolesRoleTypeEnum["CUPL"] = "CUPL";
  RegisteredRolesRoleTypeEnum["CUSD"] = "CUSD";
  RegisteredRolesRoleTypeEnum["CUSN"] = "CUSN";
  RegisteredRolesRoleTypeEnum["CUUS"] = "CUUS";
  RegisteredRolesRoleTypeEnum["DC"] = "DC";
  RegisteredRolesRoleTypeEnum["DDLC"] = "DDLC";
  RegisteredRolesRoleTypeEnum["DECD"] = "DECD";
  RegisteredRolesRoleTypeEnum["DL"] = "DL";
  RegisteredRolesRoleTypeEnum["DLYC"] = "DLYC";
  RegisteredRolesRoleTypeEnum["DP"] = "DP";
  RegisteredRolesRoleTypeEnum["DR"] = "DR";
  RegisteredRolesRoleTypeEnum["DS"] = "DS";
  RegisteredRolesRoleTypeEnum["DSRC"] = "DSRC";
  RegisteredRolesRoleTypeEnum["DV"] = "DV";
  RegisteredRolesRoleTypeEnum["EA"] = "EA";
  RegisteredRolesRoleTypeEnum["EC"] = "EC";
  RegisteredRolesRoleTypeEnum["ECON"] = "ECON";
  RegisteredRolesRoleTypeEnum["EE"] = "EE";
  RegisteredRolesRoleTypeEnum["EFTA"] = "EFTA";
  RegisteredRolesRoleTypeEnum["EMPL"] = "EMPL";
  RegisteredRolesRoleTypeEnum["EMPT"] = "EMPT";
  RegisteredRolesRoleTypeEnum["ER"] = "ER";
  RegisteredRolesRoleTypeEnum["EX"] = "EX";
  RegisteredRolesRoleTypeEnum["EXAD"] = "EXAD";
  RegisteredRolesRoleTypeEnum["EXAL"] = "EXAL";
  RegisteredRolesRoleTypeEnum["EXEX"] = "EXEX";
  RegisteredRolesRoleTypeEnum["FA"] = "FA";
  RegisteredRolesRoleTypeEnum["FD"] = "FD";
  RegisteredRolesRoleTypeEnum["FI"] = "FI";
  RegisteredRolesRoleTypeEnum["FL"] = "FL";
  RegisteredRolesRoleTypeEnum["FPOA"] = "FPOA";
  RegisteredRolesRoleTypeEnum["FR"] = "FR";
  RegisteredRolesRoleTypeEnum["FRDA"] = "FRDA";
  RegisteredRolesRoleTypeEnum["FRDB"] = "FRDB";
  RegisteredRolesRoleTypeEnum["FRDC"] = "FRDC";
  RegisteredRolesRoleTypeEnum["FSRS"] = "FSRS";
  RegisteredRolesRoleTypeEnum["FTRA"] = "FTRA";
  RegisteredRolesRoleTypeEnum["FTRB"] = "FTRB";
  RegisteredRolesRoleTypeEnum["FTRC"] = "FTRC";
  RegisteredRolesRoleTypeEnum["GC"] = "GC";
  RegisteredRolesRoleTypeEnum["GD"] = "GD";
  RegisteredRolesRoleTypeEnum["GASR"] = "GASR";
  RegisteredRolesRoleTypeEnum["GASL"] = "GASL";
  RegisteredRolesRoleTypeEnum["GDLA"] = "GDLA";
  RegisteredRolesRoleTypeEnum["GF"] = "GF";
  RegisteredRolesRoleTypeEnum["GFAA"] = "GFAA";
  RegisteredRolesRoleTypeEnum["GFOA"] = "GFOA";
  RegisteredRolesRoleTypeEnum["GIPR"] = "GIPR";
  RegisteredRolesRoleTypeEnum["GLAA"] = "GLAA";
  RegisteredRolesRoleTypeEnum["GLBL"] = "GLBL";
  RegisteredRolesRoleTypeEnum["GM"] = "GM";
  RegisteredRolesRoleTypeEnum["GP"] = "GP";
  RegisteredRolesRoleTypeEnum["GR"] = "GR";
  RegisteredRolesRoleTypeEnum["GS"] = "GS";
  RegisteredRolesRoleTypeEnum["GU"] = "GU";
  RegisteredRolesRoleTypeEnum["HLWL"] = "HLWL";
  RegisteredRolesRoleTypeEnum["HR"] = "HR";
  RegisteredRolesRoleTypeEnum["HS"] = "HS";
  RegisteredRolesRoleTypeEnum["GLAS"] = "GLAS";
  RegisteredRolesRoleTypeEnum["HSMB"] = "HSMB";
  RegisteredRolesRoleTypeEnum["IAA"] = "IAA";
  RegisteredRolesRoleTypeEnum["IABN"] = "IABN";
  RegisteredRolesRoleTypeEnum["IADM"] = "IADM";
  RegisteredRolesRoleTypeEnum["IBES"] = "IBES";
  RegisteredRolesRoleTypeEnum["IC"] = "IC";
  RegisteredRolesRoleTypeEnum["ICH"] = "ICH";
  RegisteredRolesRoleTypeEnum["ICSH"] = "ICSH";
  RegisteredRolesRoleTypeEnum["IEST"] = "IEST";
  RegisteredRolesRoleTypeEnum["IEXE"] = "IEXE";
  RegisteredRolesRoleTypeEnum["IFRA"] = "IFRA";
  RegisteredRolesRoleTypeEnum["IFRM"] = "IFRM";
  RegisteredRolesRoleTypeEnum["IFRS"] = "IFRS";
  RegisteredRolesRoleTypeEnum["IFTS"] = "IFTS";
  RegisteredRolesRoleTypeEnum["IFVW"] = "IFVW";
  RegisteredRolesRoleTypeEnum["IICA"] = "IICA";
  RegisteredRolesRoleTypeEnum["IICS"] = "IICS";
  RegisteredRolesRoleTypeEnum["IIOB"] = "IIOB";
  RegisteredRolesRoleTypeEnum["IIOD"] = "IIOD";
  RegisteredRolesRoleTypeEnum["IIOF"] = "IIOF";
  RegisteredRolesRoleTypeEnum["IIOG"] = "IIOG";
  RegisteredRolesRoleTypeEnum["IIOO"] = "IIOO";
  RegisteredRolesRoleTypeEnum["IIOP"] = "IIOP";
  RegisteredRolesRoleTypeEnum["IIOS"] = "IIOS";
  RegisteredRolesRoleTypeEnum["IIOU"] = "IIOU";
  RegisteredRolesRoleTypeEnum["IIPA"] = "IIPA";
  RegisteredRolesRoleTypeEnum["IIPM"] = "IIPM";
  RegisteredRolesRoleTypeEnum["IISR"] = "IISR";
  RegisteredRolesRoleTypeEnum["IL"] = "IL";
  RegisteredRolesRoleTypeEnum["ILRR"] = "ILRR";
  RegisteredRolesRoleTypeEnum["IMKR"] = "IMKR";
  RegisteredRolesRoleTypeEnum["IMTR"] = "IMTR";
  RegisteredRolesRoleTypeEnum["IN"] = "IN";
  RegisteredRolesRoleTypeEnum["INAD"] = "INAD";
  RegisteredRolesRoleTypeEnum["INAN"] = "INAN";
  RegisteredRolesRoleTypeEnum["INCN"] = "INCN";
  RegisteredRolesRoleTypeEnum["INPR"] = "INPR";
  RegisteredRolesRoleTypeEnum["INSE"] = "INSE";
  RegisteredRolesRoleTypeEnum["INSP"] = "INSP";
  RegisteredRolesRoleTypeEnum["INTR"] = "INTR";
  RegisteredRolesRoleTypeEnum["IO"] = "IO";
  RegisteredRolesRoleTypeEnum["IOBN"] = "IOBN";
  RegisteredRolesRoleTypeEnum["IP"] = "IP";
  RegisteredRolesRoleTypeEnum["IPAG"] = "IPAG";
  RegisteredRolesRoleTypeEnum["IPBT"] = "IPBT";
  RegisteredRolesRoleTypeEnum["IPCM"] = "IPCM";
  RegisteredRolesRoleTypeEnum["IPCN"] = "IPCN";
  RegisteredRolesRoleTypeEnum["IPDB"] = "IPDB";
  RegisteredRolesRoleTypeEnum["IPEF"] = "IPEF";
  RegisteredRolesRoleTypeEnum["IPHR"] = "IPHR";
  RegisteredRolesRoleTypeEnum["IPIA"] = "IPIA";
  RegisteredRolesRoleTypeEnum["IPII"] = "IPII";
  RegisteredRolesRoleTypeEnum["IPIN"] = "IPIN";
  RegisteredRolesRoleTypeEnum["IPIR"] = "IPIR";
  RegisteredRolesRoleTypeEnum["IPPC"] = "IPPC";
  RegisteredRolesRoleTypeEnum["IPPR"] = "IPPR";
  RegisteredRolesRoleTypeEnum["IPPV"] = "IPPV";
  RegisteredRolesRoleTypeEnum["IPSA"] = "IPSA";
  RegisteredRolesRoleTypeEnum["IPSM"] = "IPSM";
  RegisteredRolesRoleTypeEnum["IPSS"] = "IPSS";
  RegisteredRolesRoleTypeEnum["IQTR"] = "IQTR";
  RegisteredRolesRoleTypeEnum["IR"] = "IR";
  RegisteredRolesRoleTypeEnum["IREL"] = "IREL";
  RegisteredRolesRoleTypeEnum["IRKH"] = "IRKH";
  RegisteredRolesRoleTypeEnum["IRKM"] = "IRKM";
  RegisteredRolesRoleTypeEnum["IRLR"] = "IRLR";
  RegisteredRolesRoleTypeEnum["IRPS"] = "IRPS";
  RegisteredRolesRoleTypeEnum["IRSM"] = "IRSM";
  RegisteredRolesRoleTypeEnum["IRTR"] = "IRTR";
  RegisteredRolesRoleTypeEnum["ISAF"] = "ISAF";
  RegisteredRolesRoleTypeEnum["ISAS"] = "ISAS";
  RegisteredRolesRoleTypeEnum["ITAD"] = "ITAD";
  RegisteredRolesRoleTypeEnum["IUA"] = "IUA";
  RegisteredRolesRoleTypeEnum["IVCO"] = "IVCO";
  RegisteredRolesRoleTypeEnum["IWCC"] = "IWCC";
  RegisteredRolesRoleTypeEnum["IWHF"] = "IWHF";
  RegisteredRolesRoleTypeEnum["IWIA"] = "IWIA";
  RegisteredRolesRoleTypeEnum["IWIB"] = "IWIB";
  RegisteredRolesRoleTypeEnum["IWIC"] = "IWIC";
  RegisteredRolesRoleTypeEnum["IWID"] = "IWID";
  RegisteredRolesRoleTypeEnum["IWRA"] = "IWRA";
  RegisteredRolesRoleTypeEnum["IWRB"] = "IWRB";
  RegisteredRolesRoleTypeEnum["IWTP"] = "IWTP";
  RegisteredRolesRoleTypeEnum["IWVN"] = "IWVN";
  RegisteredRolesRoleTypeEnum["IWWA"] = "IWWA";
  RegisteredRolesRoleTypeEnum["IWWB"] = "IWWB";
  RegisteredRolesRoleTypeEnum["I4_AR"] = "I4AR";
  RegisteredRolesRoleTypeEnum["JA"] = "JA";
  RegisteredRolesRoleTypeEnum["JANN"] = "JANN";
  RegisteredRolesRoleTypeEnum["JOWN"] = "JOWN";
  RegisteredRolesRoleTypeEnum["JS"] = "JS";
  RegisteredRolesRoleTypeEnum["JVEN"] = "JVEN";
  RegisteredRolesRoleTypeEnum["LA"] = "LA";
  RegisteredRolesRoleTypeEnum["LC"] = "LC";
  RegisteredRolesRoleTypeEnum["LEAD"] = "LEAD";
  RegisteredRolesRoleTypeEnum["LP"] = "LP";
  RegisteredRolesRoleTypeEnum["LPOA"] = "LPOA";
  RegisteredRolesRoleTypeEnum["LT"] = "LT";
  RegisteredRolesRoleTypeEnum["LTEX"] = "LTEX";
  RegisteredRolesRoleTypeEnum["LTQT"] = "LTQT";
  RegisteredRolesRoleTypeEnum["MABN"] = "MABN";
  RegisteredRolesRoleTypeEnum["MC"] = "MC";
  RegisteredRolesRoleTypeEnum["MGMT"] = "MGMT";
  RegisteredRolesRoleTypeEnum["MINR"] = "MINR";
  RegisteredRolesRoleTypeEnum["ML"] = "ML";
  RegisteredRolesRoleTypeEnum["MM"] = "MM";
  RegisteredRolesRoleTypeEnum["MN"] = "MN";
  RegisteredRolesRoleTypeEnum["MOWN"] = "MOWN";
  RegisteredRolesRoleTypeEnum["MPOO"] = "MPOO";
  RegisteredRolesRoleTypeEnum["MR"] = "MR";
  RegisteredRolesRoleTypeEnum["NA"] = "NA";
  RegisteredRolesRoleTypeEnum["NC"] = "NC";
  RegisteredRolesRoleTypeEnum["NCCA"] = "NCCA";
  RegisteredRolesRoleTypeEnum["NCMG"] = "NCMG";
  RegisteredRolesRoleTypeEnum["NCNA"] = "NCNA";
  RegisteredRolesRoleTypeEnum["NCOR"] = "NCOR";
  RegisteredRolesRoleTypeEnum["NF"] = "NF";
  RegisteredRolesRoleTypeEnum["NIIV"] = "NIIV";
  RegisteredRolesRoleTypeEnum["NM"] = "NM";
  RegisteredRolesRoleTypeEnum["NMNE"] = "NMNE";
  RegisteredRolesRoleTypeEnum["NO"] = "NO";
  RegisteredRolesRoleTypeEnum["NOMO"] = "NOMO";
  RegisteredRolesRoleTypeEnum["NPB1"] = "NPB1";
  RegisteredRolesRoleTypeEnum["NPB2"] = "NPB2";
  RegisteredRolesRoleTypeEnum["NPPT"] = "NPPT";
  RegisteredRolesRoleTypeEnum["NPQ1"] = "NPQ1";
  RegisteredRolesRoleTypeEnum["NPQ2"] = "NPQ2";
  RegisteredRolesRoleTypeEnum["NTWK"] = "NTWK";
  RegisteredRolesRoleTypeEnum["OC"] = "OC";
  RegisteredRolesRoleTypeEnum["OF"] = "OF";
  RegisteredRolesRoleTypeEnum["OFC"] = "OFC";
  RegisteredRolesRoleTypeEnum["ORGN"] = "ORGN";
  RegisteredRolesRoleTypeEnum["OVRD"] = "OVRD";
  RegisteredRolesRoleTypeEnum["OW"] = "OW";
  RegisteredRolesRoleTypeEnum["OWNR"] = "OWNR";
  RegisteredRolesRoleTypeEnum["OWNS"] = "OWNS";
  RegisteredRolesRoleTypeEnum["OWTR"] = "OWTR";
  RegisteredRolesRoleTypeEnum["PA"] = "PA";
  RegisteredRolesRoleTypeEnum["PAAD"] = "PAAD";
  RegisteredRolesRoleTypeEnum["PABN"] = "PABN";
  RegisteredRolesRoleTypeEnum["PACA"] = "PACA";
  RegisteredRolesRoleTypeEnum["PACS"] = "PACS";
  RegisteredRolesRoleTypeEnum["PAMG"] = "PAMG";
  RegisteredRolesRoleTypeEnum["PANA"] = "PANA";
  RegisteredRolesRoleTypeEnum["PANN"] = "PANN";
  RegisteredRolesRoleTypeEnum["PAOR"] = "PAOR";
  RegisteredRolesRoleTypeEnum["PART"] = "PART";
  RegisteredRolesRoleTypeEnum["PASA"] = "PASA";
  RegisteredRolesRoleTypeEnum["PAVB"] = "PAVB";
  RegisteredRolesRoleTypeEnum["PAYE"] = "PAYE";
  RegisteredRolesRoleTypeEnum["PAYR"] = "PAYR";
  RegisteredRolesRoleTypeEnum["PB"] = "PB";
  RegisteredRolesRoleTypeEnum["PC"] = "PC";
  RegisteredRolesRoleTypeEnum["PCTS"] = "PCTS";
  RegisteredRolesRoleTypeEnum["PD"] = "PD";
  RegisteredRolesRoleTypeEnum["PDAG"] = "PDAG";
  RegisteredRolesRoleTypeEnum["PE"] = "PE";
  RegisteredRolesRoleTypeEnum["PL"] = "PL";
  RegisteredRolesRoleTypeEnum["PLAD"] = "PLAD";
  RegisteredRolesRoleTypeEnum["PLTR"] = "PLTR";
  RegisteredRolesRoleTypeEnum["PLVW"] = "PLVW";
  RegisteredRolesRoleTypeEnum["PM"] = "PM";
  RegisteredRolesRoleTypeEnum["PO"] = "PO";
  RegisteredRolesRoleTypeEnum["POBN"] = "POBN";
  RegisteredRolesRoleTypeEnum["POOL"] = "POOL";
  RegisteredRolesRoleTypeEnum["POWN"] = "POWN";
  RegisteredRolesRoleTypeEnum["PP"] = "PP";
  RegisteredRolesRoleTypeEnum["PPAG"] = "PPAG";
  RegisteredRolesRoleTypeEnum["PR"] = "PR";
  RegisteredRolesRoleTypeEnum["PRCT"] = "PRCT";
  RegisteredRolesRoleTypeEnum["PRIN"] = "PRIN";
  RegisteredRolesRoleTypeEnum["PRRT"] = "PRRT";
  RegisteredRolesRoleTypeEnum["PRTR"] = "PRTR";
  RegisteredRolesRoleTypeEnum["PS"] = "PS";
  RegisteredRolesRoleTypeEnum["PSMB"] = "PSMB";
  RegisteredRolesRoleTypeEnum["PV"] = "PV";
  RegisteredRolesRoleTypeEnum["RCAG"] = "RCAG";
  RegisteredRolesRoleTypeEnum["RCRA"] = "RCRA";
  RegisteredRolesRoleTypeEnum["RE"] = "RE";
  RegisteredRolesRoleTypeEnum["REF"] = "REF";
  RegisteredRolesRoleTypeEnum["RESP"] = "RESP";
  RegisteredRolesRoleTypeEnum["PSGC"] = "PSGC";
  RegisteredRolesRoleTypeEnum["REXU"] = "REXU";
  RegisteredRolesRoleTypeEnum["RGRL"] = "RGRL";
  RegisteredRolesRoleTypeEnum["RI"] = "RI";
  RegisteredRolesRoleTypeEnum["RK"] = "RK";
  RegisteredRolesRoleTypeEnum["RKCO"] = "RKCO";
  RegisteredRolesRoleTypeEnum["RKTR"] = "RKTR";
  RegisteredRolesRoleTypeEnum["RKVW"] = "RKVW";
  RegisteredRolesRoleTypeEnum["RM"] = "RM";
  RegisteredRolesRoleTypeEnum["RMFA"] = "RMFA";
  RegisteredRolesRoleTypeEnum["RMGR"] = "RMGR";
  RegisteredRolesRoleTypeEnum["RP"] = "RP";
  RegisteredRolesRoleTypeEnum["RPLN"] = "RPLN";
  RegisteredRolesRoleTypeEnum["SASC"] = "SASC";
  RegisteredRolesRoleTypeEnum["SBAO"] = "SBAO";
  RegisteredRolesRoleTypeEnum["SBOC"] = "SBOC";
  RegisteredRolesRoleTypeEnum["SBPA"] = "SBPA";
  RegisteredRolesRoleTypeEnum["SBPC"] = "SBPC";
  RegisteredRolesRoleTypeEnum["SBPP"] = "SBPP";
  RegisteredRolesRoleTypeEnum["SBPS"] = "SBPS";
  RegisteredRolesRoleTypeEnum["SBTP"] = "SBTP";
  RegisteredRolesRoleTypeEnum["SBTR"] = "SBTR";
  RegisteredRolesRoleTypeEnum["SC"] = "SC";
  RegisteredRolesRoleTypeEnum["SCSA"] = "SCSA";
  RegisteredRolesRoleTypeEnum["SD"] = "SD";
  RegisteredRolesRoleTypeEnum["SE"] = "SE";
  RegisteredRolesRoleTypeEnum["SEGM"] = "SEGM";
  RegisteredRolesRoleTypeEnum["SF"] = "SF";
  RegisteredRolesRoleTypeEnum["SG"] = "SG";
  RegisteredRolesRoleTypeEnum["SGAC"] = "SGAC";
  RegisteredRolesRoleTypeEnum["SGAM"] = "SGAM";
  RegisteredRolesRoleTypeEnum["SGAO"] = "SGAO";
  RegisteredRolesRoleTypeEnum["SGAT"] = "SGAT";
  RegisteredRolesRoleTypeEnum["SGBC"] = "SGBC";
  RegisteredRolesRoleTypeEnum["SGCB"] = "SGCB";
  RegisteredRolesRoleTypeEnum["SGCC"] = "SGCC";
  RegisteredRolesRoleTypeEnum["SGCF"] = "SGCF";
  RegisteredRolesRoleTypeEnum["SGFP"] = "SGFP";
  RegisteredRolesRoleTypeEnum["SGIA"] = "SGIA";
  RegisteredRolesRoleTypeEnum["SGIB"] = "SGIB";
  RegisteredRolesRoleTypeEnum["SGIO"] = "SGIO";
  RegisteredRolesRoleTypeEnum["SGIP"] = "SGIP";
  RegisteredRolesRoleTypeEnum["SGOT"] = "SGOT";
  RegisteredRolesRoleTypeEnum["SGPB"] = "SGPB";
  RegisteredRolesRoleTypeEnum["SGPD"] = "SGPD";
  RegisteredRolesRoleTypeEnum["SGQR"] = "SGQR";
  RegisteredRolesRoleTypeEnum["SGRM"] = "SGRM";
  RegisteredRolesRoleTypeEnum["SGSC"] = "SGSC";
  RegisteredRolesRoleTypeEnum["SGTM"] = "SGTM";
  RegisteredRolesRoleTypeEnum["SGTS"] = "SGTS";
  RegisteredRolesRoleTypeEnum["SGTT"] = "SGTT";
  RegisteredRolesRoleTypeEnum["SGTX"] = "SGTX";
  RegisteredRolesRoleTypeEnum["SH"] = "SH";
  RegisteredRolesRoleTypeEnum["SHAL"] = "SHAL";
  RegisteredRolesRoleTypeEnum["SHBN"] = "SHBN";
  RegisteredRolesRoleTypeEnum["SHGP"] = "SHGP";
  RegisteredRolesRoleTypeEnum["SHJS"] = "SHJS";
  RegisteredRolesRoleTypeEnum["SHLP"] = "SHLP";
  RegisteredRolesRoleTypeEnum["SHMN"] = "SHMN";
  RegisteredRolesRoleTypeEnum["SHNO"] = "SHNO";
  RegisteredRolesRoleTypeEnum["SHPS"] = "SHPS";
  RegisteredRolesRoleTypeEnum["SHRM"] = "SHRM";
  RegisteredRolesRoleTypeEnum["SHSH"] = "SHSH";
  RegisteredRolesRoleTypeEnum["SL"] = "SL";
  RegisteredRolesRoleTypeEnum["SM"] = "SM";
  RegisteredRolesRoleTypeEnum["SN"] = "SN";
  RegisteredRolesRoleTypeEnum["SNRC"] = "SNRC";
  RegisteredRolesRoleTypeEnum["SO"] = "SO";
  RegisteredRolesRoleTypeEnum["SOLP"] = "SOLP";
  RegisteredRolesRoleTypeEnum["SP"] = "SP";
  RegisteredRolesRoleTypeEnum["SPSE"] = "SPSE";
  RegisteredRolesRoleTypeEnum["SR"] = "SR";
  RegisteredRolesRoleTypeEnum["SRFC"] = "SRFC";
  RegisteredRolesRoleTypeEnum["SS"] = "SS";
  RegisteredRolesRoleTypeEnum["SSNA"] = "SSNA";
  RegisteredRolesRoleTypeEnum["SX02"] = "SX02";
  RegisteredRolesRoleTypeEnum["SX40"] = "SX40";
  RegisteredRolesRoleTypeEnum["SX70"] = "SX70";
  RegisteredRolesRoleTypeEnum["SX80"] = "SX80";
  RegisteredRolesRoleTypeEnum["SX90"] = "SX90";
  RegisteredRolesRoleTypeEnum["SX91"] = "SX91";
  RegisteredRolesRoleTypeEnum["SX92"] = "SX92";
  RegisteredRolesRoleTypeEnum["TC"] = "TC";
  RegisteredRolesRoleTypeEnum["TE"] = "TE";
  RegisteredRolesRoleTypeEnum["TPA"] = "TPA";
  RegisteredRolesRoleTypeEnum["TPSA"] = "TPSA";
  RegisteredRolesRoleTypeEnum["TR"] = "TR";
  RegisteredRolesRoleTypeEnum["TRAL"] = "TRAL";
  RegisteredRolesRoleTypeEnum["TRPO"] = "TRPO";
  RegisteredRolesRoleTypeEnum["TRRE"] = "TRRE";
  RegisteredRolesRoleTypeEnum["TRSC"] = "TRSC";
  RegisteredRolesRoleTypeEnum["TRST"] = "TRST";
  RegisteredRolesRoleTypeEnum["TRTR"] = "TRTR";
  RegisteredRolesRoleTypeEnum["TT"] = "TT";
  RegisteredRolesRoleTypeEnum["TXTO"] = "TXTO";
  RegisteredRolesRoleTypeEnum["UC"] = "UC";
  RegisteredRolesRoleTypeEnum["UN"] = "UN";
  RegisteredRolesRoleTypeEnum["UOBT"] = "UOBT";
  RegisteredRolesRoleTypeEnum["UPAG"] = "UPAG";
  RegisteredRolesRoleTypeEnum["US"] = "US";
  RegisteredRolesRoleTypeEnum["USDB"] = "USDB";
  RegisteredRolesRoleTypeEnum["USER"] = "USER";
  RegisteredRolesRoleTypeEnum["USGR"] = "USGR";
  RegisteredRolesRoleTypeEnum["USRA"] = "USRA";
  RegisteredRolesRoleTypeEnum["USSG"] = "USSG";
  RegisteredRolesRoleTypeEnum["VERS"] = "VERS";
  RegisteredRolesRoleTypeEnum["VIVP"] = "VIVP";
  RegisteredRolesRoleTypeEnum["VNDR"] = "VNDR";
  RegisteredRolesRoleTypeEnum["VP"] = "VP";
  RegisteredRolesRoleTypeEnum["VRPA"] = "VRPA";
  RegisteredRolesRoleTypeEnum["WEBC"] = "WEBC";
  RegisteredRolesRoleTypeEnum["WF"] = "WF";
  RegisteredRolesRoleTypeEnum["WFNC"] = "WFNC";
  RegisteredRolesRoleTypeEnum["WVOA"] = "WVOA";
  RegisteredRolesRoleTypeEnum["XH"] = "XH";
  RegisteredRolesRoleTypeEnum["XS"] = "XS";
  RegisteredRolesRoleTypeEnum["XW"] = "XW";
})(RegisteredRolesRoleTypeEnum = exports.RegisteredRolesRoleTypeEnum || (exports.RegisteredRolesRoleTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var RegisteredRolesPermissionTypeEnum;
(function (RegisteredRolesPermissionTypeEnum) {
  RegisteredRolesPermissionTypeEnum["TRNS"] = "TRNS";
  RegisteredRolesPermissionTypeEnum["VIEW"] = "VIEW";
})(RegisteredRolesPermissionTypeEnum = exports.RegisteredRolesPermissionTypeEnum || (exports.RegisteredRolesPermissionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var RegisteredRolesRoleSubTypeEnum;
(function (RegisteredRolesRoleSubTypeEnum) {
  RegisteredRolesRoleSubTypeEnum["O"] = "O";
  RegisteredRolesRoleSubTypeEnum["N"] = "N";
  RegisteredRolesRoleSubTypeEnum["PAIO"] = "PAIO";
  RegisteredRolesRoleSubTypeEnum["PAVC"] = "PAVC";
})(RegisteredRolesRoleSubTypeEnum = exports.RegisteredRolesRoleSubTypeEnum || (exports.RegisteredRolesRoleSubTypeEnum = {}));
