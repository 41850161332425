"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContributionFamilySummaryFamilyTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ContributionFamilySummaryFamilyTypeEnum;
(function (ContributionFamilySummaryFamilyTypeEnum) {
  ContributionFamilySummaryFamilyTypeEnum["TRADITIONAL_ROLLOVER_ROTH_AND_SEP_IRA"] = "TRADITIONAL_ROLLOVER_ROTH_AND_SEP_IRA";
  ContributionFamilySummaryFamilyTypeEnum["SIMPLE_IRA"] = "SIMPLE_IRA";
  ContributionFamilySummaryFamilyTypeEnum["EDUCATION_SAVINGS"] = "EDUCATION_SAVINGS";
  ContributionFamilySummaryFamilyTypeEnum["INHERITED_AND_INHERITED_ROTH_IRA"] = "INHERITED_AND_INHERITED_ROTH_IRA";
  ContributionFamilySummaryFamilyTypeEnum["FOUR_ZERO_THREE_B"] = "FOUR_ZERO_THREE_B";
  ContributionFamilySummaryFamilyTypeEnum["INDIVIDUAL_FOUR_ZERO_ONE_K_AND_INDIVIDUAL_ROTH_FOUR_ZERO_ONE_K"] = "INDIVIDUAL_FOUR_ZERO_ONE_K_AND_INDIVIDUAL_ROTH_FOUR_ZERO_ONE_K";
})(ContributionFamilySummaryFamilyTypeEnum = exports.ContributionFamilySummaryFamilyTypeEnum || (exports.ContributionFamilySummaryFamilyTypeEnum = {}));
