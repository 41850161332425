"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WithdrawalRetirementDistributionInstructionsReasonCodeEnum = exports.WithdrawalRetirementDistributionInstructionsDistributionTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var WithdrawalRetirementDistributionInstructionsDistributionTypeEnum;
(function (WithdrawalRetirementDistributionInstructionsDistributionTypeEnum) {
  WithdrawalRetirementDistributionInstructionsDistributionTypeEnum["EXCESS_CONTRIBUTION"] = "EXCESS_CONTRIBUTION";
  WithdrawalRetirementDistributionInstructionsDistributionTypeEnum["EXCESS_EARNINGS"] = "EXCESS_EARNINGS";
  WithdrawalRetirementDistributionInstructionsDistributionTypeEnum["HARDSHIP"] = "HARDSHIP";
  WithdrawalRetirementDistributionInstructionsDistributionTypeEnum["I401_K"] = "I401K";
  WithdrawalRetirementDistributionInstructionsDistributionTypeEnum["LUMP_SUM"] = "LUMP_SUM";
  WithdrawalRetirementDistributionInstructionsDistributionTypeEnum["NORMAL"] = "NORMAL";
})(WithdrawalRetirementDistributionInstructionsDistributionTypeEnum = exports.WithdrawalRetirementDistributionInstructionsDistributionTypeEnum || (exports.WithdrawalRetirementDistributionInstructionsDistributionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var WithdrawalRetirementDistributionInstructionsReasonCodeEnum;
(function (WithdrawalRetirementDistributionInstructionsReasonCodeEnum) {
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_01"] = "01";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_02"] = "02";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_04"] = "04";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_05"] = "05";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_07"] = "07";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_08"] = "08";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_10"] = "10";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_11"] = "11";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_12"] = "12";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_1_D"] = "1D";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_1_P"] = "1P";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_2_D"] = "2D";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_2_P"] = "2P";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_3_M"] = "3M";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_4_D"] = "4D";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_4_G"] = "4G";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_4_M"] = "4M";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_4_P"] = "4P";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_81"] = "81";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_82"] = "82";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_84"] = "84";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_8_J"] = "8J";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["_8_M"] = "8M";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["B"] = "B";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["B1"] = "B1";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["B2"] = "B2";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["B4"] = "B4";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["B7"] = "B7";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["B8"] = "B8";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["BD"] = "BD";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["BG"] = "BG";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["BP"] = "BP";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["D"] = "D";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["G"] = "G";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["H"] = "H";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["H4"] = "H4";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["J"] = "J";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["M"] = "M";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["N"] = "N";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["P"] = "P";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["PJ"] = "PJ";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["PM"] = "PM";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["PT"] = "PT";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["Q"] = "Q";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["R"] = "R";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["S"] = "S";
  WithdrawalRetirementDistributionInstructionsReasonCodeEnum["T"] = "T";
})(WithdrawalRetirementDistributionInstructionsReasonCodeEnum = exports.WithdrawalRetirementDistributionInstructionsReasonCodeEnum || (exports.WithdrawalRetirementDistributionInstructionsReasonCodeEnum = {}));
