// Below class and styling is to highlight responsive sections for skeleton story RTLTL-2883.
// Feel free to adjust/remove as needed as trade cancel ui stories are implemented
@import '@vg-constellation/angular-17/styles/spacing';

// special case where we remove the border and shadow from the quote component
.cancel-page-quote {
  .twe-card {
    box-shadow: none;
    border-width: 1px 0 0;
    border-radius: 0;
    padding: $c11n-size-space-5x 0 0 0;

    .dollar-based-indicator {
      display: none;
    }
  }
}
// -------

.cancel-page-section {
  margin-bottom: 24px;
  min-height: 150px;
}
