{
  "name": "trade-web-equity-etf",
  "version": "2.0.4",
  "description": "Trade Web Equity and ETF Angular Project",
  "author": {
    "name": "CX Retail Trade 1",
    "email": "Tradeone@vanguard.com"
  },
  "config": {
    "deploy-url": "pkg/2.0.3/web",
    "port": "8081"
  },
  "files": [
    "/dist"
  ],
  "contributors": [
    "james_zhao@vanguard.com",
    "steven_cherry@vanguard.com",
    "beatriz_hernandez@vanguard.com",
    "kateryna_grynova@vanguard.com",
    "jorge_sierra@vanguard.com",
    "talia_irgangladen@vanguard.com",
    "terrence_spicer-williams@vanguard.com",
    "dmitrii_rabotnov@vanguard.com",
    "orkhan_aliyev@vanguard.com",
    "piotr_kossowski@vanguard.com",
    "lukasz_koblyanski@vanguard.com"
  ],
  "cypress-cucumber-preprocessor": {
    "nonGlobalStepDefinitions": false,
    "step_definitions": "cypress/integration/",
    "cucumberJson": {
      "generate": true,
      "outputFolder": "reports/cucumber/json",
      "filePrefix": "",
      "fileSuffix": ".cucumber"
    }
  },
  "license": "SEE LICENSE IN LICENSE",
  "private": true,
  "repository": {
    "type": "git",
    "url": "http://stash.vanguard.com:7990/scm/TWE/trade-web.angular.git"
  },
  "scripts": {
    "build": "npm run write:rev && npm run build:all",
    "build:all": "npm run build:client:all && npm run build:server && npm run build:client:serverless:all",
    "build:client:all": "npm run build:client:prod && npm run build:client:non-prod-test && npm run build:client:non-prod-eng",
    "build:client:prod": "cross-env-shell ng build --configuration=prod --deploy-url=pkg/$npm_package_version/web/",
    "build:client:non-prod-test": "cross-env-shell ng build --configuration=non-prod-test --deploy-url=pkg/$npm_package_version/web/",
    "build:client:non-prod-eng": "cross-env-shell ng build --configuration=non-prod-eng --deploy-url=pkg/$npm_package_version/web/",
    "build:client:local": "cross-env-shell ng build --configuration=local --deploy-url=pkg/$npm_package_version/web/",
    "build:client:serverless:all": "npm run build:client:prod:serverless && npm run build:client:non-prod-test:serverless && npm run build:client:non-prod-eng:serverless",
    "build:client:prod:serverless": "cross-env-shell NODE_ENV=prod ng run trade-web-angular:serverless:prod --base-href ./",
    "build:client:non-prod-test:serverless": "cross-env-shell NODE_ENV=non-prod-test ng run trade-web-angular:serverless:non-prod-test --base-href ./",
    "build:client:non-prod-eng:serverless": "cross-env-shell NODE_ENV=non-prod-eng ng run trade-web-angular:serverless:non-prod-eng --base-href ./",
    "build:client:local:serverless": "cross-env-shell NODE_ENV=local ng run trade-web-angular:serverless:local",
    "build:pipeline": "npm run write:rev && npm run build:client:local && npm run build:server",
    "build:server": "npm run write:rev && npm run webpack:server",
    "cypress:cleanup": "rm -rf reports && mkdir reports && mkdir reports/coverage &&  mkdir reports/cucumber && mkdir reports/tests",
    "cypress:set-env": "cross-env $(node cypress/set-env.js)",
    "cypress:open": "cypress open",
    "cypress:copy-feature-files": "cross-env cp cypress/integration/**/*.feature dist/cypress/integration",
    "cypress:generate-features": "node tasks/generate-cucumber-features.js",
    "cypress:report": "node tasks/cucumber-report.js",
    "cypress:build": "tsc -p cypress; npm run cypress:copy-feature-files",
    "cypress:build:dev": "tsc -p cypress -w",
    "cypress:dev": "npm run write:rev && npm run cypress:set-env -- run-p -r serve cypress:build:dev cypress:open",
    "cypress:run": "cypress run",
    "cypress:run:parallel": "VRT_TRACK=1 VRT_BRANCHNAME=`node ./scripts/vrt/getVrtBranchName` VRT_BASELINEBRANCHNAME=`node ./scripts/vrt/getName` CYPRESS_API_URL=http://sry.rett.c1.vanguard.com:1234/ cypress-cloud --env tags=ci --parallel --record --key XYZ --ci-build-id \"$bamboo_planRepository_branchName ($bamboo_buildNumber)\"",
    "cypress:run:serve": "cross-env NODE_ENV=local npm run cypress:set-env -- run-p -r start-local cypress:run",
    "cypress:run:parallel:serve": "cross-env NODE_ENV=local npm run cypress:set-env -- run-p -r start-local cypress:run:parallel",
    "cypress:run-sell-integration": "cypress run --spec 'cypress/integration/sell-order/*,cypress/integration/buy-order/*'",
    "cypress:run-remaining-integration": "cypress run --spec 'cypress/integration/change-order/*,cypress/integration/accessibility-verification/*,cypress/integration/margin-verification/**,cypress/integration/clear-ticket/*,cypress/integration/dollar-based-investing/*,cypress/integration/edit-ticket/*,cypress/integration/open-order-cards/*,cypress/integration/order-behavior/*,cypress/integration/order-confirmation-details/*,cypress/integration/order-validations/*,cypress/integration/symbol-lookup/*'",
    "cypress:test": "cross-env NODE_ENV=local npm run cypress:set-env -- run-p -r start-local cypress:run",
    "cypress:test-sell-integration": "cross-env NODE_ENV=local npm run cypress:set-env -- run-p -r start-local cypress:run-sell-integration",
    "cypress:test-remaining-integration": "cross-env NODE_ENV=local npm run cypress:set-env -- run-p -r start-local cypress:run-remaining-integration",
    "debug:e2e": "npm run node:debug:e2e",
    "default": "npm run lint:all && npm run test:all && npm run build:all",
    "format:check": "prettier -c \"./**/*.{css,html,js,json,md,scss,ts}\"",
    "format:file": "prettier -w",
    "lint": "npm run write:rev && npm run lint:all",
    "lint:all": "npm run lint:app && npm run && npm run lint:server && npm run lint:cypress && npm run format:check",
    "lint:app": "eslint src",
    "lint:file": "eslint --fix",
    "lint:server": "eslint server",
    "lint:cypress": "eslint cypress",
    "move-protractor-files": "node tasks/move-protractor-files",
    "ng:build:server": "ng run trade-web-angular:server",
    "ng:e2e": "cross-env-shell ng e2e --port=$PORT",
    "ng:serve": "cross-env-shell ng serve --build-target=trade-web-angular:serverless:local --configuration=local --open --host=localhost.vanguard.com --ssl=true",
    "ng:test": "ng test trade-web-angular",
    "node:debug:e2e": "node --inspect-brk ./node_modules/@angular/cli/bin/ng e2e --port=9001",
    "pipeline:build": "npm run build:all",
    "pipeline:test:coverage": "npm run write:rev && npm run test:coverage && npm run test:api:coverage",
    "pipeline:test:e2e": "npm run test:e2e",
    "pipeline:test:unit": "npm run test:unit",
    "protractor-retry-report-cleanup": "node tasks/protractor-retry-report-cleanup",
    "serve": "npm run serve:dev",
    "serve:dev": "cross-env ANGULAR_ENV=local npm run set:proxy-port -- run-p ts-node:server ng:serve",
    "serve:local": "cross-env-shell IGNORE_GATEKEEPER=true npm run serve:dev",
    "serve:prod": "npm run build && cross-env-shell PORT=$npm_package_config_port npm run start",
    "set:mock-server-port": "cross-env MOCK_SERVER_PORT=$(node tasks/find-open-port)",
    "set:port": "cross-env PORT=$(node tasks/find-open-port)",
    "set:proxy-port": "cross-env PROXY_PORT=$(node tasks/find-open-port)",
    "start": "node dist/server",
    "start-local": "cross-env-shell ANGULAR_ENV=local node dist/server",
    "test": "npm run write:rev && npm run test:unit",
    "test:all": "npm run test:coverage && npm run test:e2e && npm run test:api:coverage",
    "test:api": "npm run write:rev && ts-node -O '{\"module\": \"commonjs\", \"types\": [\"jasmine\", \"node\"], \"target\": \"es2015\"}' node_modules/jasmine/bin/jasmine --config=jasmine.json",
    "test:api-integration": "ts-node -O '{\"module\": \"commonjs\", \"target\": \"es2015\"}' node_modules/jasmine/bin/jasmine --config=./server/tests/integration/jasmine-integration.json",
    "test:api-integration:coverage": "wait-on tcp:9999 && nyc -r lcov -r text -e .ts -x \"server/tests/integration/**/*.spec.ts\" npm run test:api-integration",
    "test:api:coverage": "nyc -r lcov -r text -e .ts -x \"server/**/*.spec.ts\" npm run test:api",
    "test:api:integration-mock-server": "npm run set:mock-server-port -- tsnd -O '{\"module\": \"commonjs\", \"target\": \"es2015\"}' ./server/tests/mock-api-server/mock-api-server.ts",
    "test:api-e2e": "cross-env-shell NODE_ENV=local-mock npm run set:proxy-port -- run-p -r start-local test:api:integration-mock-server test:api-integration:coverage",
    "test:coverage": "npm run write:rev && npm run set:port -- npm run ng:test -- --watch=false --source-map --code-coverage",
    "test:e2e-ui:serve": "run-s build:pipeline cypress:cleanup cypress:run:serve cypress:report",
    "test:e2e-ui:parallel:serve": "run-s build:pipeline cypress:cleanup cypress:run:parallel:serve cypress:report",
    "test:e2e": "npm run move-protractor-files && npm run set:proxy-port -- npm run set:port -- npm run ng:e2e && npm run protractor-retry-report-cleanup",
    "test:ui-api-e2e": "run-s test:ui-e2e test:api-e2e",
    "test:ui-e2e": "run-s build:pipeline cypress:cleanup cypress:test cypress:report",
    "test:ui-e2e:test-sell-integration": "run-s build:pipeline cypress:cleanup cypress:test-sell-integration cypress:report",
    "test:ui-e2e:test-remaining-integration": "run-s build:pipeline cypress:cleanup cypress:test-remaining-integration cypress:report",
    "test:unit": "npm run set:port -- npm run ng:test",
    "ts-node:server": "tsnd --inspect -O '{\"module\": \"commonjs\", \"target\": \"es2015\"}' ./server/server",
    "webpack:server": "webpack --config webpack.server.config.js --progress",
    "hashsplitter": "tsnd --inspect --no-warnings -O '{\"module\": \"commonjs\", \"target\": \"es2015\"}' tasks/hash-splitter",
    "prepare": "husky install",
    "write:rev": "node writeRevFile"
  },
  "deployment": {
    "itGpsId": 9899,
    "command": "node dist/server",
    "name": "trade-web",
    "diskQuota": "1024M",
    "protectedresources": "/",
    "type": "external",
    "serviceNowAppId": "TWE-TRADE SERVICE WEB",
    "appPrefix": "TWE",
    "memory": "1024M",
    "organization": "RET",
    "privateCloud": "false",
    "privateCloudDct": "false",
    "publicCloud": "true",
    "instances": 20,
    "properties": {
      "development-us-east-1": {
        "env": {
          "NODE_ENV": "development",
          "LOGGER_IMPL": "vg-logger-console",
          "NODE_OPTIONS": "--max-http-header-size=81920",
          "GATEKEEPER_APP_PREFIX": "TWE",
          "GATEKEEPER_ENVIRONMENT_TAG": "DEV",
          "ANGULAR_ENV": "non-prod"
        },
        "vault": {
          "TWE_APP_ID": "IDVAULT;type=USERNAME;appId=TWE_NONPRD;query=safe:ADS_TWE_NONPRD,folder:Root,object:Operating System-WinDomain_RD-vanguard.com-ATWEQ;IDVAULTEND",
          "TWE_APP_PASSWORD": "IDVAULT;type=PASSWORD;appId=TWE_NONPRD;query=safe:ADS_TWE_NONPRD,folder:Root,object:Operating System-WinDomain_RD-vanguard.com-ATWEQ;IDVAULTEND",
          "TWE_OAUTH_CLIENT_ID": "IDVAULT;type=USERNAME;appId=TWE_NONPRD;query=safe:REP_TWE_NONPRD,folder:Root,object:Website-Repository_Only-NA-PB0nG0VjPSrBMnAzbOrs0IA5ZNstkE9H;IDVAULTEND",
          "TWE_OAUTH_CLIENT_SECRET": "IDVAULT;type=PASSWORD;appId=TWE_NONPRD;query=safe:REP_TWE_NONPRD,folder:Root,object:Website-Repository_Only-NA-PB0nG0VjPSrBMnAzbOrs0IA5ZNstkE9H;IDVAULTEND",
          "TWE_HASH_SECRET": "IDVAULT;type=PASSWORD;appId=TWE_NONPRD;query=safe:REP_TWE_NONPRD,folder:Root,object:Website-Repository_Only-na-twe_hash_salt;IDVAULTEND"
        },
        "internal-env": {
          "SRV_TYP": "internal"
        },
        "external-env": {
          "SRV_TYP": "external"
        }
      },
      "test-us-east-1": {
        "env": {
          "NODE_ENV": "development",
          "LOGGER_IMPL": "vg-logger-console",
          "NODE_OPTIONS": "--max-http-header-size=81920",
          "GATEKEEPER_APP_PREFIX": "TWE",
          "GATEKEEPER_ENVIRONMENT_TAG": "SAT",
          "GATEKEEPER_CREWFINDER_ENVIRONMENT": "SAT",
          "ANGULAR_ENV": "non-prod"
        },
        "vault": {
          "TWE_APP_ID": "IDVAULT;type=USERNAME;appId=TWE_NONPRD;query=safe:ADS_TWE_NONPRD,folder:Root,object:Operating System-WinDomain_RD-vanguard.com-ATWEQ;IDVAULTEND",
          "TWE_APP_PASSWORD": "IDVAULT;type=PASSWORD;appId=TWE_NONPRD;query=safe:ADS_TWE_NONPRD,folder:Root,object:Operating System-WinDomain_RD-vanguard.com-ATWEQ;IDVAULTEND",
          "TWE_OAUTH_CLIENT_ID": "IDVAULT;type=USERNAME;appId=TWE_NONPRD;query=safe:REP_TWE_NONPRD,folder:Root,object:Website-Repository_Only-NA-PB0nG0VjPSrBMnAzbOrs0IA5ZNstkE9H;IDVAULTEND",
          "TWE_OAUTH_CLIENT_SECRET": "IDVAULT;type=PASSWORD;appId=TWE_NONPRD;query=safe:REP_TWE_NONPRD,folder:Root,object:Website-Repository_Only-NA-PB0nG0VjPSrBMnAzbOrs0IA5ZNstkE9H;IDVAULTEND",
          "TWE_HASH_SECRET": "IDVAULT;type=PASSWORD;appId=TWE_NONPRD;query=safe:REP_TWE_NONPRD,folder:Root,object:Website-Repository_Only-na-twe_hash_salt;IDVAULTEND"
        },
        "internal-env": {
          "SRV_TYP": "internal"
        },
        "external-env": {
          "SRV_TYP": "external"
        }
      },
      "production-us-east-1": {
        "env": {
          "NODE_ENV": "production",
          "LOGGER_IMPL": "vg-logger-console",
          "NODE_OPTIONS": "--max-http-header-size=81920",
          "GATEKEEPER_APP_PREFIX": "TWE",
          "GATEKEEPER_ENVIRONMENT_TAG": "PROD",
          "GATEKEEPER_CREWFINDER_ENVIRONMENT": "PROD",
          "ANGULAR_ENV": "prod"
        },
        "vault": {
          "TWE_APP_ID": "IDVAULT;type=USERNAME;appId=TWE_PRD;query=safe:ADS_TWE_PRD,folder:Root,object:Operating System-WinDomain_RD-vanguard.com-ATWEP;IDVAULTEND",
          "TWE_APP_PASSWORD": "IDVAULT;type=PASSWORD;appId=TWE_PRD;query=safe:ADS_TWE_PRD,folder:Root,object:Operating System-WinDomain_RD-vanguard.com-ATWEP;IDVAULTEND",
          "TWE_OAUTH_CLIENT_ID": "IDVAULT;type=USERNAME;appId=TWE_PRD;query=safe:REP_TWE_PRD,folder:Root,object:Website-Repository_Only-NA-E7qwZyRrxncGqcV4bcwhIgdaIcd4aZd6;IDVAULTEND",
          "TWE_OAUTH_CLIENT_SECRET": "IDVAULT;type=PASSWORD;appId=TWE_PRD;query=safe:REP_TWE_PRD,folder:Root,object:Website-Repository_Only-NA-E7qwZyRrxncGqcV4bcwhIgdaIcd4aZd6;IDVAULTEND",
          "TWE_HASH_SECRET": "IDVAULT;type=PASSWORD;appId=TWE_PRD;query=safe:REP_TWE_PRD,folder:Root,object:Website-Repository_Only-na-twe_hash_salt;IDVAULTEND"
        },
        "internal-env": {
          "SRV_TYP": "internal"
        },
        "external-env": {
          "SRV_TYP": "external"
        }
      }
    },
    "sonar": {
      "profile": "software-improvement-group",
      "language": "ts",
      "sources": "src",
      "tests": "src",
      "testsInclusions": "**/*.spec.ts",
      "exclusions": "**/*.js, **/*.data.ts",
      "lcovReportPath": "reports/coverage/lcov.info"
    }
  },
  "engines": {
    "node": "^18.13.x"
  },
  "dependencies": {
    "@angular-builders/custom-esbuild": "17.1.2",
    "@angular/animations": "^17.3.12",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.12",
    "@angular/compiler": "^17.3.12",
    "@angular/core": "^17.3.12",
    "@angular/forms": "^17.3.12",
    "@angular/platform-browser": "^17.3.12",
    "@angular/platform-browser-dynamic": "^17.3.12",
    "@angular/platform-server": "^17.3.12",
    "@angular/router": "^17.3.12",
    "@apollo/client": "^3.11.4",
    "@aws-sdk/client-secrets-manager": "^3.388.0",
    "@aws-sdk/client-sts": "^3.388.0",
    "@aws-sdk/node-http-handler": "^3.374.0",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/entity": "^17.2.0",
    "@ngrx/router-store": "^17.2.0",
    "@ngrx/schematics": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngrx/store-devtools": "^17.2.0",
    "@opentelemetry/api": "^1.7.0",
    "@opentelemetry/context-async-hooks": "1.25.1",
    "@opentelemetry/context-zone-peer-dep": "^1.21.0",
    "@opentelemetry/instrumentation": "^0.53.0",
    "@opentelemetry/instrumentation-document-load": "^0.34.0",
    "@opentelemetry/instrumentation-fetch": "^0.48.0",
    "@opentelemetry/instrumentation-http": "0.52.0",
    "@opentelemetry/instrumentation-xml-http-request": "^0.48.0",
    "@opentelemetry/resources": "^1.26.0",
    "@opentelemetry/sdk-trace-node": "1.25.1",
    "@opentelemetry/sdk-trace-web": "1.21.0",
    "@types/compression": "^0.0.33",
    "@types/cors": "^2.8.5",
    "@types/cypress-cucumber-preprocessor": "^1.14.1",
    "@types/luxon": "^3.3.8",
    "@vanguard/feature-flag-utils": "2.1.1",
    "@vanguard/gatekeeper-npm": "1.0.0",
    "@vanguard/hash-based-splitter": "^3.0.0",
    "@vanguard/invest-api-client-typescript-axios": "^2.44.0",
    "@vanguard/invest-otel-lib": "^11.1.2",
    "@vanguard/invest-semantic-conventions": "^0.1.0",
    "@vanguard/trade-error-handler-lib": "^1.0.0",
    "@vanguard/trade-nil-utils-lib": "^0.2.4",
    "@vanguard/trade-standard-forms-lib-ng-17": "^6.2.2",
    "@vanguard/trade-ui-components-lib-ng-17": "^45.0.1",
    "@vg-constellation/angular-17": "^1.21.0",
    "@vg-constellation/patterns-angular": "^0.0.14",
    "@vg-constellation/styles": "^1.21.0",
    "@vg-constellation/tokens": "^1.21.0",
    "angular-imask": "~6.6.1",
    "apollo-angular": "^7.1.2",
    "axios": "^1.7.7",
    "axios-retry": "^4.5.0",
    "body-parser": "^1.18.3",
    "bootstrap": "^5.2.3",
    "cfenv": "^1.2.4",
    "cheerio": "^1.0.0-rc.12",
    "compression": "^1.7.4",
    "cookie": "^0.7.0",
    "cookie-parser": "^1.4.4",
    "core-js": "~2.6.2",
    "cors": "^2.8.5",
    "cross-spawn": "^7.0.2",
    "crypto-hash": "^2.0.1",
    "express": "~4.21.1",
    "express-correlation-id": "~1.3.1",
    "express-handlebars": "^6.0.3",
    "express-useragent": "^1.0.15",
    "graphql": "^16.8.2",
    "helmet": "^5.0.2",
    "json-server": "^0.17.2",
    "karma-htmlfile-reporter": "^0.3.8",
    "lodash": "^4.17.11",
    "luxon": "^3.5.0",
    "moment": "^2.29.4",
    "moment-timezone": "^0.5.40",
    "ngx-cookie-service": "^17.1.0",
    "node-cache": "^5.1.2",
    "path": "^0.12.7",
    "postcss": "^8.3.9",
    "reflect-metadata": "~0.1.13",
    "rxjs": "^7.8.0",
    "tslib": "^2.0.0",
    "uuid": "^8.3.2",
    "validator": "^13.9.0",
    "vg-ccc-client": "^2.2.3",
    "vg-ccc-serverless": "^0.0.90",
    "vg-ecs-base-href": "^0.1.7",
    "vg-idle-timer-middleware": "^1.0.0",
    "vg-logger": "^1.0.9",
    "vg-logger-console": "~1.0.8",
    "vg-logger-hvlm": "~1.0.13",
    "vg-poid-scrambler": "^0.1.0",
    "vg-secrets": "^9.0.0",
    "vg-tea-leaf": "^3.3.4",
    "vg-trade-design-system": "^2.0.0",
    "vg-trade-design-tokens": "^2.0.4",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-builders/custom-webpack": "^17.0.2",
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular-eslint/builder": "17.3.0",
    "@angular-eslint/eslint-plugin": "17.5.1",
    "@angular-eslint/eslint-plugin-template": "17.5.1",
    "@angular-eslint/schematics": "^17.3.0",
    "@angular-eslint/template-parser": "17.5.1",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.12",
    "@angular/language-service": "^17.3.12",
    "@cypress/webpack-preprocessor": "^5.17.1",
    "@types/express": "~4.17.1",
    "@types/express-useragent": "^1.0.2",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "~2.0.5",
    "@types/node": "^18.19.44",
    "@types/uuid": "^10.0.0",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "@visual-regression-tracker/agent-cypress": "^5.6.0",
    "axe-core": "^4.7.2",
    "axios-mock-adapter": "^2.0.0",
    "cross-env": "^5.2.1",
    "csv": "^5.3.2",
    "cucumber-html-reporter": "^5.5.1",
    "cypress": "^12.17.2",
    "cypress-axe": "^1.4.0",
    "cypress-cloud": "^1.9.6",
    "cypress-cucumber-preprocessor": "^4.3.1",
    "cypress-fail-fast": "^5.0.1",
    "cypress-fail-on-console-error": "^4.0.2",
    "cypress-failed-log": "^2.9.5",
    "cypress-plugin-tab": "1.0.5",
    "decimal.js": "^10.4.3",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^8.6.0",
    "eslint-plugin-cypress": "^2.15.2",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jasmine": "^4.1.3",
    "eslint-plugin-jsdoc": "^37.9.7",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "eslint-plugin-rxjs": "^5.0.3",
    "eslint-plugin-simple-import-sort": "^10.0.0",
    "eslint-plugin-unused-imports": "^3.2.0",
    "grunt": "~1.0.3",
    "husky": "^8.0.3",
    "jasmine": "^5.1.0",
    "jasmine-core": "^5.1.0",
    "jasmine-reporters": "^2.5.0",
    "jasmine-spec-reporter": "^7.0.0",
    "jasmine-ts": "^0.4.0",
    "jest": "^29.6.1",
    "jira-prepare-commit-msg": "^1.7.2",
    "karma": "^6.4.1",
    "karma-chrome-launcher": "^3.1.1",
    "karma-coverage": "^2.2.0",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^1.7.0",
    "karma-junit-reporter": "^2.0.1",
    "karma-sauce-launcher": "^4.3.6",
    "karma-spec-reporter": "0.0.33",
    "karma-webdriver-launcher": "1.0.4",
    "lint-staged": "^13.1.0",
    "mkdirp": "^0.5.5",
    "multiple-cucumber-html-reporter": "^1.16.3",
    "ng-mocks": "^14.12.2",
    "npm-run-all": "^4.1.5",
    "nyc": "^15.0.1",
    "portscanner": "^2.2.0",
    "prettier": "^2.8.3",
    "protractor": "~7.0.0",
    "rollup": "^4.32.1",
    "tough-cookie": "^4.1.3",
    "ts-loader": "^9.4.2",
    "ts-node": "^10.9.1",
    "ts-node-dev": "^2.0.0",
    "typescript": "5.4.2",
    "vg-accessibility-attest": "~1.1.3",
    "vg-chrome-drivers": "~1.0.3",
    "vg-protractor-retry": "0.1.0",
    "wait-on": "^5.0.0",
    "wd": "1.11.2",
    "webpack-cli": "^4.9.0",
    "webpack-node-externals": "~1.7.2",
    "xlsx": "^0.16.1"
  },
  "overrides": {
    "tough-cookie": "^4.1.3",
    "json5": "^2.2.3"
  }
}
