<ng-container
  *ngIf="{
    env: (env$ | async),
    isChangeOrder: (isChangeOrder$ | async),
    accountIsMargin: (accountIsMargin$ | async),
    isOneTrustEnabled: (isOneTrustEnabled$ | async),
    isOnlyManagedAccounts: (isOnlyManagedAccounts$ | async)
  } as view"
>
  <vg-vgn-nav
    [app-id]="appPrefix"
    [no-nav]="showVgnHeaderAndFooter ? '' : vgnNavVarians.UTILITIES_ONLY"
    *ngIf="!isBeacon"
  >
    <div class="twe-content-wrapper">
      <ng-container *ngTemplateOutlet="tweNavAndMain"></ng-container>
      <!-- TWE custom footer -->
      <div *ngIf="!showVgnHeaderAndFooter">
        <ng-container *ngTemplateOutlet="tweFooterNavigation"></ng-container>
      </div>
    </div>
  </vg-vgn-nav>

  <!-- If beacon we don't use the c3 vgn navigation -->
  <ng-container *ngIf="isBeacon">
    <div class="twe-content-wrapper beacon">
      <ng-container *ngTemplateOutlet="tweNavAndMain"></ng-container>
      <div>
        <ng-container *ngTemplateOutlet="tweFooterNavigation"></ng-container>
      </div>
    </div>
  </ng-container>

  <ng-template #tweNavAndMain>
    <div>
      <twe-header
        *ngIf="!showVgnHeaderAndFooter || (showVgnHeaderAndFooter && showNav)"
        [tweNavIsVisible]="
          (!isIncapacitated && !view.isOnlyManagedAccounts && showNav && !isCriticalError && !view.isChangeOrder) ||
          isConfirmationPage
        "
        [exitButtonHref]="secureSiteUrls.dashboard"
        [pageTitle]="pageTitle"
        [showNavLinks]="showContactUsTradeHelp && !isCriticalError"
        [showHeader]="showHeader"
      >
      </twe-header>
    </div>
    <twe-alert-info
      class="container-lg container-xl container-xxl position-relative container-fluid"
      [heading]="content.crewNoteHead"
      [bodyText]="[content.crewNoteBody1, content.crewNoteBody2, content.crewNoteBody3]"
      *ngIf="isCrewDisclaimerVisible && tweNavIsVisible"
    ></twe-alert-info>

    <main class="twe-main" [class.mt-0]="view.isChangeOrder && !isConfirmationPage">
      <div *ngIf="loadingService.isLoading()" class="loading-container">
        <c11n-spinner size="large" colorMode="on-dark" [spinnerStatus]="loadingService.spinnerStatus()"></c11n-spinner>
      </div>

      <router-outlet *ngIf="!view.isOnlyManagedAccounts"></router-outlet>

      <twe-service-unavailable-msg *ngIf="view.isOnlyManagedAccounts" iconType="warning">
        <p>{{ content.managedAccountsShingle.bodyPart1 }}</p>

        <p>
          {{ content.managedAccountsShingle.bodyPart2 }}
          <a c11n-link variant="primary-reinforced" href="{{ content.contactUsModal.phoneNumberGeneralLink }}">
            {{ content.contactUsModal.phoneNumberGeneral }}
          </a>
          or
          <a c11n-link variant="primary-reinforced" href="{{ appSchedulerUrl }}">
            {{ content.managedAccountsShingle.scheduleAppointment }} </a
          >.
        </p>
      </twe-service-unavailable-msg>
    </main>
  </ng-template>

  <ng-template #tweFooterNavigation>
    <div class="container-fluid overflow-hidden" role="navigation" aria-label="pre-footer-navigation">
      <ul class="twe-inline-list navbar-footer-style">
        <li *ngIf="showContactUsTradeHelp" class="d-sm-inline-block d-lg-none">
          <button c11n-link (click)="tradeHelpModal.open()">
            {{ content.helpMeTrade }}
          </button>
        </li>
        <li *ngIf="showContactUsTradeHelp" class="d-sm-inline-block d-lg-none">
          <button c11n-link (click)="contactUsModal.modal.openModalDialog()">
            {{ content.contactUs }}
          </button>
        </li>
        <li *ngIf="view.isOneTrustEnabled && isBeacon">
          <!-- OneTrust Cookies Settings footer link start -->
          <button c11n-link class="ot-sdk-show-settings" (click)="triggerCookieSetting()">
            {{ content.manageCookies }}
          </button>
          <!-- OneTrust Cookies Settings footer link end -->
        </li>
        <li *ngIf="!isIncapacitated && !view.isOnlyManagedAccounts">
          <button c11n-link (click)="disclosureModal.modal.openModalDialog()">
            {{ content.disclosureModal.title }}
          </button>
        </li>
        <li *ngIf="!isIncapacitated && !view.isOnlyManagedAccounts">
          <button c11n-link (click)="quoteDisclaimerModal.modal.openModalDialog()">
            {{ content.quoteDisclaimerLink }}
          </button>
        </li>
        <li *ngIf="!isIncapacitated && view.accountIsMargin && !view.isOnlyManagedAccounts">
          <a c11n-link href="https://personal.vanguard.com/pdf/margin.pdf" target="_blank">
            {{ content.marginFooterLinkText }}
          </a>
        </li>
      </ul>
    </div>
  </ng-template>

  <c11n-modal-dialog-entrypoint></c11n-modal-dialog-entrypoint>

  <twe-adobe-analytics></twe-adobe-analytics>

  <!-- Icons -->
  <tcx-c11n-icons-container></tcx-c11n-icons-container>

  <!-- Modals -->
  <twe-exit-dialog *ngIf="showNav && showHeader"></twe-exit-dialog>
  <span hidden id="opens-new-window">{{ content.openNewWindow }}</span>

  <twe-disclosure-modal #disclosureModal></twe-disclosure-modal>

  <twe-quote-disclaimer-modal #quoteDisclaimerModal></twe-quote-disclaimer-modal>

  <twe-contact-us-modal #contactUsModal></twe-contact-us-modal>

  <twe-trade-help-modal #tradeHelpModal></twe-trade-help-modal>

  <!-- Load Medallia script -->
  <span appLoadScript [srcUrl]="medalliaUrl" [timeout]="500"></span>
  <!-- Load Prospectus Audit Script -->
  <span appLoadScript [srcUrl]="prospectusAuditUrl"></span>
</ng-container>
