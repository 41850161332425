@import '@vg-constellation/angular-17/styles/breakpoints';
@import '@vg-constellation/angular-17/styles/typography';
@import '@vg-constellation/angular-17/styles/variables';

.what-happens-next-heading,
.open-orders-header h2 {
  @include c11n-font-rules('bold', 'lg');

  @include media-breakpoint-up(lg) {
    @include c11n-font-rules('bold', 'xl');
  }
}

.what-happens-next {
  display: flex;
  margin-bottom: $c11n-size-space-4x;

  &__gutter {
    margin-right: $c11n-size-space-2x;
  }

  &__icon {
    margin-top: $c11n-size-space-0point5x;
    width: 38px;
    max-width: initial;
  }
}

.page-heading {
  @include c11n-font-rules('regular', 'sm');
  display: flex;
  flex-flow: column;

  @include media-breakpoint-up(lg) {
    @include c11n-font-rules('regular', 'md');
    align-items: baseline;
    flex-flow: row;
    justify-content: space-between;
  }

  &__details {
    text-align: unset;
    margin-top: $c11n-size-space-4x;

    @include media-breakpoint-up(lg) {
      align-self: flex-end;
      margin-top: 0;
      text-align: right;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.print-icon-fill {
  filter: invert(20%) sepia(61%) saturate(6731%) hue-rotate(193deg) brightness(103%) contrast(101%);
  height: 24px;
  width: 22px;
  margin-left: $c11n-size-space-0point5x;
}

.order-confirm {
  @include c11n-font-rules('regular', 'lg');
}

.print-vanguard-logo {
  &__hr {
    border: $brand-color solid $c11n-size-border-width-thick;
  }
}

.received-order-header {
  align-items: center;
  gap: $c11n-size-space-1x;

  & c11n-icon {
    color: $c11n-color-success-400;
  }
}
