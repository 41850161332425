"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VgaOrdersResponseV2AccountTypeEnum = exports.VgaOrdersResponseV2OrderCompletionStatusEnum = exports.VgaOrdersResponseV2OrderStatusDetailEnum = exports.VgaOrdersResponseV2OrderStatusEnum = exports.VgaOrdersResponseV2TransactionTypeEnum = exports.VgaOrdersResponseV2OrderActionIndicatorEnum = exports.VgaOrdersResponseV2OrderDurationEnum = exports.VgaOrdersResponseV2RequestedAmountTypeEnum = exports.VgaOrdersResponseV2OrderTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var VgaOrdersResponseV2OrderTypeEnum;
(function (VgaOrdersResponseV2OrderTypeEnum) {
  VgaOrdersResponseV2OrderTypeEnum["MARKET"] = "MARKET";
  VgaOrdersResponseV2OrderTypeEnum["LIMIT"] = "LIMIT";
  VgaOrdersResponseV2OrderTypeEnum["STOP"] = "STOP";
  VgaOrdersResponseV2OrderTypeEnum["STOP_LIMIT"] = "STOP_LIMIT";
  VgaOrdersResponseV2OrderTypeEnum["MARKET_ON_CLOSE"] = "MARKET_ON_CLOSE";
  VgaOrdersResponseV2OrderTypeEnum["LIMIT_ON_CLOSE"] = "LIMIT_ON_CLOSE";
})(VgaOrdersResponseV2OrderTypeEnum = exports.VgaOrdersResponseV2OrderTypeEnum || (exports.VgaOrdersResponseV2OrderTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var VgaOrdersResponseV2RequestedAmountTypeEnum;
(function (VgaOrdersResponseV2RequestedAmountTypeEnum) {
  VgaOrdersResponseV2RequestedAmountTypeEnum["DOLLARS"] = "DOLLARS";
  VgaOrdersResponseV2RequestedAmountTypeEnum["SHARES"] = "SHARES";
  VgaOrdersResponseV2RequestedAmountTypeEnum["PERCENTAGE"] = "PERCENTAGE";
})(VgaOrdersResponseV2RequestedAmountTypeEnum = exports.VgaOrdersResponseV2RequestedAmountTypeEnum || (exports.VgaOrdersResponseV2RequestedAmountTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var VgaOrdersResponseV2OrderDurationEnum;
(function (VgaOrdersResponseV2OrderDurationEnum) {
  VgaOrdersResponseV2OrderDurationEnum["DAY"] = "DAY";
  VgaOrdersResponseV2OrderDurationEnum["EVENING"] = "EVENING";
  VgaOrdersResponseV2OrderDurationEnum["GOOD_TILL_CANCEL"] = "GOOD_TILL_CANCEL";
  VgaOrdersResponseV2OrderDurationEnum["GOOD_TILL_DATE"] = "GOOD_TILL_DATE";
})(VgaOrdersResponseV2OrderDurationEnum = exports.VgaOrdersResponseV2OrderDurationEnum || (exports.VgaOrdersResponseV2OrderDurationEnum = {}));
/**
    * @export
    * @enum {string}
    */
var VgaOrdersResponseV2OrderActionIndicatorEnum;
(function (VgaOrdersResponseV2OrderActionIndicatorEnum) {
  VgaOrdersResponseV2OrderActionIndicatorEnum["REGULAR_ORDER"] = "REGULAR_ORDER";
  VgaOrdersResponseV2OrderActionIndicatorEnum["AMEND_ORDER"] = "AMEND_ORDER";
  VgaOrdersResponseV2OrderActionIndicatorEnum["OPEN_END_MUTUAL_FUND_ORDER"] = "OPEN_END_MUTUAL_FUND_ORDER";
})(VgaOrdersResponseV2OrderActionIndicatorEnum = exports.VgaOrdersResponseV2OrderActionIndicatorEnum || (exports.VgaOrdersResponseV2OrderActionIndicatorEnum = {}));
/**
    * @export
    * @enum {string}
    */
var VgaOrdersResponseV2TransactionTypeEnum;
(function (VgaOrdersResponseV2TransactionTypeEnum) {
  VgaOrdersResponseV2TransactionTypeEnum["BUY"] = "BUY";
  VgaOrdersResponseV2TransactionTypeEnum["SELL"] = "SELL";
  VgaOrdersResponseV2TransactionTypeEnum["EXCHANGE"] = "EXCHANGE";
  VgaOrdersResponseV2TransactionTypeEnum["BUY_TO_COVER"] = "BUY_TO_COVER";
  VgaOrdersResponseV2TransactionTypeEnum["SELL_SHORT"] = "SELL_SHORT";
  VgaOrdersResponseV2TransactionTypeEnum["SELL_SHORT_LOCATE_EXEMPT"] = "SELL_SHORT_LOCATE_EXEMPT";
})(VgaOrdersResponseV2TransactionTypeEnum = exports.VgaOrdersResponseV2TransactionTypeEnum || (exports.VgaOrdersResponseV2TransactionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var VgaOrdersResponseV2OrderStatusEnum;
(function (VgaOrdersResponseV2OrderStatusEnum) {
  VgaOrdersResponseV2OrderStatusEnum["ENTERED"] = "ENTERED";
  VgaOrdersResponseV2OrderStatusEnum["OPEN"] = "OPEN";
  VgaOrdersResponseV2OrderStatusEnum["PENDING"] = "PENDING";
  VgaOrdersResponseV2OrderStatusEnum["COMPLETE"] = "COMPLETE";
  VgaOrdersResponseV2OrderStatusEnum["REJECTED"] = "REJECTED";
  VgaOrdersResponseV2OrderStatusEnum["EXPIRED"] = "EXPIRED";
})(VgaOrdersResponseV2OrderStatusEnum = exports.VgaOrdersResponseV2OrderStatusEnum || (exports.VgaOrdersResponseV2OrderStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
var VgaOrdersResponseV2OrderStatusDetailEnum;
(function (VgaOrdersResponseV2OrderStatusDetailEnum) {
  VgaOrdersResponseV2OrderStatusDetailEnum["OPEN"] = "OPEN";
  VgaOrdersResponseV2OrderStatusDetailEnum["IN_PROGRESS"] = "IN_PROGRESS";
  VgaOrdersResponseV2OrderStatusDetailEnum["ENTERED"] = "ENTERED";
  VgaOrdersResponseV2OrderStatusDetailEnum["EXPIRED"] = "EXPIRED";
  VgaOrdersResponseV2OrderStatusDetailEnum["EXECUTED"] = "EXECUTED";
  VgaOrdersResponseV2OrderStatusDetailEnum["CANCELED"] = "CANCELED";
  VgaOrdersResponseV2OrderStatusDetailEnum["DROPPED"] = "DROPPED";
  VgaOrdersResponseV2OrderStatusDetailEnum["REJECTED"] = "REJECTED";
  VgaOrdersResponseV2OrderStatusDetailEnum["PARTIAL_CANCEL"] = "PARTIAL_CANCEL";
  VgaOrdersResponseV2OrderStatusDetailEnum["PARTIAL_EXECUTION"] = "PARTIAL_EXECUTION";
  VgaOrdersResponseV2OrderStatusDetailEnum["PENDING_CANCEL"] = "PENDING_CANCEL";
  VgaOrdersResponseV2OrderStatusDetailEnum["PENDING_CHANGE"] = "PENDING_CHANGE";
  VgaOrdersResponseV2OrderStatusDetailEnum["PENDING"] = "PENDING";
  VgaOrdersResponseV2OrderStatusDetailEnum["COMPLETE"] = "COMPLETE";
  VgaOrdersResponseV2OrderStatusDetailEnum["UNKNOWN"] = "UNKNOWN";
})(VgaOrdersResponseV2OrderStatusDetailEnum = exports.VgaOrdersResponseV2OrderStatusDetailEnum || (exports.VgaOrdersResponseV2OrderStatusDetailEnum = {}));
/**
    * @export
    * @enum {string}
    */
var VgaOrdersResponseV2OrderCompletionStatusEnum;
(function (VgaOrdersResponseV2OrderCompletionStatusEnum) {
  VgaOrdersResponseV2OrderCompletionStatusEnum["NOT_COMPLETE"] = "NOT_COMPLETE";
  VgaOrdersResponseV2OrderCompletionStatusEnum["NORMAL_COMPLETION"] = "NORMAL_COMPLETION";
  VgaOrdersResponseV2OrderCompletionStatusEnum["REPLACED"] = "REPLACED";
  VgaOrdersResponseV2OrderCompletionStatusEnum["REJECTED"] = "REJECTED";
  VgaOrdersResponseV2OrderCompletionStatusEnum["REJECTED_BY_REVIEW_RELEASE"] = "REJECTED_BY_REVIEW_RELEASE";
  VgaOrdersResponseV2OrderCompletionStatusEnum["DROPPED"] = "DROPPED";
  VgaOrdersResponseV2OrderCompletionStatusEnum["BUSTED"] = "BUSTED";
  VgaOrdersResponseV2OrderCompletionStatusEnum["REJECTED_BY_BROKER"] = "REJECTED_BY_BROKER";
  VgaOrdersResponseV2OrderCompletionStatusEnum["PENDING_CANCEL"] = "PENDING_CANCEL";
  VgaOrdersResponseV2OrderCompletionStatusEnum["PENDING_CANCEL_REPLACE"] = "PENDING_CANCEL_REPLACE";
  VgaOrdersResponseV2OrderCompletionStatusEnum["REVIEW_RELEASE_UNAVAILABLE"] = "REVIEW_RELEASE_UNAVAILABLE";
  VgaOrdersResponseV2OrderCompletionStatusEnum["REJECTED_BY_SYSTEM"] = "REJECTED_BY_SYSTEM";
})(VgaOrdersResponseV2OrderCompletionStatusEnum = exports.VgaOrdersResponseV2OrderCompletionStatusEnum || (exports.VgaOrdersResponseV2OrderCompletionStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
var VgaOrdersResponseV2AccountTypeEnum;
(function (VgaOrdersResponseV2AccountTypeEnum) {
  VgaOrdersResponseV2AccountTypeEnum["CASH"] = "CASH";
  VgaOrdersResponseV2AccountTypeEnum["MARGIN"] = "MARGIN";
  VgaOrdersResponseV2AccountTypeEnum["SHORT"] = "SHORT";
  VgaOrdersResponseV2AccountTypeEnum["WHEN_ISSUED"] = "WHEN_ISSUED";
})(VgaOrdersResponseV2AccountTypeEnum = exports.VgaOrdersResponseV2AccountTypeEnum || (exports.VgaOrdersResponseV2AccountTypeEnum = {}));
