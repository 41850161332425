import { Component, Input, OnInit } from '@angular/core';
import { UILogCode } from '@app/core/enums/ui-log-code-enum';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { OrderEnums } from '@app/etfs-equities/enums';
import { ProspectusAudit } from '@app/etfs-equities/models';
import { WindowService } from '@app/etfs-equities/services';
import { OrderUtil } from '@app/etfs-equities/utils';
import { LoggerService } from '@core/services';
import { environment } from '@env/environment';
import { APP_PREFIX } from '@env/environment.constants';
import { AngularOpenTelemetryService, SpawningSpan } from '@vanguard/invest-otel-lib/angular';
import { InvestSemanticAttributes } from '@vanguard/invest-semantic-conventions';
import { ProspectusData } from '@vanguard/trade-ui-components-lib-ng-17';

import { Order } from '../../models';

declare global {
  let auditScript: ProspectusAudit;
}

@Component({
  selector: 'twe-prospectus-link',
  templateUrl: './prospectus-link.component.html',
  styleUrls: ['./prospectus-link.component.scss'],
})
export class ProspectusLinkComponent implements OnInit {
  @Input({ required: true }) order: Order.Order;

  readonly content = require('@content/content.json');

  prospectusUrl: string;

  isBeacon: boolean;

  constructor(
    private readonly windowService: WindowService,
    private readonly loggerService: LoggerService,
    private readonly tracerService: AngularOpenTelemetryService
  ) {}

  ngOnInit(): void {
    // get prospectus for buy transaction only
    this.prospectusUrl = `${environment.prospectus.baseUrl}?securityId=${this.order?.cusip}&securityIdType=CUSIP&consumerApplicationCode=${CONSTANTS.APP_PREFIX}`;
    this.isBeacon = this.windowService.getIsBeacon();
  }

  isProspectusEligible(): boolean {
    return (
      this.order?.transactionType === OrderEnums.TransactionTypes.BUY && this.order?.prospectusEligibility === true
    );
  }

  logProspectusData(): void {
    if (!this.order?.accountNumber) {
      this.loggerService.error(
        UILogCode.PROSPECTUS_LINK_ERROR,
        `Sending invalid data to prospectus: accountNumber - ${this.order?.accountNumber}, orderId - ${this.order?.orderId}`
      );
      return;
    }

    this.tracerService.createActive(UILogCode.PROSPECTUS_LINK, (span: SpawningSpan) => {
      span.setAttributes({
        [InvestSemanticAttributes.SERVICE_APP_PREFIX]: APP_PREFIX,
        accountNumber: OrderUtil.mask(this.order.accountNumber),
        orderId: this.order?.orderId,
      });
      span.addEvent('Prospectus Link Clicked');
      span.end();
    });
  }

  getProspectusData(): ProspectusData {
    return {
      brokerageAccountNumber: this.order.accountNumber,
      prospectusUrl: this.prospectusUrl,
      prospectusEligible: this.isProspectusEligible(),
      appPrefix: CONSTANTS.APP_PREFIX,
      productType: 'BROKERAGE',
      securityId: this.order.cusip,
      securityIdType: 'CUSIP',
      prospectusAuditService: {
        auditProspectus: (data, prospectusLinkId, postFormId, appPrefix = CONSTANTS.APP_PREFIX, prospectusLinkUrl) => {
          if (auditScript) {
            auditScript.auditProspectusWithLinkUrl(data, prospectusLinkId, postFormId, appPrefix, prospectusLinkUrl);
          } else {
            this.loggerService.error(UILogCode.PROSPECTUS_LINK_ERROR, 'Prospectus Audit Script is not available.');
          }
        },
      },
      viewProspectus: this.content.labels.viewProspectus,
    };
  }
}
