<ng-container
  *ngIf="{
    selectedAccount: (selectedAccount$ | async),
    etfs: (etfs$ | async),
    stocks: (stocks$ | async),
    options: (options$ | async),
    mutualFunds: (mutualFunds$ | async),
    CDs: (CDs$ | async),
    bonds: (bonds$ | async),
  } as view"
>
  <div class="container-fluid c11n-space-stack-5x c11n-space--force">
    <twe-accounts-control [showAccountDetails]="true"></twe-accounts-control>
    <twe-accounts-error (hasAccountRetrievalErrorEmitter)="setHasAccountRetrievalError($event)"></twe-accounts-error>

    <div *ngIf="view.selectedAccount" class="twe-scrollable">
      <twe-service-unavailable-msg *ngIf="hasCriticalHoldingError">
        <p class="twe-c11n-color-ink-400 c11n-space-stack-1x">
          {{ content.holdings.criticalHoldingError.body }}
        </p>
        <p>
          <a c11n-link [routerLink]="TRADE_PATH">
            {{ content.holdings.criticalHoldingError.linkText }}
          </a>
        </p>
      </twe-service-unavailable-msg>

      <twe-service-unavailable-msg
        *ngIf="!view.selectedAccount?.holdings?.length && !isRefreshingAccounts && !hasCriticalHoldingError"
      >
        <p class="twe-c11n-color-ink-400 c11n-space-stack-1x">{{ content.holdings.noHoldings.noHoldingsText }}</p>
        <p>
          <a c11n-link [routerLink]="TRADE_PATH">{{ content.holdings.noHoldings.backToTrading }}</a>
        </p>
      </twe-service-unavailable-msg>

      <div *ngIf="!hasCriticalHoldingError">
        <!-- Holdings tables -->
        <twe-holdings-table
          *ngIf="view.etfs?.length"
          [title]="content.labels.etfs"
          [holdings]="view.etfs"
        ></twe-holdings-table>
        <twe-holdings-table
          *ngIf="view.stocks?.length"
          [title]="content.labels.stocks"
          [holdings]="view.stocks"
        ></twe-holdings-table>
        <twe-holdings-table
          *ngIf="view.mutualFunds?.length"
          [title]="content.labels.mutualFunds"
          [holdings]="view.mutualFunds"
        ></twe-holdings-table>
        <twe-holdings-table
          *ngIf="view.bonds?.length"
          [title]="content.labels.bonds"
          [holdings]="view.bonds"
        ></twe-holdings-table>
        <twe-holdings-table
          *ngIf="view.options?.length"
          [title]="content.labels.options"
          [holdings]="view.options"
        ></twe-holdings-table>
        <twe-holdings-table
          *ngIf="view.CDs?.length"
          [title]="content.labels.cds"
          [holdings]="view.cds"
        ></twe-holdings-table>
      </div>
    </div>
  </div>
</ng-container>
