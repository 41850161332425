@import '@vg-constellation/angular-17/styles/variables';

#twe-c11n-tab-nav.c11n-navigation {
  &.c11n-navigation--left-aligned {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.hide-active-item.c11n-navigation {
    .c11n-nav-item,
    .c11n-tabs__tab {
      &.active,
      &.c11n-tabs__tab--active {
        background-image: none;

        &:hover {
          background-image: linear-gradient($c11n-color-ink-800, $c11n-color-ink-800);
        }
      }
    }
  }

  .c11n-nav-item,
  .c11n-tabs__tab {
    transition: none !important;
    &:hover {
      transition: none !important;
    }
  }
}
