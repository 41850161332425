import { Component, OnInit } from '@angular/core';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { Account, JsonContent } from '@app/etfs-equities/models';
import { AccountService, WindowService } from '@app/etfs-equities/services';
import {
  createRefreshAccountsAction,
  selectAccountIsMargin,
  selectBondHoldings,
  selectCDHoldings,
  selectEtfHoldings,
  selectMutualFundHoldings,
  selectOptionHoldings,
  selectSelectedAccount,
  selectStockHoldings,
  TayneState,
} from '@app/etfs-equities/store';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { TimestampSize } from '@vanguard/trade-ui-components-lib-ng-17';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-holdings',
  templateUrl: './holdings-page.component.html',
  styleUrls: ['./holdings-page.component.scss'],
})
export class HoldingsPageComponent implements OnInit {
  readonly TRADE_PATH = CONSTANTS.TRADE_PATH;

  selectedAccount$: Observable<Account.Account>;

  accountIsMargin$: Observable<boolean>;

  stocks$: Observable<Account.MappedHolding[]>;

  etfs$: Observable<Account.MappedHolding[]>;

  options$: Observable<Account.MappedHolding[]>;

  mutualFunds$: Observable<Account.MappedHolding[]>;

  CDs$: Observable<Account.MappedHolding[]>;

  bonds$: Observable<Account.MappedHolding[]>;

  content: JsonContent = content;

  hasAccountRetrievalError = false;

  protected readonly timestampSizeEnum = TimestampSize;

  constructor(
    private readonly accountService: AccountService,
    private readonly store: Store<TayneState>,
    public readonly windowService: WindowService
  ) {}

  // Getters/Setters...

  get isRefreshingAccounts() {
    return this.accountService.isRefreshingAccounts;
  }

  get hasCriticalHoldingError() {
    return this.accountService.hasCriticalHoldingError;
  }

  setHasAccountRetrievalError(hasError: boolean) {
    this.hasAccountRetrievalError = hasError;
  }

  ngOnInit() {
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.accountIsMargin$ = this.store.pipe(select(selectAccountIsMargin));
    this.etfs$ = this.store.pipe(select(selectEtfHoldings));
    this.stocks$ = this.store.pipe(select(selectStockHoldings));
    this.options$ = this.store.pipe(select(selectOptionHoldings));
    this.mutualFunds$ = this.store.pipe(select(selectMutualFundHoldings));
    this.CDs$ = this.store.pipe(select(selectCDHoldings));
    this.bonds$ = this.store.pipe(select(selectBondHoldings));
  }

  refreshHoldingsForAccount() {
    this.store.dispatch(createRefreshAccountsAction());
  }
}
