{
  "accounts": {
    "accountsError": {
      "body": "We are unable to display your account information at this time."
    },
    "loading": "Loading accounts...",
    "unavailable": "Accounts unavailable"
  },
  "alertsModal": {
    "title": "Important, please read"
  },
  "altText": {
    "warningCircle": "Warning Circle Icon",
    "info": "Information Icon"
  },
  "amount": {
    "amount": "Amount",
    "amountGroup": "Amount group",
    "amountGroupWithDollarsStatus": "Amount group, Amount is set to Dollars entry",
    "amountGroupWithSharesStatus": "Amount group, Amount is set to Shares entry",
    "dollars": "Dollars",
    "enterDollars": "$",
    "enterDollarsDescription": "Amount, Enter Dollars",
    "enterShares": "Enter Shares",
    "enterSharesDescription": "Amount, Enter Shares",
    "shares": "Shares"
  },
  "announcementBanner": {
    "content": "<strong>We have a new look!</strong><br>You’ll notice that our ETF/stock ticket has a new look, but the functionality you’re accustomed to remains.",
    "closeButtonText": "Close Announcements Banner"
  },
  "backToTrading": "Back to trading",
  "balancesMarginModal": {
    "balancesAltText": "More information about Balances",
    "balancesAriaLabel": "Expand/collapse more information about Balances.",
    "fundsAvailable": "<strong>Funds available to trade</strong> consists of your settlement fund balance and any pending credits or debits, plus available margin cash. This number is adjusted for open orders to purchase stocks or ETFs at the market price or to purchase Vanguard or non-Vanguard mutual funds. <i>Note:</i> Money recently added to your account by check or electronic bank transfer may not be available to purchase securities or to withdraw from the account.",
    "marginBuyingPower": "<strong>Margin buying power</strong> refers to the amount that can be used for purchasing marginable securities (most commonly, your funds available to trade multiplied by two).",
    "marginCall": "If you’re in a <strong>margin call</strong> you need to increase the equity in your account to bring it up to the minimum requirements, based on industry rules for investing on margin.",
    "marginCashAvailable": "<strong>Margin cash available</strong> represents the amount available to be withdrawn from your margin account as a loan or used to purchase securities that aren’t marginable (i.e. they must be paid for in full). The use of margin cash available will increase the debit balance in your account.",
    "settlementFund": "Your <strong>settlement fund</strong> is a cash account that acts as your Vanguard wallet when you buy and sell stocks and other securities. Money to pay for your purchases is taken from this fund and proceeds from sales go directly into it.",
    "title": "Balances"
  },
  "balancesModal": {
    "body": "<p><strong>Debits/credits:</strong> A credit or debit will appear in your brokerage account when a transaction is pending.</p><p>For example, if you’re owed money, proceeds from the sale of a security or receipt of a dividend—you’ll see a credit.</p><p>If you owe money, and you’ve bought securities that haven’t settled yet or an account-level fee is pending—you’ll see a debit.</p><p>Generally, the day after a transaction settles, the credit or debt amount will be zero, as a result of a \"sweep\" into or from your settlement fund. If you have multiple pending transactions, the credit or debit amount reflects the net total of all of those transactions.</p><p><strong>Funds available to withdraw:</strong> This represents the amount of money that’s currently available in your account. It combines your settlement fund balance and your settled credits or debits. This figure is adjusted for any open orders to purchase stocks or ETFs at the market price or to purchase Vanguard or non-Vanguard mutual funds. Money that has been recently added to your account by check and/or electronic bank transfer (EBT) may not be immediately available for withdraw.</p><p><strong>Value of open buy limit orders:</strong> This represents the sum of all buy limit orders that are awaiting execution.</p><p><strong>Value of open buy mutual funds and market orders:</strong> This figure is based on the sum of any open or pending purchase orders.</p>",
    "title": "Balances and holdings information"
  },
  "cancelOrder": {
    "cancelButtonLabel": "Cancel Order",
    "cancelGenericErrorModal": {
      "title": "Cancel request cannot be completed"
    },
    "changeOrderInfo": {
      "linkText": "change order",
      "part1": "To change the quantity, order type, duration, or price for this security, use our",
      "part2": "page."
    },
    "header": "Please review this order before canceling",
    "illustrationOfErrorMessage": "Illustration of error message",
    "note": "Note: Your order could execute or change before the request can be processed.",
    "orderInfo1": "You are about to cancel your order for ",
    "requestCannotBeCompleted": "Your request cannot be completed online at this time.<br>To cancel this order, please contact us at 800-992-8372.",
    "warningSoftStopModal": {
      "cancelBtnText": "Return to Order"
    }
  },
  "cancelSuccess": {
    "cancelOrderReceived": "Your cancel order has been received!",
    "goToHoldingsButtonText": "Go to holdings page",
    "openOrdersLinkText": "\"Open orders\" tab",
    "trackRequest": "To track your request, go to the"
  },
  "changeOrder": {
    "cannotModifyOrder": "This order cannot be changed or canceled because the order status has changed. Please review your order status.",
    "header": "Change Order",
    "iconAlt": "Illustration of error message",
    "noChangesErrorModal": {
      "body": "To request a change, you must revise one or more of your selections.",
      "title": "No changes were made."
    },
    "orderNumber": "Order number",
    "requestCannotBeCompleted": "Your request cannot be completed online at this time. To change this order, please contact us at 877-662-7447.",
    "transaction": "Transaction"
  },
  "changeOrderModal": {
    "linkToModal": "Learn more about Change Order",
    "changeOrderAriaLabel": "Expand/collapse more information about how change orders work",
    "changeOrderBody1": "Orders can be revised provided the order hasn’t already executed. When an order is changed, you will receive a new confirmation number.",
    "changeOrderBody2": "If you’d like to revise the order’s transaction type or the security to be traded, you’ll need to cancel the original order on the ",
    "changeOrderBody3": " page, then place a new order. You are responsible for settling any open order that is not canceled or changed. Placing a new or identical order does not cancel an existing open order for the same security.",
    "title": "Change order details"
  },
  "contactUs": "Contact us",
  "contactUsModal": {
    "callDays": "Monday through Friday",
    "callTimes": "8 a.m. to 8 p.m., Eastern time",
    "contactUsBody": "Use your TTY or dial 711 to be automatically connected to a relay service operator. In order to leverage a relay service, you must initiate a call to Vanguard. 711 dialing access does not work for calls initiated through the internet, such as Video Relay Service, IP Relay or IP Captioned Telephone Service Relay calls.",
    "generalQuestions": "General questions",
    "phoneNumberGeneral": "877-662-7447",
    "phoneNumberGeneralLink": "tel:+18776627447",
    "phoneNumberText": "Phone number",
    "phoneNumberTTY": "800-749-7273",
    "phoneNumberTTYLink": "tel:+18007497273",
    "title": "Contact us",
    "ttyTdd": "TTY/TDD"
  },
  "costBasisChooseMethod": "Choose a cost basis method",
  "costBasisError": "Select a cost basis method.",
  "costBasisLotLoadErrorModal": {
    "body": "To proceed with your trade you may wish to use another cost basis method.  Cost basis selection may be edited at any time prior to settlement.",
    "title": "Updated lot information currently unavailable"
  },
  "costBasisMethodSelectionNotRequired": "<strong>When selling all shares, selecting a cost basis method is unnecessary.</strong> If your order receives a partial execution, your shares will be sold using the FIFO (first in, first out) method.",
  "costBasisMethodUnavailableModal": {
    "body1": "If you already have a preferred cost basis method for this holding it will be sold using that method. ",
    "body2": "If no preferred method has been established, it will be sold using the first in, first out (FIFO) method. FIFO will also be used for clients who have specific identification (SpecID) as their preferred method. ",
    "body3": "If you don’t want to use your preferred method or FIFO, call us immediately after placing this order at 877-662-7447 to ensure your desired method is used.",
    "title": "Cost basis information is currently unavailable",
    "viewDetails": "View Details"
  },
  "costBasisModal": {
    "avgCost": "Avg Cost (Average Cost)",
    "avgCostDescription": "The average cost is calculated by dividing the total amount in dollars invested in a mutual fund position by the number of shares owned (the default for mutual funds unless you elect a different method). Once you sell, transfer, or dispose of covered shares of a fund, you’ll be locked into the average cost method for any remaining shares of the fund held in your account and any additional shares purchased before switching to another method. The average cost method is easy to use because you won’t have to choose which shares to sell, but this method may limit some tax planning strategies because you won’t be able to select a specific set of shares to sell.",
    "costBasisBody1": "The IRS requires financial institutions to maintain cost basis information for \"covered securities\" in mutual fund and brokerage accounts. You may select a cost basis method that is different from your default method when selling, exchanging, or transferring a security. For \"covered securities\", this information is reported to both you and the IRS on your Form 1099-B.",
    "costBasisBody2": "Available methods include:",
    "costBasisMethod": "Cost Basis Method",
    "costBasisNote": "Note: Not sure which cost basis method is right for you? Consult with your tax advisor.",
    "description": "Description",
    "fifo": "FIFO (First in, first out)",
    "fifoDescription": "Shares with the oldest acquisition date will be sold first (the default for stocks unless you elect a different method before the transaction).",
    "hifo": "HIFO (Highest in, first out)",
    "hifoDescription": "Shares with the highest cost basis will be sold first.",
    "link": "https://investor.vanguard.com/investor-resources-education/taxes/cost-basis-first-in-first-out",
    "linkText": "Learn more about cost basis reporting methods",
    "specId": "SpecID (Specific identification)",
    "specIdDescription": "You choose the shares (or lots) to sell, which will determine your capital gain or loss.",
    "title": "Cost basis reporting methods"
  },
  "costBasisPrevAvgCostSale": {
    "linkText": "cost basis",
    "part1": "If you would like to sell shares of your selected holding using a cost basis method other than average cost, use our",
    "part2": "page to change your method."
  },
  "costBasisSpecIdDisclaimer": "SpecID (specific identification) is a cost basis method that’s only available for market orders.",
  "costBasisSelection": {
    "editCostBasis": "Edit, Cost Basis,",
    "editIconAltText": "Edit icon",
    "currentlySelected": " Currently selected",
    "link": "https://investor.vanguard.com/investor-resources-education/taxes/cost-basis",
    "linkMinTaxUrl": "https://investor.vanguard.com/investor-resources-education/taxes/cost-basis-minimum-tax",

    "costBasisSelectionContent": {
      "header": "Choose a cost basis method",
      "linkLearnHow": "Learn how MinTax can potentially reduce your capital gains taxes",
      "opensInANewTab": "opens in a new tab",
      "radioMinTax": "Minimum tax (MinTax)",
      "paragraphMinTax": "Automatically chooses shares with the goal of minimizing tax impact of each transaction.",
      "radioSpecID": "Specific identification (SpecID)",
      "paragraphSpecID": "You choose which shares to sell.",
      "radioFIFO": "First in, first out (FIFO)",
      "paragraphFIFO": "The shares you bought first will be the first ones we sell.",
      "radioHIFO": "Highest in, first out (HIFO)",
      "paragraphHIFO": "The shares you bought at the highest price will be the first shares we sell, regardless of how long you’ve held the security.",
      "radioAvgCost": "Average Cost (AvgCost)",
      "paragraphAvgCost": "Calculates cost basis using an average cost for each share you own so that all shares of a security have the same basis."
    },
    "costBasisSelectionModal": {
      "info": "Cost basis is generally the price you paid for your shares of a security. The cost basis method you choose will help determine your gain or loss on the sale along with its tax implications.",
      "linkLearnMore": "Learn more about cost basis",
      "specIDNotAvailableForDollars": "SpecID (specific identification) is not available for dollar-based trades.",
      "specIDNotAvailableForSellAll": "SpecID is unavailable when selling all shares.",
      "buttonContinue": "Continue",
      "buttonCancel": "Cancel",
      "opensInANewTab": "opens in a new tab",
      "checkboxPreferredCostBasis": "Set as the preferred cost basis method for this holding."
    }
  },
  "costTaxableDefaultMethodUnavailable": {
    "part1": "If your preferred method is not available, call",
    "part2": "to revise cost basis prior to the settlement of the trade."
  },
  "crewNoteBody1": "<strong>Investment Access Persons</strong> must preclear all covered securities trades with Compliance, including all stock and corporate/municipal bond trades, as well as trades of an individual Vanguard ETF that exceed $50,000 USD in a rolling 30-day period. They must also adhere to the short-term trading prohibition. These rules also apply to trades made by their household and family members. Trade preclearance is obtained through My Compliance Office (MCO) and additional details on trading restrictions can be found in the Access Person Code of Conduct on CrewNet.",
  "crewNoteBody2": "<strong>Fund Access Persons</strong> must preclear all covered securities trades with Compliance, including all stock and corporate/municipal bond trades. They must also adhere to the short-term trading prohibition. These rules also apply to trades made by their household and family members. Trade preclearance is obtained through My Compliance Office (MCO) and additional details on trading restrictions can be found in the Access Person Code of Conduct on CrewNet.",
  "crewNoteBody3": "<strong>Advisor Access Persons</strong> do not need to preclear trades. However, they, and their household and family members, must adhere to the short-term trading prohibition. Details on trading restrictions can be found in the Access Person Code of Conduct on CrewNet.",
  "crewNoteHead": "<strong>Note to Vanguard crew, contingent workers, and their household and family members</strong>",
  "executionHint": {
    "eveningOrder": "Orders are active only for this extended-hours trading session and are automatically canceled at the end of the session.",
    "marketOrder": "Market orders execute immediately, so their execution duration can only be set to day."
  },
  "disclosureModal": {
    "body": "Industry regulations require securities to be delivered on the settlement date. Vanguard Brokerage Services reserves the right to reject your order at any time, for any reason, and without prior notice. If you continue this transaction, please monitor <strong>Order status</strong> to determine the final disposition of your order. You are liable for covering all transactions placed in this account. If you choose to exchange funds into your settlement fund to cover this trade, you must complete the exchange no later than the business day before the settlement date. For market orders, you should consider price volatility when determining whether you’ll have sufficient assets to pay for your purchase.",
    "title": "Disclosures"
  },
  "dollarBasedBannerIndicator": {
    "learnMoreLink": "Learn more",
    "tradeInDollarsOrWholeShares": "Trade in dollars or whole shares"
  },
  "dollarBasedCostBasisHint": "SpecID (specific identification) is not available for dollar-based trades.",
  "dollarBasedInvestingLearnMoreModal": {
    "additionalDetails": "Additional details",
    "additionalDetailsList": [
      "Dollar-based orders have a $1 minimum.",
      "Dollar-based trading is exclusive to Vanguard ETFs.",
      "Vanguard ETFs are traded commission-free, like most stock & ETF trades through Vanguard Brokerage Services.",
      "Dollar-based orders are restricted to market orders which execute immediately (or the next market day if you place an order when the market is closed).",
      "SpecID (specific identification) is not available as a cost basis method for dollar-based sell orders."
    ],
    "dollarBasedTrade": "Dollar-based trade for Vanguard ETF with a $150 price",
    "example": "<b>Example:</b> Say you have $100 to invest in a Vanguard ETF with a share price of $150. Buying in dollars allows you to purchase just a part of that ETF share. With your $100 you can buy 0.67 shares instead of having to wait until you can afford a whole share. ",
    "graphicAlt": " graphic - Bar chart graphic. Chart shows example of dollar-based trade where $100 will buy 0.67 of 1 whole share for purchase of Vanguard ETF with $150 share price",
    "nowYouCanBuy": "Now you can buy and sell Vanguard ETFs based on how much you want to invest, not on how many shares you can afford. With as little as $1, you can start investing.",
    "oneHundredDollars": "$100",
    "title": "Vanguard ETF dollar-based investing",
    "wholeShare": "0.67 of 1 whole share"
  },
  "dollarBasedOrderTypeHint": "Dollar-based trades are only available as market orders.",
  "dollarsToSharesModal": {
    "cancelButton": "Cancel",
    "confirmButton": "Use shares amount",
    "disclaimer": "<span class=\"twe-text-bold\">Note:</span> The estimate is based on the security’s last available trade price, and doesn’t include commission. Market fluctuation and availability of the last trade price will impact the final principal amount. For market orders, consider possible changes in price when determining whether you’ll have sufficient assets to pay for your purchase. You’ll receive a final confirmation with transaction details for all executed trades.",
    "hideDisclaimer": "Hide disclaimer",
    "priceAsOfLastTrade": "Price as of last trade",
    "showDisclaimer": "Show disclaimer",
    "textInputDollarAmountAriaLabel": "Enter Dollar Amount, United States currency dollar sign",
    "textInputDollarAmountLabel": "Enter Dollar Amount",
    "title": "Dollars to Shares"
  },
  "dollarsToSharesModalTrigger": "Calculate dollars to shares",
  "durationModal": {
    "gtcDuration": "GTC orders are automatically entered on a \"do not reduce\" (DNR) basis. The limit price won’t be adjusted on a stock’s ex-dividend date. You must maintain records of all open orders. Be sure to review your open GTC orders periodically. If you make any changes to an open GTC order, the 60-day calendar will restart as of the day the change is made unless the order is executed or canceled. If your order is automatically canceled at the end of the 60-day period, you must place the order again if you want to establish the same conditions for your purchase or sale.",
    "securityDuration": "Orders for securities undergoing <strong>corporate actions</strong>—such as, but not limited to, stock splits, stock dividends, spin-offs, and special distributions—may be canceled by Vanguard before the market opens on the day the stock goes \"ex-dividend.\" If you’re uncertain of the status of your order, check your <strong>order status</strong> on vanguard.com or call us.",
    "title": "Order durations"
  },
  "dynamicContentDollarBasedOrders": "*For <strong>dollar-based market orders,</strong> the estimated share amount for your trade may fluctuate as the trade will use the ask price for a buy order and the bid price for a sell order. The dollar amount for the trade is fixed based on the amount you set and will not change, regardless of share price fluctuations. (If you’re trading after market hours, the estimated share amount will be based on the previous day’s closing price, however the security can open at a significantly higher or lower price).",
  "dynamicContentLimitAndStopOrders": "*For <strong>limit and stop limit orders,</strong> the estimated totals use the limit price you set.",
  "dynamicContentMarketOrder": "*For <strong>market orders,</strong> the estimated totals use the ask price for a buy order and the bid price for a sell order. (If you’re trading after market hours, this will be the previous closing price. The stock can open significantly higher or lower).",
  "dynamicContentStopOrders": "*For <strong>stop orders,</strong> the estimated totals use the stop price that you set. Please note, this price only triggers a market order, meaning your order is likely to execute at a different price – in some cases significantly different.",
  "submitOrderDisclaimer": {
    "body1": "Money moves out of your settlement fund one business day after your trade date.",
    "body2": "By clicking “Submit Order”:",
    "dollarBasedTermsLink": "Dollar-Based Trading Terms of Use",
    "dollarBasedTrading1": "You agree to the ",
    "dollarBasedTrading2": ".",
    "prospectusLinkContent": "For ETF purchases only, you consent to electronic access to the prospectus of the security you're purchasing."
  },
  "dynamicPreviewSellAll": "If you’re selling your entire position, any fractional shares liquidate automatically on the settlement date, at no additional cost to you. The proceeds from liquidating fractional shares sweep into your settlement fund one business day after the settlement date. Your fractional shares are sold at the same price as your whole shares.",
  "dynamicPreviewSellFooter": "Trades settle one business day after your order is executed. Unless you are selling short, sale proceeds will typically appear in your settlement fund on the second day following execution.",
  "editCostBasis": {
    "orderDetailsErrorMsg": "Your request cannot be completed online at this time. Please call us at 877-662-7447 for further assistance.<br>For TTY/TDD, call 800-749-7273. We're available Monday through Friday, 8 a.m. to 8 p.m., Eastern time.",
    "title": "Edit cost basis method"
  },
  "errorModal": {
    "title": "Trade cannot be completed"
  },
  "executedPrice": {
    "title": "Executed Price"
  },
  "exitModal": {
    "body": "All the information you've entered will be lost.",
    "continueOrder": "Continue order",
    "exit": "Exit",
    "title": "Are you sure you want to exit?"
  },
  "extendedTrading": {
    "announcementBanner": {
      "easternTime": " Eastern time.",
      "message": "Extended-hours trading: Access to after-market pricing is available today from",
      "to": " to ",
      "tooltipHeader": "Extended-hours trading",
      "tooltipMessage1": "Extended-hours trading offers access to after-market pricing and is available from 4:00 to 5:30 p.m. Eastern time on days when the exchanges are open. This may not apply on days when the exchanges close early or when trading is halted.",
      "tooltipMessage2": "Trading through Vanguard Brokerage's extended-hours session is subject to the terms and conditions and policies set forth by Vanguard Brokerage Services® and is subject to change without notice."
    },
    "title": "Extended-hours Trading",
    "disclosureSubtitle": "Access to after-market pricing is typically available from 4:00 to 5:30 p.m. Eastern time, directly after the regular market closes.",
    "disclosureConsiderationText": "Consider if extended-hours trading aligns with your risk tolerance and investing preferences. Please be aware of the following:",
    "disclosureTitle": "Extended-hours trading disclosure",
    "disclosureRisksText": "<strong>Extended-hours trading includes risks,</strong> including but not limited to: low liquidity, high-price volatility, and wide bid-ask spreads.",
    "disclosureLimitOrdersText": " <strong>Only limit orders are accepted.</strong> Orders that don't execute are automatically canceled at the end of the extended-hours trading session.",
    "disclosureAvailableSecuritiesText": "<strong>Only certain securities will be available</strong> for extended-hours trading.",
    "disclosureButtonDescriptionText": "To continue, you must view and acknowledge this extended-hours trading disclosure:",
    "disclosureButtonLabel": "View disclosure",
    "info": {
      "body1": "Access to after-market pricing is available today from ",
      "body2": " to ",
      "body3": " Eastern time."
    },
    "tooltipBody1": "Extended-hours trading offers access to after-market pricing and is available from 4:00 to 5:30 p.m. Eastern time on days when the exchanges are open. This may not apply on days when the exchanges close early or when trading is halted.",
    "tooltipBody2": "Trading through Vanguard Brokerage's extended-hours session is subject to the terms and conditions and policies set forth by Vanguard Brokerage Services® and is subject to change without notice."
  },
  "extendedHoursDisclosureModal": {
    "acknowledgeDisclosure": "Acknowledge disclosure",
    "alloweableOrderTypes": "<strong>Allowable order types:</strong>  Limit orders only.",
    "afterMarketTrading": "After-market trading",
    "cancel": "Cancel",
    "orderSize": "<strong>Order size:</strong>  Odd lot (less than 100 shares), round lot (multiples of 100), and mixed lot (more than 100 shares, but not a multiple of 100) orders are acceptable.",
    "paragraph1": "Trading through Vanguard Brokerage's extended-hours session is subject to the terms and conditions (“terms”) and policies set forth by Vanguard Brokerage Services® and is subject to change without notice.",
    "paragraph2": "Vanguard Brokerage Services' extended-hours trading offers the ability to trade all National Market System (NMS) equity securities that have not been halted both before and after the regular market session. Vanguard Brokerage generally will not accept extended-hours trading orders over the telephone.",
    "policies": "&#8544. Policies",
    "sessionTimes": "<strong>Session times:</strong>  Session times are 4:00 to 5:30 p.m., Eastern time, every day the exchanges are open (this may not apply when the exchanges close early). For certain trading sessions around the holidays, early exchange closings at 1 p.m., Eastern time, will result in modifications to extended trading times.",
    "title": "Extended-hours trading agreement"
  },
  "extendedHoursExpiredModal": {
    "title": "Extended-hours trading has ended.",
    "body": "You've been redirected to the Trade ETFs or Stocks page. Orders may be placed for the next business day."
  },
  "extendedHoursWarning": {
    "body1": "Extended-hours trading ends in ",
    "body2": "minute(s)."
  },
  "factsetDisclaimerModal": {
    "title": "Factset disclaimer",
    "body": "Market data (\"Data\") is provided by FactSet Research Systems Inc. and its affiliates (\"FactSet\"). The Data is provided \"as is\" and \"as available,\" without warranties of any kind, either express or implied, including without limitation warranties of merchantability, fitness for a particular purpose, accuracy, or timeliness. The Data is provided for informational purposes only and is not intended for trading. To the fullest extent permissible by law, FactSet and its suppliers shall not be liable for use of the Data under any circumstances, including without limitation due to negligence by FactSet."
  },
  "footnote": "*Market conditions, order type, order timing, the availability of last price, and/or other changes may alter the estimated trade details. You’ll receive final confirmation with transaction details for all executed trades.",
  "fundsAvailable": {
    "cardTitle": "Balances",
    "fundsAvailableToTrade": "Funds Available to Trade",
    "hideDetails": "Hide Details",
    "hideDetailsAriaLabel": "Account balances, hide details",
    "loadingFailed": "<p>Your balance information cannot be retrieved at this time. You may continue with your trade or try again later.</p>",
    "marginBuyingPower": "Margin Buying Power",
    "marginBuyingPowerSub": "Includes maximum available to borrow",
    "marginCashAvailable": "Margin Cash Available",
    "nyseSurplus": "Start-of-Day NYSE Surplus",
    "openBuyLimitOrder": "Open Buy Limit Orders",
    "openBuyMarketableOrder": "Value of Open Buy Mutual Funds and Market Orders",
    "openLimitOrder": "Value of Open Buy Limit Orders",
    "settlementFund": "Settlement Fund",
    "showDetails": "Show Details",
    "showDetailsAriaLabel": "Account balances, show details",
    "totalCreditsAndDebits": "Total Credits and Debits",
    "valueOf": "Value of "
  },
  "fractionalSharesInfoModal": {
    "fractionalSharesSelling": "If you're selling your entire position, any fractional shares liquidate automatically on the settlement date, at no additional cost to you. The proceeds from liquidating fractional shares sweep into your settlement fund one business day after the settlement date. Your fractional shares are sold at the same price as your whole shares.",
    "title": "Fractional shares",
    "wholeShares": "Stocks and ETFs trade in whole shares. You may have received fractional shares as part of dividend reinvestment or another corporate action.",
    "wholeSharesDBT": "Non-Vanguard ETFs and stocks trade only in whole shares, however you may have received fractional shares as part of a dividend reinvestment or another corporate action. Additionally, Vanguard ETFs can be traded in dollar amounts, which may result in ownership of fractional shares."
  },
  "genericErrorModal": {
    "body": "We’re not able to process your request right now. Please try again or call us Monday through Friday from 8 a.m. to 8 p.m., Eastern time at 877-662-7447. For individuals with speech or hearing limitations who would like to communicate with Vanguard via TDD, please call 800-749-7273.",
    "title": "Request cannot be completed"
  },
  "guidelet": {
    "allOrNone": {
      "guideletContent": "All or none (AON) is a condition placed on a buy or sell order instructing the broker to fill the order completely or not at all. AON is only available for orders of more than 100 shares. If all shares aren’t available at the same time and at your limit price or better, the order won’t be filled. Your AON request will be cancelled if it isn’t executed within the duration set for the order. Limit orders with an AON qualifier won’t be reflected in the marketplace (i.e. bid or ask price) preventing a partial execution at your limit price or better."
    },
    "amount": {
      "body": "You have the option to trade Vanguard ETFs using either whole shares or a specific dollar amount. Dollar-based trades have a $1 minimum and can only be executed as market orders and FIFO (first in, first out) as your cost basis method. <br/><br/> If you prefer an alternate order type or cost basis method, you must execute your trade using whole shares. Share-based trades require you to enter a specific number of whole shares—for example 100 shares (not 100.5000 shares).",
      "dbtLink": "How do fractional shares work?",
      "title": "Amount"
    },
    "balances": {
      "fundsAvailable": "<strong>Funds available to trade</strong> represents the amount of money in your settlement fund, your pending credits and/or debits, and any available margin cash. This figure is adjusted for any open orders to purchase stocks or ETFs at the market price or to purchase Vanguard or non-Vanguard mutual funds. Money recently added to your account by check or electronic bank transfer (EBT) may not be immediately available to purchase certain securities or to withdraw from the account.",
      "linkToModal": "Learn more about balances and holding terms.",
      "settlementFund": "Your <strong>settlement fund</strong> is used to pay for and receive proceeds from brokerage transactions. Think of it as an investible fund that covers the costs associated with trading.",
      "title": "Balances"
    },
    "costBasis": {
      "body": "Cost basis is the price you paid for your shares, adjusted for reinvested dividends and capital gains distributions as well as any sales charges or transaction fees. This value is used to determine your capital gains and losses on sold shares when it’s time to file your taxes.",
      "linkText": "Learn more about cost basis reporting methods",
      "title": "Cost basis"
    },
    "currentShares": {
      "sharesContent": "This figure represents the number of shares held in the account you’ve selected and may not represent the total number of shares for this security if you hold multiple accounts.",
      "sharesTitle": "Your current shares"
    },
    "duration": {
      "ariaLabel": "Learn more about Duration",
      "dayOrder": "A <strong>day order</strong> is a trade order that expires at the end of the trading session unless executed or canceled. Market orders are always day orders. If a day order is entered after the close of trading, it will be in effect for the next trading day. Orders received near the close of trading at 4 p.m., Eastern time, may be considered too late to enter for the day and expire.",
      "eveningOrder": "<strong>Evening</strong> orders that are placed in after-market sessions (generally, 4:00 to 5:30 p.m. ET) are in force for only the after-market trading session which they were entered.  They are automatically canceled at the end of that trading session.",
      "goodTillCanceled": "A <strong>good-till-canceled order (GTC)</strong> is an order that remains in effect for 60 calendar days after the business day on which the order was placed. If the 60th day falls during a weekend or on a holiday, the order will be canceled on the next business day before the markets open.",
      "linkToModal": "Learn more about order durations",
      "sellShortOrder": "When selling short, order duration can only be set to day.",
      "title": "Duration"
    },
    "editCostBasis": {
      "ariaLabel": "Learn more about Cost Basis",
      "body1": "Cost basis is the price you paid for your shares, adjusted for reinvested dividends and capital gains distributions, as well as any sales charges or transaction fees.",
      "body2": "You can edit the cost basis method during a brief window between the order execution and the settlement of funds. Changes to an order's cost basis method may not post to your account for up to 24 hours.",
      "body3": "Vanguard automatically selects \"first in, first out\" for individual stocks and ETFs.",
      "title": "Edit cost basis method details"
    },
    "estimatedValueInDollars": {
      "dollarsContent": "This figure represents the value of the shares you currently own in U.S. dollars. This estimated value is calculated by multiplying the number of shares by the bid price quoted above. If the current price is unavailable, the estimated value is calculated using the previous day’s closing price.",
      "dollarsTitle": "Estimated value in dollars"
    },
    "marginTradeType": {
      "body": "Holdings in a <strong>cash</strong> sub-type are not currently marginable whereas holdings in a <strong>margin</strong> or <strong>short</strong> sub-type are currently marginable. (A marginable holding can be borrowed against.)",
      "title": "Margin Sub-Type"
    },
    "numberOfShares": {
      "sharesContent": "ETFs and stocks trade in whole shares (e.g., 101, not 101.75). To estimate the number of shares you want to buy or sell based on a dollar amount, click the Calculate dollars to shares link.",
      "sharesContentDBT": "Non-Vanguard ETFs and stocks trade in whole shares (e.g., 101, not 101.75). Investors in Vanguard ETFs have the option of either a dollar-based trade or a share-based trade. <br/><br/> To estimate the number of shares you want to buy or sell based on a dollar amount, select the <i>calculate dollars to shares</i> link.",
      "sharesLink": "Why can’t I enter fractional shares?",
      "sharesLinkDBT": "How do fractional shares work?",
      "sharesTitle": "Number of Shares"
    },
    "orderType": {
      "advanceOrderType": "<strong>Advanced order types:</strong> Stop orders, Stop limit orders",
      "limitOrder": "<strong>Limit order:</strong> A request to buy or sell at a specific price or better. You’ll enter a limit price and choose a duration of 1 day (Day) or up to 60 calendar days (Good-till-canceled order or GTC).",
      "limitOnlyInfo": "Limit is the only allowable order type during extended-hours trading.",
      "linkToModal": "Learn more about order types",
      "marketOrder": "<strong>Market order:</strong> A request to execute the trade immediately and at the best available price."
    },
    "sharesHeldShort": {
      "sharesContent": "This figure represents the number of borrowed shares you’ve sold with the hope of purchasing them later at a lower price.",
      "sharesTitle": "Your current short position"
    },
    "sharesOwnedInCash": {
      "sharesContent": "This figure represents the number of shares held in a cash position in the account you’ve selected. These are not marginable and cannot be borrowed against. This number may not represent the total number of shares for this security if you hold multiple accounts.",
      "sharesTitle": "Your current shares (cash)"
    },
    "sharesOwnedInMargin": {
      "sharesContent": "This figure represents the number of shares held in a margin position in the account you’ve selected. These are marginable shares that can be borrowed against. This number may not represent the total number of shares for this security if you hold multiple accounts.",
      "sharesTitle": "Your current shares (margin)"
    },
    "symbolKeywordLookup": {
      "content": "Enter a security’s ticker symbol to obtain a real-time quote. If you’d prefer to lookup a security using a keyword in the security’s name, click the <strong>Search by Keyword</strong> link, then select the security from the displayed results. To revert back to getting a real-time quote by entering a ticker symbol, simply click the link again and enter the ticker symbol in the field.",
      "header": "Symbol",
      "link": "Learn how Vanguard formats symbols for preferred stocks"
    },
    "transaction": {
      "linkToModal": "Learn more about Transaction Types",
      "buyToCover": "<strong>Buy to cover</strong> refers to a buy order made on a security to close out an existing short position.",
      "link": "To learn more, read the Margin Investing guide",
      "sellShort": "To <strong>sell short</strong> is to sell a security you don’t own with the hope that the security will drop in price and you can purchase it at a lower price at a later date. The difference in the sale price and the purchase price would be either profit or debt for the investor, based on whether the price drops or rises."
    }
  },
  "haltAlert": {
    "content": " This security is subject to a trading halt. Orders will be delayed until trading resumes.",
    "icon": "announcement",
    "notice": "NOTICE!"
  },
  "headings": {
    "holdings": "Holdings",
    "makeAnotherTrade": "Make another trade",
    "previewAndSubmit": "Preview and submit",
    "whatHappensNext": "What happens next",
    "whatsNext": "Next steps"
  },
  "helpMeTrade": "Trade help",
  "holdings": {
    "criticalHoldingError": {
      "body": "Your list of current holdings is unavailable at this time. Try again later.",
      "linkText": "Back to trading"
    },
    "noHoldings": {
      "backToTrading": "Back to trading",
      "noHoldingsText": "At this time you have no holdings for this account."
    },
    "tableHeaders": [
      {
        "text": "Symbol | Name",
        "wide": false
      },
      {
        "text": "Current position",
        "wide": true
      }
    ]
  },
  "holdingsTitle": "Holdings",
  "howToReadAQuoteModal": {
    "body": [
      {
        "briefDescription": "Name of company, exchange, and ticker symbol.",
        "group": "B,D",
        "longDescription": null,
        "sectionName": "Stock name"
      },
      {
        "briefDescription": "The price for which the last matched trade (a seller found a buyer) took place for any given stock.",
        "group": "B,D",
        "longDescription": null,
        "sectionName": "Last trade"
      },
      {
        "briefDescription": "The number of shares in the last trade on the market.",
        "group": "B,D",
        "longDescription": null,
        "sectionName": "Last size"
      },
      {
        "briefDescription": "The market exchange for which the last trade took place.",
        "group": "B,D",
        "longDescription": null,
        "sectionName": "Exchange"
      },
      {
        "briefDescription": "The difference between a stock’s last trade price and the previous day’s closing price.",
        "group": "B,D",
        "longDescription": null,
        "sectionName": "Change"
      },
      {
        "group": "B,D",
        "longDescription": "The first number is the <strong>bid price</strong>, which is the highest price a buyer is willing to pay for a particular stock or ETF. The second number, in units of 100, is the <strong>bid size</strong>, which represents the number of shares investors are willing to purchase at the bid price.<br/><br/>The bid price is always lower than the ask price. The difference between the two is referred to as the spread. When a security is in high demand, the spread narrows and the trade becomes easier to execute.",
        "sectionName": "Bid x Size"
      },
      {
        "briefDescription": "Total number of shares that have traded throughout the day.",
        "group": "B,D",
        "longDescription": null,
        "sectionName": "Volume"
      },
      {
        "group": "B,D",
        "longDescription": "The first number is the <strong>ask price</strong>, which is the lowest price at which a stock or ETF is offered for sale. The second is the <strong>ask size</strong>, which represents the number of shares (expressed in multiples of 100), available at the ask price.<br/><br/>The ask price is always higher than the bid price. The difference between the two is referred to as the spread. When a security is in high demand, the spread narrows and the trade becomes easier to execute.",
        "sectionName": "Ask x Size"
      },
      {
        "briefDescription": "First trade price recorded that day.",
        "group": "D",
        "longDescription": "This price is not identical to the previous day’s closing price.",
        "sectionName": "Open"
      },
      {
        "briefDescription": "The lowest and highest price at which the security traded during the previous day.",
        "group": "D",
        "longDescription": null,
        "sectionName": "Day"
      },
      {
        "briefDescription": "Last traded price recorded the day before.",
        "group": "D",
        "longDescription": "This price is not identical to the following day’s opening price.",
        "sectionName": "Prev. close"
      },
      {
        "briefDescription": "The lowest and highest price a security has traded during the specific time period.",
        "group": "D",
        "longDescription": "Can give you an idea of how much volatility a security has experienced (day range) or fluctuation you might face over the course of a longer period (52-week range).",
        "sectionName": "52-wk"
      },
      {
        "briefDescription": "The day that a dividend will be paid to shareholders.",
        "group": "D",
        "longDescription": null,
        "sectionName": "Div. Date"
      },
      {
        "briefDescription": "The last date a stockholder is entitled to receive a dividend payment.",
        "group": "D",
        "longDescription": "If you purchase before this date, you are entitled to the next dividend payment. If you purchase after this date, you aren’t and the dividend goes to the previous seller.",
        "sectionName": "Ex-div date"
      },
      {
        "briefDescription": "This figure represents the number of shares held in the account you’ve selected and may not represent the total number of shares for this security if you hold multiple accounts.",
        "group": "B,D",
        "longDescription": null,
        "sectionName": "Current shares"
      }
    ],
    "tabs": {
      "basicQuote": "Basic Quote",
      "detailedQuote": "Detailed Quote"
    },
    "title": "How to read a quote"
  },
  "incapacitatedShingle": {
    "body1": "For assistance, call Vanguard Brokerage Services at ",
    "body2": " on business days from 8 a.m. to 8 p.m., Eastern time.",
    "body3": "For individuals with speech or hearing limitations who would like to communicate with Vanguard via TDD, please call ",
    "body4": ".",
    "overviewLink": "Go to account overview",
    "phoneNumberTTY": "800-749-7273",
    "phoneNumberTTYLink": "tel:+18007497273",
    "phoneNumberVBS": "877-662-7447",
    "phoneNumberVBSLink": "tel:+18776627447",
    "title": "You’re not authorized to transact."
  },
  "ipoModal": {
    "body": "An Initial Public Offering (IPO) is a corporation’s first offering of common stock to the public. The following scenarios describe how IPOs and other types of new listings begin trading. <strong>Please be aware that trading may begin later than market open at 9:30 a.m., Eastern time.<strong>",
    "ipoFirstListItem": "While you are not able to participate directly in the IPO, you can place IPO orders with us on the morning the security is scheduled to trade. <strong>Only limit orders are accepted for IPOs before they open for trading.<strong>",
    "ipoFourthListItem": "The initial margin requirements for some IPOs may be higher than the standard requirement, if the IPO is expected to be in high demand.",
    "ipoSecondListItem": "Market orders will not be allowed until sufficient liquidity is available. If sufficient liquidity is not available, market orders and stop orders will not be permitted on the initial day of trading.",
    "ipoThirdListItem": "If a good-till-canceled (GTC) order is placed, but the IPO does not trade on that day, then the GTC order will be dropped at the end of that day. You are responsible for re-entering the canceled order if you still want to make a purchase.",
    "linkText": "Get more information about IPOs",
    "title": "About trading IPOs and new listings"
  },
  "keywordSearch": {
    "fieldHasChanged": "The field above has changed to ",
    "instructions": "Select the security you’d like to use.",
    "loading": "Loading keyword search results",
    "mobileInstructions": "Enter a keyword for your security.",
    "mobileTitle": "Search by keyword",
    "resultsFoundFor": "results found for",
    "resultsFoundForExtended": "10 or more results found for",
    "searchFailed": "Our keyword search is unavailable at this time. To continue, return to get a quote with a symbol or try again later.",
    "title": "Search results",
    "useArrowKeys": "Use the arrow keys to navigate the results list.",
    "emptyStateText": "Enter keyword or symbol"
  },
  "labels": {
    "account": "Account",
    "accountType": "Account type",
    "alert": "Alert",
    "allOrNone": "All or None",
    "amount": "Amount",
    "amountType": "Amount type",
    "asOf": "As of",
    "avgCost": "Avg Cost",
    "avgCostExpanded": "AvgCost (Average cost)",
    "actions": "Actions",
    "bonds": "Bonds",
    "buy": "Buy",
    "buyToCover": "Buy to Cover",
    "buyVanguardFunds": "Buy Vanguard funds",
    "cash": "Cash",
    "cds": "CDs",
    "changeOrder": "Change order",
    "cancelOrder": "Cancel order",
    "clearTicket": "Clear ticket",
    "commission": "Commission",
    "continue": "Continue",
    "costBasis": "Cost Basis",
    "day": "Day",
    "dollars": "Dollars",
    "duration": "Duration",
    "edit": "Edit",
    "editOrder": "Edit order",
    "estimatedAmount": "Estimated amount*",
    "estimatedNetAmount": "Estimated net amount",
    "estimatedNetAmountAbbr": "Est. net amount",
    "estimatedPrincipal": "Estimated principal",
    "estimatedPrincipalAbbr": "Est. principal",
    "estimatedSharesCustom": "Estimated Shares",
    "estimatedShares": "Estimated shares*",
    "estimatedValueInDollars": "Estimated value in dollars",
    "etfEquitiesPageTitle": "Trade ETFs and stocks",
    "etfs": "ETFs",
    "evening": "Evening",
    "exchangeVanguardFunds": "Exchange Vanguard funds",
    "executed": "Executed",
    "executedAmount": "Executed amount",
    "exit": "Exit",
    "expires": "Expires",
    "fifo": "FIFO",
    "fifoExpanded": "FIFO (First in, first out)",
    "getQuote": "Get Quote",
    "goBack": "Go back",
    "goToOrderStatus": "Go to Order Status",
    "hifo": "HIFO",
    "hifoExpanded": "HIFO (Highest in, first out)",
    "important": "Important!",
    "ipoLink": "More information about trading IPOs and new listings",
    "keepOrder": "Keep order",
    "keyword": "Keyword",
    "limit": "Limit",
    "limitPrice": "Limit price",
    "margin": "Margin",
    "marginTradeType": "Margin Sub-Type",
    "market": "Market",
    "minTaxExpanded": "Minimum tax (MinTax)",
    "mutualFunds": "Mutual Funds",
    "noResultFound": "No results found.",
    "numberOfShares": "Number of Shares",
    "ok": "OK",
    "open": "Open",
    "optional": "Optional",
    "options": "Options",
    "order": "Order",
    "orderDetails": "Order Details",
    "orderNumber": "Order #",
    "orderType": "Order type",
    "orderStatus": "Order status",
    "orderSummary": "Order summary",
    "placeAnotherTrade": "Place another trade",
    "previewAndSubmit": "Preview and submit",
    "previewChangeOrder": "Preview changes",
    "previewOrder": "Preview order",
    "price": "Price",
    "print": "Print",
    "productName": "Product Name",
    "prospectusLink": "Prospectus Link",
    "receivedOrder": "Received order #",
    "searchKeyword": "Search by Keyword",
    "selectAccount": "Select Account",
    "selectAccountForHoldings": "To view your holdings, first select which account to use for this trade.",
    "selectShares": "Select Shares",
    "sell": "Sell",
    "sellAllShares": "Sell all shares",
    "sellAllSharesCleared": "Amount has been cleared and changed to share entry",
    "sellAllSharesHeld": "Amount has been changed to the number of shares you currently hold",
    "sellAllSharesText": "Sell all shares checkbox has been selected automatically",
    "sellShort": "Sell Short",
    "sellVanguardFunds": "Sell Vanguard funds",
    "share": "share",
    "shares": "Shares",
    "sharesHeldShort": "Your current short position",
    "sharesOwnedInCash": "Your current shares (cash)",
    "sharesOwnedInMargin": "Your current shares (margin)",
    "sixtyDayGTC": "60-day (GTC)",
    "specId": "SpecID",
    "specIdExpanded": "SpecID (Specific identification)",
    "stocks": "Stocks",
    "stop": "Stop",
    "stopLimit": "Stop limit",
    "stopPrice": "Stop price",
    "submit": "Submit Order",
    "submittedAt": "Submitted at",
    "symbol": "Symbol",
    "toggleLinkTextKeyword": "Search by Keyword",
    "toggleLinkTextSymbol": "Get Quote with Symbol",
    "tradeDetail": "Trade Details",
    "tradeEtfsOrStocks": "Trade ETFs or stocks",
    "tradeOtherBrokerageProducts": "Trade other brokerage products",
    "transaction": "Transaction",
    "unavailable": "Unavailable",
    "viewDetails": "View Details",
    "viewMyHoldings": "View my holdings",
    "viewOpenOrders": "View open orders",
    "viewProspectus": "View prospectus",
    "yourShares": "Your current shares"
  },
  "loading": {
    "loading": "Loading...",
    "longerLoadingTime": "This is taking longer than usual. Please continue waiting.",
    "preview": "Preparing your order",
    "refresh": "Refresh",
    "refreshHoldings": "Refresh holdings",
    "refreshOrders": "Refresh orders",
    "submit": "Submitting your order",
    "submitRequest": "Submitting your request",
    "updating": "Updating..."
  },
  "manageCookies": "Manage cookies",
  "marginCall": {
    "link": {
      "text": "Learn more about the three types of margin calls and what to do if you get one.",
      "url": "https://investor.vanguard.com/investor-resources-education/online-trading/margin-calls"
    },
    "title": "Your balances indicate you may be in a margin call."
  },
  "marginFooterLinkText": "Margin Investing guide (PDF)",
  "marginRequirementsForVanguardETFsBanner": {
    "descr": "After 30 days, the shares you purchase will automatically move to a margin position.",
    "title": "Vanguard ETFs must be paid for in full."
  },
  "marketVolatilityAlertBanner": {
    "body": "When placing trades during volatile periods you can take steps to lower your risk.",
    "header": "The markets are experiencing high volatility",
    "link": "Tips for trading during volatile markets.",
    "close": "Close"
  },
  "mutualFundErrorModal": {
    "body": "The symbol you've entered is for a Vanguard mutual fund. To buy, sell, or exchange Vanguard mutual funds, select one of the choices below. If you don’t wish to trade a Vanguard mutual fund, select the X close control in the upper right corner to return to your ETF or stock trade.",
    "bodyBeacon": "The symbol you've entered is for a Vanguard mutual fund. To buy, sell, or exchange Vanguard mutual funds, use the back arrow in the upper left corner. Then select <strong>Vanguard mutual funds</strong>. To continue with an ETF or stock trade, select the X in the upper right corner of this message.",
    "buyButton": "Buy Vanguard mutual funds",
    "exchangeButton": "Exchange Vanguard mutual funds",
    "header": "Trade Vanguard mutual funds",
    "sellButton": "Sell Vanguard mutual funds",
    "title": "Trying to trade a Vanguard mutual fund?"
  },
  "managedAccountsShingle": {
    "bodyPart1": "As a Personal Advisor client you’re not able to place trades.",
    "bodyPart2": "To talk to your advisor call",
    "scheduleAppointment": "schedule an appointment"
  },
  "noBuyOrderBanner": {
    "body": "Securities may be unavailable for purchase at Vanguard due to a number of variables including regulatory restrictions, OTC status, leveraged or inverse strategy, cryptocurrency-related, corporate actions, or various trading and/or settlement limitations.",
    "header": "Buy orders are not currently accepted for this security"
  },
  "nonVanguardMutualFund": {
    "body": "The symbol you've entered is for a non-Vanguard mutual fund. To buy or sell non-Vanguard mutual funds, use the link below.",
    "cancelButton": "Cancel",
    "heading": "Trying to trade a non-Vanguard mutual fund?",
    "tradeNonVanguarMutualFundsButton": "Trade non-Vanguard mutual funds"
  },
  "openNewWindow": "Opens a new window",
  "openOrders": {
    "cancelOrderTo": "Cancel order to",
    "changeOrderFrom": "Change order from ",
    "dollarsOf": "dollars of",
    "editCostBasisMethodOrderTo": "Edit cost basis of order to",
    "fixedIncome": "Fixed Income",
    "mutualFund": "Mutual Fund",
    "navigationInstructions": "Use the arrow keys to navigate order details.",
    "noAccountSelected": "To view your open orders, first select which account to use for this trade.",
    "nonEquityText1": "To change or cancel your ",
    "nonEquityText2": "order, visit ",
    "noOpenOrdersFound": "At this time you have no open or recently executed orders for this account.",
    "openOrders": "Open orders",
    "openOrdersUnavailable": "Your list of open or recently executed orders is unavailable at this time.",
    "options": "Options",
    "orderDate": "Order date",
    "orderForSymbol": "order for symbol",
    "orderStatus": "Order status",
    "orderStatusLinkText": "Order Status.",
    "pendingStatus1": "Vanguard reserves the right to review any order submitted electronically and to subsequently approve or reject the order in accordance with our trading policies. As a result of this review process, it’s possible that the order’s execution will be delayed or rejected pursuant to Vanguard’s trading policies. To monitor the status of an existing order, please refer to the",
    "pendingStatus2": "page on Vanguard.com.",
    "securityName": "Security Name",
    "sharesOf": " shares of "
  },
  "openOrdersTitle": "Open Orders",
  "orderDetail": {
    "account": "Account",
    "allOrNone": "All or none",
    "amount": "Amount",
    "commission": "Commission",
    "costBasis": "Cost basis",
    "dollars": "Dollars",
    "duration": "Duration",
    "estimatedAmount": "Estimated amount*",
    "estimatedNetAmount": "Estimated net amount",
    "estimatedNetAmountAbbr": "Est. net amount",
    "estimatedPrincipal": "Estimated principal",
    "estimatedPrincipalAbbr": "Est. principal",
    "estimatedShares": "Estimated shares*",
    "expirationDate": "Temp expiration date",
    "free": "Free",
    "limitPrice": "Limit price",
    "marketPrice": "Market price",
    "orderType": "Order type",
    "security": "Security",
    "shares": "Shares",
    "specID": {
      "costTitle": "Total Cost",
      "dateTitle": "Date Acquired",
      "hideSelectedShares": "Hide selected shares",
      "sharesTitle": "Shares to Sell",
      "showSelectedShares": "Show selected shares",
      "total": "Total"
    },
    "stopPrice": "Stop price",
    "transaction": "Transaction"
  },
  "orderStatusLabel": {
    "executed": "Executed",
    "inProgress": "In Progress",
    "open": "Open",
    "pending": "Pending"
  },
  "orderTypeModal": {
    "advancedOrderTypesLink": "Advanced Order Types: Stop and Stop Limit",
    "buyExample": "Buy example",
    "buyingTab": "Buying",
    "expandedDescription": "The execution of a stop or stop limit order is highly dependent on market conditions. Your order may execute at a price significantly different from the price you’ve set, execute partially, or not execute at all. <strong>These risks may be heightened during a volatile or market flash crash scenario.</strong> Weigh your options carefully and/or consult with your financial advisor before executing one of these advanced order types.",
    "limitOrder": "Limit order",
    "limitOrderBuyDescription": "You’re considering purchasing a stock that’s currently trading at $15. However, you only want to buy the stock if its price drops to $13. Therefore, you place a buy limit order with a limit price of $13. The order will execute only at or below your $13 limit price.",
    "limitOrderChart": "Line graph shows limit order executing at a price you’ve set.",
    "limitOrderDefinition": "An order to buy or sell a security at a specified price (limit price) or better.",
    "limitOrderSell": "Sell limit order example",
    "limitOrderSellDescription": "You own a stock that’s currently trading at $12. You decide to sell the stock only if its price rises to $13. Therefore, you place a sell limit order with a limit price of $13. The order will execute only at or above your $13 limit price.",
    "marketOrder": "Market order",
    "marketOrderChart": "Line graph shows market order executing close to order placement price.",
    "marketOrderDefinition": "An order to buy or sell a security at the best available price.",
    "marketOrderDescription": "The order is executed immediately, however the execution price can vary during volatile markets.",
    "orderTypes": "Order types",
    "orderTypesBodyTop": "Many investing strategies can be supported using market or limit orders. The following examples breakdown what you need to know to choose order types effectively. Note that in all examples the dollar amounts represent whole share prices, and that any hypothetical example does not represent the return on any actual investment. Execution of limit orders, stop orders, and stop limit orders are subject to market conditions and are not guaranteed to be completed. GTC orders should be checked periodically and are subject to partial fulfillment.",
    "sellExample": "Sell example",
    "sellingTab": "Selling",
    "stopLimitOrder": "Stop limit order",
    "stopLimitOrderBuyExample": "You’re interested in buying a stock that’s currently trading at $18 a share. You decide to buy the stock if its share price rises to $18.50, but you don’t want to buy it for anything more than $19.50. Therefore, you place a buy stop limit order with a stop price of $18.50 and a limit price of $19.50. Once the stock hits $18.50 or higher, a limit order is triggered. However, the order will execute only at or below your $19.50 limit price.",
    "stopLimitOrderChart": "Line graph shows stop limit order executing a limit order at price in the range you’ve set.",
    "stopLimitOrderDefinition": "An order that triggers a limit order once a specified price (the stop price) has been achieved.",
    "stopLimitOrderDescription": "Under this order type you place an order to buy or sell a security at a limit price or better once a specified price (the stop price) has been achieved. The limit price should be at or below the stop price for sell orders and at or above the stop price for buy orders.",
    "stopLimitOrderSellExample": "You own a stock that’s currently trading at $18 a share. You decide to sell the stock if its share price falls to $15, but you don’t want to sell it for anything less than $14.50. Therefore, you place a sell stop limit order with a stop price of $15 and a limit price of $14.50. Once the stock drops to $15 or lower, a limit order is triggered. However, the order will execute only at or above your $14.50 limit price.",
    "stopOrder": "Stop order",
    "stopOrderBuyExample": "You are looking to buy a stock that’s currently trading at $18 a share. You decide to buy stock if the price rises to $18.50. Therefore, you place a buy stop order with a stop price of $18.50. Once the share price hits $18.50 or higher, your purchase is executed as a market order (which may be higher or lower than your stop price).",
    "stopOrderChart": "Line graph shows stop order executing a market order at the stop price you’ve set.",
    "stopOrderDefinition": "An order that triggers a market order once a specified price (the stop price) has been achieved.",
    "stopOrderSellExample": "You own a stock that’s currently trading at $18. You decide to sell the stock if its price falls to $15 or lower. Therefore, you place a sell stop order with a stop price of $15. Once the stock drops to $15 or lower, your stock is sold at the current market price (which may be higher or lower than your $15 stop price)."
  },
  "otcLimitOnly": "Over the Counter securities can be thinly traded and may be difficult to buy or sell without affecting the quoted price. Only limit orders are accepted online.",
  "otcRestrictionNotice": "Vanguard restricts purchases of most over-the-counter (OTC) securities.  Clients may continue to hold and sell their existing positions in these securities and make additional purchases of a small selection of global American Depository Receipts (ADRs).",
  "primaryNavigation": "Primary Navigation",
  "quote": {
    "askTimesSize": "Ask Times Size",
    "askTimesSizeAbbr": "ASK x SIZE",
    "bidTimesSize": "Bid Times Size",
    "bidTimesSizeAbbr": "BID x SIZE",
    "change": "CHANGE",
    "chip": {
      "afterMarketPrice": "After-Market Price"
    },
    "day": "day",
    "decreased": "decreased",
    "detailsDisplayed": "Quote details are displayed within the Real-Time Quote section on this page",
    "divDate": "Dividend Date",
    "divDateAbbr": "DIV DATE",
    "exchange": "Exchange",
    "exDivDate": "EX Dividend Date",
    "exDivDateAbbr": "EX-DIV DATE",
    "fiftyTwoWeek": "52 Week Range",
    "fiftyTwoWeekAbbr": "52-WK",
    "hideDetailedQuote": "Hide Detailed Quote",
    "increased": "increased",
    "lastSize": "Last Size",
    "lastTrade": "Last trade",
    "open": "OPEN",
    "prevClose": "Previous Close",
    "prevCloseAbbr": "PREV CLOSE",
    "primaryExchange": "PRIMARY EXCHANGE",
    "realTimeAlt": "More info about Real-Time Quote",
    "realTimeQuote": "Real-Time Quote",
    "showDetailedQuote": "Show Detailed Quote",
    "volume": "VOLUME",
    "warnings": {
      "limitUpLimitDown": {
        "linkToModal": "Learn more about single security price limit rules.",
        "message": "At least one of the displayed quotes for this security is currently outside of price limits set by market regulations. Because of this, an order that might otherwise appear to be due an execution, may not execute."
      },
      "subPennyStocks": {
        "linkToContact": "800-992-8327",
        "message": "This security trades below $0.01. Trading in securities priced less than $0.01 is not permitted.  To place a sell order on an existing position, please contact us at"
      }
    }
  },
  "quoteDisclaimerLink": "Quote information",
  "refinitivDisclaimerModal": {
    "body": "Quotes are shown in real time during applicable trading hours. Market data provided by Refinitiv. Data are provided for information purposes only and are not intended for trading purposes. Refinitiv shall not be liable for any errors or any delay in the content, or for any action taken in reliance thereon. The Refinitiv Instrument Code (RIC) has been developed and maintained by Refinitiv and is the intellectual property of Refinitiv.",
    "title": "Refinitiv disclaimer"
  },
  "retry": "Retry",
  "selectAccount": "Select Account",
  "selectSharesPage": {
    "lotsSelectionContent": {
      "longTermHoldings": "Long-term holdings",
      "shortTermHoldings": "Short-term holdings",
      "viewDefinitions": "View definitions",
      "lotTableContent": {
        "tableHeadings": {
          "numberOfSharesToTrade": "Number of shares to trade",
          "sharesOwned": "Shares owned",
          "estGainLossPerShare": "Est. gain/loss (per share)",
          "estAvailableProceeds": "Est. available proceeds",
          "totalCost": "Total cost",
          "dateAcquired": "Date acquired"
        },
        "numberOfSharesValidationError": "Amount must be equal to or less than the lot quantity.",
        "additionalLotInfoButtonLabel": "Click to open modal with additional information",
        "washSaleLabel": "Wash sale",
        "nonCoveredLabel": "Noncovered",
        "washSaleModalHeading": "Wash sale",
        "washSaleModalText": "Shares in this lot are affected by a wash sale.",
        "taxLossHarvestingLinkText": "Learn more about tax-loss harvesting",
        "nonCoveredModalHeading": "Noncovered shares",
        "nonCoveredModalText": "These shares are \"noncovered,\" which means that Vanguard will not report cost basis information for these shares to the IRS. Vanguard is required by law to report to the IRS on Form 1099-B, the cost basis information for the sale of covered shares. Once this trade is settled, you cannot change this cost basis information.",
        "unallocatedLabel": "Unreported cost basis",
        "unallocatedModalHeading": "Unreported cost basis",
        "unallocatedModalBeginningText": "Vanguard has no recorded cost basis for these shares. To update this information, complete and return ",
        "unallocatedModalLinkText": "this form",
        "unallocatedModalEndText": " to Vanguard.",
        "a11y": {
          "sortingStatus": "Sorting status",
          "ascending": "ascending",
          "descending": "descending",
          "default": "default",
          "selectAll": "Select all",
          "increased": "increased",
          "decreased": "decreased"
        }
      },
      "summaryContent": {
        "totalSelectedShares": "Total selected shares to sell",
        "totalEstimatedGainLoss": "Total estimated gain/loss",
        "totalEstimatedProceeds": "Total estimated proceeds",
        "totalCostOfSelectedShares": "Total cost of selected shares",
        "viewDetails": ""
      },
      "tooltipContent": {
        "longTermHoldingsTooltipText": "The capital gains on investments you owned for more than 1 year.  They're subject to a 0%, 15%, or 20% tax rate, depending on your level of taxable income.",
        "shortTermHoldingsTooltipText": "The capital gains on investments you owned 1 year or less and are taxed at your ordinary income tax rate."
      },
      "bannerContent": {
        "shortTermSharesBannerTitle": "Short-term shares selected",
        "shortTermSharesBannerBody": "Shares held one year or less are considered short-term holdings and capital gains realized from these shares may be taxed at ordinary income rates.",
        "nonCoveredSharesBannerTitle": "Noncovered shares selected",
        "nonCoveredSharesBannerBody": "You have selected some shares purchased prior to January 1, 2012. Vanguard only has average cost information for these shares. You'll need to use your own records to report these noncovered shares to the IRS using specific identification.",
        "nonCoveredSharesMoreInfoLink": "More information about noncovered shares",
        "learnMoreCapitalGainsLink": "Learn more about capital gains"
      },
      "a11yLabelsContent": {
        "shortTermLotTableA11yId": "long-term-lot-table-a11y-label",
        "longTermLotTableA11yId": "short-term-lot-table-a11y-label",
        "capitalGainsLinkAriaLabel": "capital-gains-link-a11y-label",
        "opensInANewTab": "opens in a new tab"
      }
    },
    "mixedMethodsModal": {
      "body1": "If you execute multiple trades involving the same security, and choose the same tax lots using specific identification (Spec ID) as your cost basis method, your second order may be defaulted to first in, first out (FIFO) as your cost basis method. If you have questions, contact us by sending a ",
      "body2": " prior to the order's settlement.",
      "buttonText": "I understand",
      "heading": "Are you trading this security twice in one day?",
      "phoneNumberLink": "tel:+18009928327",
      "phoneNumberText": "800-992-8327",
      "secureMessageText": "secure message",
      "orAtText": " or at "
    },
    "moreAboutCostBasis": "More about selecting shares to trade and cost basis terms",
    "partialExecutionNotice": "<strong>Note</strong>: If your order receives a partial execution, your selected shares will be sold using the \"First in, First out\" (FIFO) cost basis method.",
    "refreshBtnAriaLabel": "Last successful response, as of ",
    "select": "Select",
    "selectedMoreSharesThanRequiredPart1": "Your total selected shares must equal the amount you’re trading:",
    "selectedMoreSharesThanRequiredPart2": "You can adjust the amounts in the fields. Please make sure the amounts don’t exceed the quantity of shares available.",
    "sharesToTrade": "to trade",
    "totalsMustMatch": "Your total selected shares must match the amount you’d like to sell. Please revise amounts in the selected shares to trade fields."
  },
  "sellAllInContextMessage": "When selling all, remaining fractional shares may take as long as one additional business day to update in your account. Do not \"sell all\" of the same security twice in one day.",
  "sellAllLessThanOneOrderTypeHint": "Sell orders of less than one share must be executed as market orders.",
  "singleStockPriceBandsModal": {
    "body": {
      "descr": "Recently, to address market events such as the \"Flash Crash,\" the national stock exchanges, FINRA and the SEC implemented a new marketplace rule designed to prevent erroneous trades and respond to periods of extraordinary volatility. The new rule, known as the Limit Up-Limit Down Rule, prevents trades from executing outside of price bands established throughout the day for individual stocks and ETFs. The new rule will be implemented on a rolling \"pilot\" basis and does not apply to all securities.",
      "howRuleWork": {
        "p1": "First, a reference price is continuously calculated for each stock and ETF subject to the new rule. The reference price is based on recent executions in the particular stock or ETF across the markets. Next, price bands above and below the reference price are continuously calculated and updated to determine the range of permitted execution prices for the particular stock or ETF. Trades cannot execute below the lower price band or above the upper price band.",
        "p2": "In addition to preventing executions outside the price bands, the new rule halts (pauses) trading when certain conditions are met. The halts are designed to accommodate fundamental price movements in a stock or ETF. Generally, after 5 minutes, the stock or ETF will reopen pursuant to normal market opening rules.",
        "title": "How does the rule work?"
      },
      "howPriceBandsCalculated": {
        "p1": "The applicable price bands vary based on a number of considerations, including the security involved, the price at which it is trading, and the time of day. Below is a high level summary of the standard price bands. For more detailed information on the applicable price bands, see the reference materials provided below.",
        "title": "How are price bands calculated?",
        "table": {
          "header": {
            "securityType": "Stocks and ETFs",
            "pricePercentages": "Price percentages"
          },
          "rows": [
            {
              "securityType": "Priced above $3.00",
              "pricePercentages": "5% (stocks in the S&P 500 index, Russell 1000 index, and certain ETFs)<br>10% (other stocks and ETFs beginning August 2013)"
            },
            {
              "securityType": "Priced between $0.75 and $3.00",
              "pricePercentages": "20%"
            },
            {
              "securityType": "Priced below $0.75",
              "pricePercentages": "Lesser of $0.30 or 75%"
            }
          ]
        }
      },
      "whenTradingHalted": {
        "p1": "If certain public quotations for an individual stock or ETF equal the price bands and trading does not occur within the price bands for 15 seconds, trading in the security will be halted for 5 minutes. As an example, if the lower price band is $10.00 and the best \"ask\" price is also $10.00 for a period of 15 seconds, trading will halt for 5 minutes. Conversely, if the upper price band is $10.00 and the best \"bid\" price is also $10.00 for a period of 15 seconds, trading will halt for 5 minutes.",
        "p2": "The market centers will reopen trading according to their standard procedures and new price bands will be established once trading has commenced.",
        "title": "When will trading be halted?"
      },
      "howRuleImpactDisplayQuotations": {
        "example": {
          "lowerPriceBand": "Lower price band = $95.00",
          "upperPriceBand": "Upper price band = $105.00"
        },
        "p1": "The rule also prevents certain orders from being displayed in the public quotation system. Here is an example:",
        "p2": "Orders to purchase the stock (bids) will be displayed up to the upper price band of $105.00. Purchase orders above $105.00 will be submitted to the markets, but at a price of $105.00. A purchase order below $95.00 will be displayed as entered, but would be identified as non-executable.",
        "p3": "Conversely, orders to sell the stock (asks) will be displayed down to $95.00. Sell orders below $95.00 will be submitted to the markets, but at a price of $95.00. A sell order above $105.00 will be displayed as entered, but would be identified as non-executable.",
        "title": "How does the rule impact the display of quotations?"
      },
      "whereInfoBeAvailable": {
        "p1": {
          "part1": "When you select \"get quote\" on the \"trade ETFs or stocks\" page of Vanguard’s trade path, a",
          "part2": "image will appear next to all quotes that are outside applicable price bands at the time of the request. Markets move quickly and the bands may change prior to the submission of your order. The fact that a quote is flagged does not mean you cannot place your order; it is simply an additional piece of information for you to consider. For securities that have been halted, a notification will be presented after selecting \"continue.\""
        },
        "p2": "If you have questions, please contact a Vanguard representative.",
        "title": "Where will information about quotes and halts be available?"
      },
      "whatMeanForMe": {
        "list": [
          "You may see publicly displayed quotations that are not executable. For example, you may see a Bid that is below the lower price band. In such a situation, a market order to sell the security may not execute immediately.",
          "Aggressively priced orders may not display at the price you submit. In volatile situations where a security may be experiencing a fundamental move, you may place aggressive orders outside the applicable price bands that do not execute and will not be displayed at the prices you entered because the market for the security has moved outside the price bands.",
          "If you have open orders for a security that has been halted, Vanguard will continue to submit your original order (at your original price) in the reopening process. You should monitor outstanding orders via the “order status” page on vanguard.com."
        ],
        "p1": "The rule is meant to address extraordinary market volatility, so, generally speaking, you should not experience any significant changes. Nevertheless, the following are some things to keep in mind:",
        "title": "What does this mean for me?"
      },
      "additionalInfo": {
        "link": "http://www.sec.gov/news/press/2012/2012-107.htm",
        "p1": "The above information is a high level summary for your convenience. The Limit Up-Limit Down rule is complicated and there are many details to the rule that are not set forth above. You can obtain additional information about the rule at",
        "title": "Where can I find additional information?"
      }
    },
    "title": "Single stock price bands"
  },
  "specIdModal": {
    "body1": "When making a trade using SpecID as your cost basis method, the sum total of shares in the <strong>Total selected shares to be traded</strong> column must match the number of shares you’ve indicated you want to sell. For example, if you want to sell 10 shares, the sum of the <strong>Number of shares to be traded</strong> fields must equal 10.",
    "body2": "You’re able to enter fractional share amounts. This means you can customize each field in the <strong>Number of shares to be traded</strong> column to the number you’d like as long as it does not exceed the corresponding amount in the <strong>Quantity</strong> column.",
    "body3": "You may select <strong>+ALL</strong> on the right side of your screen to include the full amount indicated in the corresponding column.",
    "costBasis": "Cost basis terms",
    "costbasisTerms": [
      {
        "header": "Date acquired",
        "description": "The date you purchased this lot of shares."
      },
      {
        "header": "Total cost",
        "description": "The amount of money your shares were worth at the time of purchase or short sale."
      },
      {
        "header": "Estimated +/- (per share)",
        "description": "The approximate amount per share the security has gained or lost since they were purchased."
      },
      {
        "header": "Number of shares to be traded",
        "description": "The number of shares you'd like to trade from the specified lot."
      },
      {
        "header": "Total estimated +/- (per share)",
        "description": "The approximate sum total per share that your security has gained or lost since the shares were purchased."
      },
      {
        "header": "Total estimated proceeds",
        "description": "The estimated amount you would receive from the sale of your security after costs and expenses. If you are closing a short position, this is the estimated cost to close."
      },
      {
        "header": "Total selected shares to be traded",
        "description": "The amount of shares that you're seeking to trade as part of this transaction."
      },
      {
        "header": "Short-term holdings",
        "description": "The capital gains on investments you owned 1 year or less and are taxed at your ordinary income tax rate."
      },
      {
        "header": "Long-term holdings",
        "description": "The capital gains on investments you owned for more than 1 year.  They're subject to a 0%, 15%, or 20% tax rate, depending on your level of taxable income."
      }
    ],
    "description": "Description",
    "header": "Cost basis instructions and terms",
    "note": "<strong>Note:</strong> The unrealized gains and losses presented here are estimates only. The amount of gain or loss you realize on the sale may differ from the amounts shown.",
    "term": "Term",
    "title": "Selecting shares to sell"
  },
  "specIdNotAvailableSellAllUnderOne": "SpecID (specific identification) is not available for sell orders of less than one share.",
  "submitTimeoutModal": {
    "body1": "Please check your ",
    "body2": " before attempting to resubmit.",
    "body3": "Resubmitting could create a duplicate order.",
    "orderStatusText": "order status",
    "title": "This order could not be confirmed"
  },
  "symbolKeywordModal": {
    "description": "See the table below for examples of how to enter a company’s non-standard security on vanguard.com. Replace the \"XYZ\" hypothetical security symbol examples with the specific symbol you have in mind.",
    "leftColumnTitle": "IF YOU’RE LOOKING FOR THIS SECURITY TYPE",
    "note": "<strong>Note:</strong> This is not a comprehensive list. Other securities may require special formatting. In some cases, the securities listed above may require different formatting.",
    "rightColumnTitle": "USE THIS SYMBOL FORMAT",
    "securityType": [
      {
        "name": "Common stock",
        "ticker": "XYZ"
      },
      {
        "name": "Common stock with class A",
        "ticker": "XYZ A"
      },
      {
        "name": "Common stock with class B",
        "ticker": "XYZ B"
      },
      {
        "name": "Preferred security",
        "ticker": "XYZ pr"
      },
      {
        "name": "Preferred security with class A",
        "ticker": "XYZ prA"
      },
      {
        "name": "Preferred security with class B",
        "ticker": "XYZ prB"
      },
      {
        "name": "Warrants",
        "ticker": "XYZ ws"
      },
      {
        "name": "Warrants with class A",
        "ticker": "XYZ wsA"
      },
      {
        "name": "Warrants with class B",
        "ticker": "XYZ wsB"
      },
      {
        "name": "Rights",
        "ticker": "XYZ rt"
      },
      {
        "name": "Units",
        "ticker": "XYZ u"
      }
    ],
    "title": "Symbol Formatting",
    "xyzCompany": "XYZ Company (ticker symbol: XYZ)"
  },
  "symbolSearchDescription": "Enter a security’s ticker symbol, then press enter or tab to obtain a real-time quote. Quote details are displayed within the real-time quote section on this page.",
  "tipsForTradingVolatilityModal": {
    "body": "High market volatility is defined as brief periods of increased trading volume coupled with sharp rises and falls of share prices.  Here are three actions to consider during these periods.",
    "learnMoreLink": "https://investor.vanguard.com/investor-resources-education/market-volatility",
    "learnMoreLinkText": "Learn more about trading during periods of market volatility",
    "legalText": "All investing is subject to risk, including the possible loss of the money you invest. Diversification does not ensure a profit or protect against a loss.",
    "subtitle1": "Use order types to reduce your risk",
    "subtitle2": "Consider your goals",
    "subtitle3": "Watch for market halts",
    "text1": "Placing a limit order allows you to set the price for the execution of your trade.  But, keep in mind that unlike a market order there’s no guarantee your order will execute.",
    "text1Link": "Show me how order types work",
    "text2": "If your financial goals haven’t changed, and you hold a diversified portfolio that aligns with your risk tolerance, the good news is you may not need to change your current investing approach.  A recovery often follows a market downturn.",
    "text3": "Because of heavy trading volume in a particular security or the market overall, circuit breakers may stop trading for a brief period when the market drops quickly.",
    "title": "Tips for trading during high market volatility"
  },
  "tradeEtfTitle": "Trade ETFs or Stocks",
  "tradeHelpModal": {
    "dollarBasedTradesTab": {
      "title": "Dollar-Based Trading Terms",
      "headingText": "Dollars-based trading terms",
      "dollarBasedTrading": {
        "body1": "Updated October 2024",
        "body2": "These Terms cover Your use of the trading functionality provided by Vanguard Brokerage Services (\"VBS\"), which allows You to place dollar value trades in certain Securities (the \"Service\"), as described below. These Terms are part of a binding legal contract between You and VBS (together with its parent, and their respective affiliates, \"VBS\" or \"We\"). Please read these Terms carefully before using the Service because using the Service indicates that You agree to these Terms.  Your use of the Service is governed by these Terms, as well as the terms of all other agreements You have with VBS, including the Vanguard Brokerage Account Agreement, the Website Terms of Use, and the Vanguard Mobile App Terms of Use (collectively, the \"VBS Agreements\"). These Terms supplement the VBS Agreements.",
        "body3": "Unless defined in these Terms, capitalized terms used in these Terms shall have the meanings given to them in the VBS Agreements. VBS may change these Terms at any time without prior notice. Your continued use of the Service indicates that You agree to be bound by these Terms.",
        "title": "Dollar-Based Trading Terms of Use (\"Terms\")"
      },
      "OverviewDollarTrades": {
        "body1": "The Service allows You to buy and sell Eligible Securities (as defined below) in dollar values (\"Dollar Trades\"), rather than in whole shares. For example, if You wanted to invest $1,000.00 in Vanguard's Total Stock Market Index Fund ETF (symbol: VTI) trading at $204.60 and could purchase only whole shares, You could acquire only four (4) whole shares of VTI, which would leave more than 18% of Your investable funds ($181 of $1,000) out of the market. By contrast, if You placed a Dollar Trade to buy $1,000.00 of VTI at the same price, You could acquire approximately 4.8875 shares of that Security.",
        "body2": "When You place Dollar Trades under the Service, You will likely end up owning a fraction of a single share (\"Fractional Share\"), or a number of whole shares and a Fractional Share of the Eligible Securities You purchase. As described below, Fractional Shares present unique risks, and have certain limitations.  They also may have different rights from a whole share of the same Security.",
        "title": "Overview: Dollar Trades and Fractional Shares"
      },
      "eligibleAccounts": {
        "body1": "The Service is available only to self-directed clients that have a VBS brokerage account and that are otherwise deemed eligible for the Service, as determined by VBS in its sole discretion.",
        "body2": "The Service is also available to Margin Accounts and these orders are also subject to VBS’ margin policies and requirements as defined in the Vanguard Brokerage Margin Account Agreement and other applicable documents.",
        "body3": "VBS reserves the right to add, delete, or amend the type of Accounts that are eligible for the Service at any time. There may be different eligibility requirements for the Automatic ETF Investment Plan Service, as described below.",
        "title": "Eligible Accounts"
      },
      "eligibleSecurities": {
        "body": "At this time, only exchange traded funds (\"ETFs\") issued by The Vanguard Group, Inc. (\"VGI\") are eligible for Dollar Trades (\"Eligible Securities\"). VBS reserves the right to add or delete a Security to the list of Eligible Securities at any time. The inclusion or removal of particular Security as an Eligible Security under the Service is not a recommendation related to any Security, nor is it advice. If VBS revokes a particular Security's status as an Eligible Security, You will no longer be able to place additional Dollar Trades to purchase or sell that Security. You may, however, continue to place whole share trades to buy and sell such Securities. If VBS no longer classifies a particular Security as an Eligible Security and You hold only a Fractional Share of that Security, it will be subject to VBS' Auto-Liquidation processes, described below.",
        "title": "Eligible Securities"
      },
      "eligibleOrders": {
        "body": "At this time, the Service supports only market orders. VBS reserves the right to expand the order types supported by the Service (e.g., limit orders).",
        "title": "Eligible Orders"
      },
      "dollarValueOrderConversion": {
        "body": "Your Dollar Trade will be converted into share quantities for execution to four decimal places, and the share quantities will be rounded down. This rounding process may result in the final purchase amount of an Eligible Security being one to several cents less than what You intended to invest (for example, an order for $999.98 instead of $1,000.00).",
        "title": "Dollar Value Order Conversion"
      },
      "estimatedShare": {
        "body": "Before you place Your Dollar Trade, VBS will provide You with an estimate of the share quantity impacted by Your proposed Dollar Trade, including any Fractional Share quantity you may buy or sell as a result of such trade (\"Estimated Share Quantity\"). VBS calculates this estimate to provide You with an approximation of the total quantity of shares impacted by Your Dollar Trade, and to meet its regulatory reporting obligations. For Dollar Trades placed during market hours, VBS performs this conversion by dividing the dollar amount You enter in the trade path by the then-current bid/ask price; if that data is unavailable for any reason, VBS will use the last traded price or (if neither current bid/ask nor last traded price data is available) the previous closing price in that Eligible Security. For Dollar Trades placed outside market hours, VBS provides an Estimated Share Quantity using the previous closing price.",
        "title": "Estimated Share Quantity"
      },
      "finalFractional": {
        "body": "Once You place a Dollar Trade, VBS recalculates the Fractional Share quantity impacted by Your order based on the then-current bid/ask price. For Dollar Trades placed after market hours, VBS will use the bid/ask price at market open. Any changes in the price of the Eligible Security in Your order will be reflected in the share quantity You receive for Your Dollar Trade. For example, if You entered a proposed Dollar Trade to buy $500.00 of VTI currently trading at $233.00, Your Estimated Share Quantity would be 2.1459 shares. If, after You place a Dollar Trade, the price of VTI increases to $234.50, then Your Final Fractional Share Quantity will be 2.1321, as the price increase results in a corresponding reduction in the Fractional Share quantity you will receive. Since the Final Fractional Share Quantity You receive may change based on the then-current price, in periods of heightened market volatility, significant price variations in Securities may result in corresponding variations between Your Estimated Share Quantity and Your Final Fractional Share Quantity.",
        "title": "Final Fractional Share Quantity"
      },
      "sellingEligibleSecurities": {
        "body": "You may sell Eligible Securities You bought through the Service in whole shares or in dollar amounts (sometimes referred to as notional trades). For example, if You own 4.88 shares of an Eligible Security trading at $205.00 (total value: approx. $1,000.00), You can enter a whole share trade to sell up to four shares of that Security, or You can enter a Dollar Trade to sell $500.00 in that Eligible Security. If You own one or more whole shares in an Eligible Security enabled by Dollar Trading, You may sell Your entire share quantity by entering a share-based trade (as opposed to a Dollar Trade) to sell all Your whole shares, which will also result in the Auto-Liquidation (described below) of any Fractional Share you own in that Security. If You own a Fractional Share only for an Eligible Security, You may sell Your entire Fractional Share by electing to sell all shares in the trade path. VBS does not guarantee that there will be a market for Fractional Shares in any Security, including Eligible Securities.",
        "title": "Selling Eligible Securities"
      },
      "avoidingOverselling": {
        "body": "As described above, Your Final Share Quantity reflects the then-current price for that Eligible Security, and that price may change between when You enter Your order and when it is executed. This means that if You were to place a Dollar Trade to sell nearly all of Your entire position in an Eligible Security, You would risk \"overselling\" Your position, that is, selling more shares than you own. For example, if You own 4.25 shares of VTI valued at $231, the total value of Your position in VTI is approximately $1000.00. If You were to place a Dollar Trade to sell $1000.00 of VTI and its price decreases to $229, You would need to sell a greater share quantity of VTI than you own (4.3668 shares vs. 4.25 shares) to execute that trade. As a control to help prevent overselling, if Your proposed Dollar Trade exceeds a certain threshold of your entire position in that Eligible Security (\"Position Threshold\"), then prior to placing Your Dollar Trade, VBS will prompt You either to lower the dollar amount of Your Dollar Trade so that it no longer Exceeds the Position Threshold, or indicate that you want to sell Your entire position, including all Fractional Shares in that Eligible Security. The Position Threshold is currently 98% or more of Your total position in an Eligible Security in an Eligible Account. VBS reserves the right to amend or modify the Position Threshold in its sole discretion.",
        "title": "Avoiding \"Overselling\""
      },
      "tradesNotHeld": {
        "body": "All Dollar Trades will be \"not held\" orders. In addition, if You hold a position in an Eligible Security that is less than one whole share (e.g., You own .8743 share of Vanguard’s Total Stock Market Index Fund ETF (symbol: VTI), and You place an order to sell Your entire position in that Eligible Security, Your order will also be \"not held.\"  A \"not held\" order means that You grant VBS time and price discretion to transact Your orders on a best-efforts basis. You will not be permitted to enter limit or other conditional orders under the Service. There may be a significant difference in the quoted price for a stock between the time You enter Your order and the time the order is executed. You agree that VBS is not responsible for any potential market fluctuations, or alleged trading losses, during this time period. If You do not want Your order to be worked on a \"not held\" basis, do not use the Service.",
        "title": "Trades \"Not Held\""
      },
      "orderDuration": {
        "body": "Orders placed using the Service during market hours are good for that day’s trading session. Orders entered outside of market hours are good until the close of the next trading session.",
        "title": "Order Duration"
      },
      "orderAggregation": {
        "body": "VBS may, in its discretion, aggregate all Dollar Trade orders for the same Eligible Security placed outside market hours. Under these circumstances, the aggregated orders will each receive the same order execution price as similarly-aggregated Dollar Trades in the same Eligible Security, and will not receive the \"opening print\" for such Eligible Security.",
        "title": "Order Aggregation"
      },
      "extendedHoursTrading": {
        "body": "Dollar Trades are not eligible for Extended Hours Trading Sessions.",
        "title": "Extended Hours Trading"
      },
      "tradingHalts": {
        "body": "In the event of a market trading halt, the Service will also be halted for all Securities, and Your order will be held until trading resumes. However, your order is good only for that day’s trading session, or in the case of an order entered outside of market hours, good until the close of the next trading session. If trading does not resume or Your order is not executed within that timeframe, it will be canceled.",
        "title": "Trading Halts"
      },
      "orderFulfillment": {
        "body": "VBS may act as agent or principal with respect to Dollar Trades. You will receive a trade confirmation detailing VBS’ capacity (i.e., agent, principal or mixed capacity) for all Dollar Trades You place when You use the Service.",
        "body2": "The whole share component of all Dollar Trade orders will be executed by VBS as agent and will receive the price VBS receives from its execution partner. The corresponding Fractional Share may be executed by VBS as agent or principal and receive the same execution price as the whole shares. ",
        "body3": "If VBS fulfills a Dollar Trade comprised only of a Fractional Share, VBS will execute this transaction as agent and it will receive the price VBS receives from its execution partner.",
        "body4": "When trading as principal for its own account, VBS may make a profit or incur a loss on each trade. For example, if You place a trade to buy an Eligible Security and VBS is required to acquire a whole share to fulfill the Fractional Share portion of that trade, that Fractional Share will receive the execution price of the corresponding whole shares for Your order, but VBS may receive a more favorable price for that whole share based on then-current market data (e.g., NBBO).",
        "title": "Order Fulfillment: Capacity, Pricing, and Trade Confirmations"
      },
      "limitedTradeChannels": {
        "body": "The Service is only available on VBS' website and mobile app. As a result, You cannot place Dollar Trades through any other Alternative Channels (defined below) that VBS may make available to You.",
        "title": "Limited Trade Channels for Dollar Trades"
      },
      "costBasisMethodRestrictions": {
        "body1": "VBS' default cost basis method for Securities You acquire via Dollar Trades is \"First In, First Out\" (\"FIFO\"). You may select an alternative cost basis method for each Security under the \"Profile and Account Settings\" page on vanguard.com, but one cost basis method, Specific Identification (\"SpecID\"), may not be used for Dollar Trades. If You previously selected SpecID as Your preferred cost basis method and place a Dollar Trade to sell that Security, VBS will use FIFO as the cost basis method for that trade. If You want to place a Dollar Trade using an alternative available cost basis method (e.g., \"Highest in, First Out\" (\"HIFO\")), You can select it under the \"Profile and Account Settings\" link before You place Your sell order. If You wish to use SpecID as Your cost basis method for a sell order, You should place Your order in whole shares, rather than as a Dollar Trade.",
        "body2": "VBS also makes certain alternative cost basis methods available in the trade path as you place Your sell order. Your cost basis method options in the trade path for Dollar Trades, however, are more limited than those available under the \"Profile and Account Settings\" link on vanguard.com. In particular, if You previously selected \"Average Cost\" as the cost basis method for a Security and place a Dollar Trade sell order, You will not be able to select an alternative cost basis method in the trade path. Instead, You will need to select an alternative cost basis method under the \"Profile and Account Settings\" link prior to placing Your sell order.",
        "title": "Cost Basis Method Restrictions and Limitations"
      },
      "serviceLimitation": {
        "body": "VBS provides the Service \"as is\": it does not guarantee that the Service will be available to You at a particular time, and reserves the right to suspend or terminate the Service at any time without prior notice. Access to the Service may be limited or unavailable during periods of peak demand, market volatility, systems upgrades or maintenance, or for other reasons. If the Service is unavailable or delayed at any time, You agree to use alternative channels to place Your orders, such as using an alternative trading channel identified by VBS (\"Alternative Channels\"). You also acknowledge and agree that the Service may not be available in some or all of these Alternative Channels. As a result, You will not be able to place Dollar Trades, and may only execute trades in these Alternative Channels in whole shares only.",
        "title": "Service Limitations/Unavailability"
      },
      "fractionalShareOwnership": "Fractional Share Ownership",
      "dividends": {
        "bodyPart1": "You will receive dividends on Fractional Shares unless the prorated amount of the dividend is less than one half of one cent ($0.005). Fractional Shares are eligible for automatic reinvestment of cash dividends received, at Your election, subject to the terms of VBS' ",
        "bodyPart2": "If You participate in VBS' dividend reinvestment program and Your dividend would equate to less than .0005 share of that Security, then You will receive Your dividend in cash.",
        "link": "https://investor.vanguard.com/client-benefits/dividend-reinvestment",
        "linkText": "Dividend Reinvestment Program",
        "title": "Dividends"
      },
      "corporateActions": {
        "body": "The eligibility of Fractional Shares to participate in corporate actions may be different than whole shares. For mandatory reorganizations, such as mergers and acquisitions, stock splits, or spin-offs, typically VBS will distribute Your interest in proportion to Your ownership interest, inclusive of any Fractional Shares. However, only whole shares, and not Fractional Shares, are eligible to participate in voluntary corporate actions, including tender offers and certain rights offerings.",
        "title": "Corporate Actions"
      },
      "votingRights": {
        "body": "If You hold a Fractional Share in a Vanguard ETF, You will receive a fractional vote for each fractional dollar amount of net asset value (\"NAV\") owned on the record date. For voting purposes, Your Fractional Share ownership interest will be rounded to the third decimal; for example, if You own .7757 share of VTI, then VBS will round Your voting interest for that Vanguard ETF to .776 share. VBS works with a vendor that aggregates any proxy votes for Fractional Shares of VBS' clients with all votes reported to the issuer or issuer's designated vote tabulator. VBS' vendor will report such proxy votes on Fractional Shares, but the issuer or tabulator may not fully count such Fractional Share votes.",
        "title": "Voting Rights in Vanguard ETFs"
      },
      "stockCertificates": {
        "body": "Stock certificates cannot be issued for Fractional Shares.",
        "title": "Stock Certificates"
      },
      "accountTransfer": {
        "body": "Fractional Shares are not currently transferrable to another brokerage firm. If, for example, You own 10.5 shares of a Security and instruct VBS to transfer Your Account to another firm, 10 shares will be transferred. Your 0.5 Fractional Share will be acquired and liquidated by VBS, and will receive the closing price from the trading day immediately preceding the transfer of Your whole share(s) to the other firm. VBS will distribute these proceeds in Your Account, and will provide confirmation of the Fractional Share liquidation on Your periodic statement. Since Your Fractional Shares cannot be transferred, Your overall SIPC coverage may be affected.",
        "title": "Account Transfer"
      },
      "suspendAutoLiquidation": {
        "body": "Prior to offering the Service, if You acquired a Fractional Share in Your Account (e.g., via participation in VBS' dividend reinvestment program), it had been VBS' practice to automatically sell (\"Auto-Liquidate\") Your Fractional Share holdings when You place an order to sell Your entire whole share position in that Security. For example, if You owned 10.2 shares in a Security and sold all 10 shares, VBS would Auto-Liquidate the remaining .2 Fractional Share and place the proceeds of that sale into Your Account. VBS also Auto-Liquidated \"orphaned Fractional Shares,\" that is, Fractional Shares You may receive without a corresponding whole share position in that Security. The first time You use the Service, VBS will turn off the Auto-Liquidate feature for each Eligible Security in which You place a Dollar Trade for the Account in which You placed such Dollar Trade. As a result, if You acquired a Fractional Share in an Eligible Security using the Service and own only a Fractional Share, You will need to affirmatively sell that Fractional Share if You wish to sell Your entire position. Conversely, if You own only a Fractional Share that is no longer classified by VBS as an Eligible Security, it will be subject to VBS's Auto-Liquidation processes.",
        "title": "Suspending Auto-Liquidation"
      },
      "automaticETFInvestmentTOU": {
        "body": "The following additional AIP Terms apply to the Automatic ETF Investment Plan Service (the \"AIP Service\") only.  To the extent that these AIP Terms are silent on any subject, the terms of the VBS Agreements govern. In the event of a conflict between the AIP Terms and the terms of the VBS Agreements with respect to the subject matter hereof, the AIP Terms shall control.",
        "title": "Automatic ETF Investment Plan Service Terms of Use (\"AIP Terms\")"
      },
      "automaticETFOverview": {
        "body1": "The AIP Service allows You to set up a recurring Automatic ETF Investment Plan (\"Plan\") to buy Eligible Securities through Dollar Trades, at a minimum of $1.00, up to a maximum aggregate value of $250,000.00 across all Your Eligible Securities for Your entire Plan. For example, if You wanted to invest $1,000.00 in Vanguard’s Total Stock Market Index Fund ETF (symbol: VTI) and $2,000.00 in Vanguard’s S&P 500 Index Fund ETF (symbol: VOO) on an automatic basis the total aggregate value across all Your Eligible Securities being set up is less than $250,000.00, which would be allowed under the AIP Service.",
        "body2": "When You set up a Plan as Dollar Trades under the AIP Service, You will likely end up owning a Fractional Share, or a number of whole shares and a Fractional Share for the Eligible Securities You purchase. As described below, Fractional Shares present unique risks, and have certain limitations; they also may have different rights from a whole share of the same Security.  Fractional Share Ownership acquired through this AIP Service is also subject to VBS’ Dollar-Based Trading Terms of Use and requirements as described above.",
        "title": "Overview: Automatic ETF Investment Plan Service"
      },
      "eligibleAccountsAIP": {
        "body1": "The AIP Service is available only to self-directed clients with a VBS brokerage Account that are deemed eligible for the AIP Service, as determined by VBS in its sole discretion. If Your Account is currently enrolled or in the future is enrolled in a Vanguard Advice Offer (e.g., Personal Advisory Services, Digital Advice, et al.), the Account is not or will no longer be eligible for the AIP Service. ",
        "body2": "The AIP Service is also available to Margin Accounts and these orders are also subject to VBS’ margin policies and requirements as defined in the Vanguard Brokerage Margin Account Agreement and other applicable documents.",
        "body3": "VBS reserves the right to add, delete, or amend the type of Accounts that are eligible for the AIP Service at any time.",
        "title": "Eligible Accounts"
      },
      "eligibleSecuritiesAIP": {
        "body": "At this time, only ETFs issued by VGI are eligible for Dollar Trades (as previously defined, \"Eligible Securities\"). VBS reserves the right to add or delete a Security to the list of Eligible Securities at any time. The inclusion or removal of particular Security as an Eligible Security under the AIP Service is not a recommendation related to any Security, nor is it advice. If VBS revokes a particular Security’s status as an Eligible Security, You will no longer be able to place additional Dollar Trades through Your Plan to purchase that Security. You may, however, continue to place whole share trades to buy and sell such Securities. If VBS no longer classifies a particular Security as an Eligible Security and You hold only a Fractional Share of that Security, it will be subject to VBS’ Auto-Liquidation processes, described above.",
        "title": "Eligible Securities"
      },
      "eligibleOrdersAIP": {
        "body": "At this time, the AIP Service supports only market buy orders. VBS reserves the right to expand the order types supported by the Service (e.g., limit orders, sell orders).",
        "title": "Eligible Orders"
      },
      "planSchedules": {
        "body1": "The AIP Service has specific order scheduling options. These schedules are detailed when you input your selections in the online Plan enrollment process. VBS reserves the right to expand the schedules supported by the AIP Service (e.g., Daily). The same schedule must be applied for all Eligible Securities in Your Plan.",
        "body2": "If your scheduled date falls on a weekend, holiday, and/or non-business day, such order(s) will be created on the prior business day. VBS reserves the right to adjust the schedule(s) set forth above at any time.",
        "body3": "Under the AIP Service, Your Plan will continue to run if you have sufficient balance in Your Settlement Fund, You have not canceled Your Plan, and have not fully liquidated a position in an Eligible Security. If any of the Eligible Securities in Your Plan have a zero share balance, Your entire Plan will be automatically canceled. However, if there is any residual amount (share amount greater than zero) of an Eligible Security in Your Plan resulting from a sale that has not completely settled, the Plan will process as scheduled.",
        "title": "Plan Schedules"
      },
      "tradesNotHeldAIP": {
        "body": "All Dollar Trades placed through the AIP Service will be \"not held\" orders, meaning that You grant VBS temporary and limited time and price discretion to execute Your order(s) on a best-efforts basis on the Plan’s authorized scheduled dates(s). You will not be permitted to enter limit or other conditional orders under the Service. There may be a significant difference in the quoted price for a stock between the time You set up your Plan and the time the order is executed on your scheduled date. You agree that VBS is not responsible for any potential market fluctuations, or alleged trading losses, during this time period. If You do not want Your order to be worked on a \"not held\" basis, do not use the AIP Service.",
        "title": "Trades \"Not Held\""
      },
      "orderAggregationAIP": {
        "body1": "VBS generally will aggregate all Dollar Trade orders for the same Eligible Security placed as an Automatic ETF Investment Plan. Under these circumstances, the aggregated orders will each receive the same order execution price as similarly aggregated Dollar Trades in the same Eligible Security. Aggregated orders will be executed by VBS as agent and will receive the price VBS receives from its execution partner. Your trade confirmation will indicate that VBS acted in \"Agency\" capacity, that is, as agent for the entire trade.",
        "body2": "If VBS, in its discretion, did not aggregate your order for the Eligible Security placed through an Automatic ETF Investment Plan, your order will be executed in accordance with VBS’ Dollar-Based Trading Terms of Use, as described above.",
        "title": "Order Aggregation and Fulfillment"
      },
      "planAmendments": {
        "body1": "Under the AIP Service, if You want to amend your Plan, either by modifying your Eligible Securities, adjusting the investment amounts or changing the schedule before Your next scheduled date, then You will must submit Your amendment request no later than 11:59 pm Eastern Standard Time the day before Your next scheduled date. If You do not cancel or modify Your Plan by this cutoff, then Your current Plan on record will be processed and VBS will not be liable for any related costs or losses.",
        "body2": "You acknowledge and agree that VBS reserves the right to deactivate, terminate, or decline Your Plan at any time and for any reason. You also acknowledge that all Plan amendment and cancellation requests will be processed as soon as practicable, but delays can occur. ",
        "title": "Plan Amendments and Cancelation"
      },
      "accountFunding": {
        "body1": "Purchases made through Your Plan will be paid for by Your Settlement Fund. It is Your responsibility to ensure that Your Settlement Fund has sufficient funds to pay for Your order(s). If You do not have sufficient funds to pay for Your order(s) then your Plan may go unprocessed. You will receive notice if Your Plan goes unprocessed. If Your Plan goes unprocessed for three (3) scheduled dates, We will cancel it.",
        "body2": "In addition, it is Your responsibility to ensure that Your Account does not have any restrictions (e.g clerical or trading). If You have any restriction on Your Account, then Your Plan may go unprocessed.",
        "title": "Account Funding and Restrictions"
      },
      "limitedTradeChannelsAIP": {
        "body": "The AIP Service is only available on VBS' website. As a result, You cannot set up, modify, or cancel Your Plan through any other Alternative Channels that VBS may make available to You. You may, however, place whole share trades via any Alternative Channels that VBS may make available to You.",
        "title": "Limited Trade Channels for Automatic ETF Investment Plan"
      },
      "serviceLimitationAIP": {
        "body": "VBS provides the AIP Service \"as is\": it does not guarantee that the AIP Service will be available to You at a particular time, and reserves the right to suspend, terminate, or decline the AIP Service at any time without prior notice. Access to the AIP Service may be limited or unavailable during periods of peak demand, market volatility, systems upgrades or maintenance, or for other reasons. If the AIP Service is unavailable or delayed at any time, You agree to use Alternative Channels to place Your orders. You also acknowledge and agree that the AIP Service may not be available in some or all of these Alternative Channels. As a result, You will not be able to setup and/or modify an Automatic ETF Investment Plan, and may only execute trades in these Alternative Channels in whole shares only.",
        "title": "Service Limitations/Unavailability"
      },
      "electronicAcceptance": {
        "body": "VBS reserves the right to change these AIP Terms at any time without prior notice. You acknowledge and agree that Your continued enrollment in the AIP Service is valid evidence of Your ongoing consent to be legally bound by these AIP Terms. Your continued enrollment, as well as Your electronic acknowledgement of These AIP Terms when creating or changing Your Plan, is also evidence of Your consent for VBS to act with limited time and price discretion on the standing order instructions You provided when enrolling in the Plan. ",
        "title": "Electronic Acceptance of this Agreement "
      }
    },
    "preferredSecuritiesTab": {
      "creditFirstListItem": "Moody’s and S&P focus on an issuer’s financial condition and credit history.",
      "creditFourthListItem": "Preferred securities rated below-investment-grade are generally considered to carry a greater degree of risk than more highly rated preferred securities.",
      "creditRating": "Credit rating",
      "creditRatingBody": "Many preferred securities are rated by agencies such as Moody’s Investors Service and Standard & Poor’s. Ratings reflect the agencies' assessment of the creditworthiness of the issuer and of the issuer’s ability to pay interest and repay principal in a timely fashion.",
      "creditSecondListItem": "On the Moody’s rating scale, issues rated Baa3 or above are generally considered to be investment-grade, while those rated lower than Baa3 are generally considered to be below-investment-grade.",
      "creditThirdListItem": "On the S&P rating scale, issues rated BBB– or above are generally considered to be investment-grade, while those rated lower than BBB– are generally considered to be below-investment-grade.",
      "disclosure": "Disclosures",
      "disclosureBody": "Issuers disclose information about preferred securities and details of their financial condition in a prospectus filed with the Securities and Exchange Commission. The prospectus, along with other continuing financial disclosures, can be found in the SEC’s",
      "disclosureLink": "http://www.sec.gov/edgar/searchedgar/webusers.htm",
      "disclosureLinkText": "EDGAR database",
      "fees": "Fees",
      "feesBody": "On new preferred securities bought in the primary market, Vanguard may receive a concession from the issuer. If a concession isn’t available, Vanguard reserves the right to charge a commission. See the ",
      "income": "Income",
      "incomeBody": "Dividends are usually paid quarterly and must be paid to owners of preferred securities before common stockholders. If the issuing company defaults, preferred security shareholders are paid after bondholders but before common stockholders.",
      "liquidity": "Liquidity",
      "liquidityBody": "Vanguard does not make a market in preferred securities. If you wish to sell your preferred security, Vanguard can provide access to a secondary market. Liquidity will vary depending on the preferred security’s features, its rating or credit quality, lot size, and other market conditions. Preferred securities sold on the secondary market may be subject to substantial gain or loss.",
      "preferredSecuritiesBodyTop": "Preferred securities combine the features of bonds and stocks. They represent ownership in a corporation like stocks (though generally with no voting rights) but provide periodic dividend payments like bonds. Most preferred securities are issued in $25 denominations.",
      "risk": "Risks",
      "riskFifthListItem": "Preferred securities sold on the secondary market may insure a substantial gain or loss. The secondary market also may be limited.",
      "riskFirstListItem": "Prices of preferred securities can rise or fall depending on interest rates. Interest rate changes have a greater effect on long-term and perpetual preferred securities.",
      "riskFourthListItem": "Certain events can impact a preferred security issuer’s financial situation and ability to make timely payments to shareholders, including economic, political, legal, or regulatory changes and natural disasters. Event risk is unpredictable and can significantly impact preferred security holders since they are paid after bondholders.",
      "riskSecondListItem": "All preferred securities carry the risk that the issuer will default or be unable to make timely payments of interest and principal. Highly rated preferred securities are presumed to have less credit risk than lower-rated preferred securities. Credit risk can significantly impact preferred security holders since they are paid after bondholders.",
      "riskThirdListItem": "Some preferred securities have call provisions that allow the issuer to buy back the securities at a fixed price before the stated maturity date. Issuers typically call preferred securities when interest rates decline. Some preferred securities also have call provisions that allow the issuer to redeem the securities under certain conditions or special events.",
      "taxability": "Taxability",
      "taxabilityFirstListItem": "The dividends on some preferred securities may be taxed at a reduced rate.",
      "taxabilitySecondListItem": "Preferred securities may subject investors to capital gains taxes when sold or redeemed.",
      "taxabilityThirdListItem": "Some issuers can defer dividend payments without forcing the company into bankruptcy. If so, a tax liability may result for the investor on income allocated but not yet received. Investors should consult a tax professional for additional information.",
      "title": "Preferred Securities",
      "typeFirstListItem": "Cumulative preferred securities are securities on which dividends, if deferred, will accrue and must be paid before the companies' common stock dividends. Accrued dividends must be paid before maturity or redemption.",
      "types": "Types",
      "typeSecondListItem": "Noncumulative preferred securities are securities on which dividends, if deferred, will not accrue and may never be paid.",
      "typeThirdListItem": "Trust-preferred securities are hybrids consisting of a preferred security issued by a trust set up by the issuer and funded by the debt securities of the issuer. The company issues a preferred security that pays dividends based on the interest the trust receives from the debt securities. The preferred security matures at the same time as the debt securities that the trust holds."
    },
    "specializedTab": {
      "etf": "Exchange-traded funds",
      "etfBody": "All exchange-traded funds (ETFs) are subject to trading risks similar to those of stocks. ETFs can entail market, sector, or industry risks similar to direct stock ownership. Generally, an ETF is a type of investment company whose investment objective is to achieve the same or a similar return as a particular market index or stated objective.",
      "etn": "Exchange-traded notes",
      "etnBody": "ETF shares can be bought and sold only through a broker (who will charge a commission) and cannot be redeemed with the issuing fund. The market price of ETF shares may be more or less than net asset value.",
      "etnDescription": "On January 22, 2019, Vanguard stopped accepting purchases in leveraged or inverse mutual funds, ETFs (exchange-traded funds), or ETNs (exchange-traded notes). If you already own these investments, you can continue to hold them or choose to sell them commission free. You can also transfer them in kind from or to other institutions.",
      "etnEnd": "Past performance is no guarantee of future results.",
      "specializedBodyTop": "Important information about leveraged, inverse, and commodity exchange-traded products",
      "title": "Specialized ETFs and ETNs"
    },
    "title": "Trade help",
    "tradingPoliciesTab": {
      "cancellingOrder": "Canceling or changing an order",
      "cancellingOrderFirstListItem": "All open orders remain in effect until confirmed as executed or canceled by the market center holding your order.",
      "cancellingOrderSecondListItem": "You will be responsible for settling any transaction that results from your failure to cancel or change an open order.",
      "cancellingOrderThirdListItem": "Placing a new or identical order does not cancel an existing open order on the same security.",
      "commissionLink": "https://investor.vanguard.com/investing/transaction-fees-commissions/etfs",
      "commissionLinkText": "Vanguard commission and fee schedules ",
      "disclaimer": "Disclaimers",
      "disclaimerFirstListItem": "Your liability or losses aren’t limited to the available balance in your cash account.",
      "disclaimerFourthListItem": "To learn more about important terms and conditions that apply to your Vanguard Brokerage account, please read the",
      "disclaimerSecondListItem": "Vanguard doesn’t encourage day trading. The high transaction costs and risks associated with this type of strategy may limit investment returns and cause a substantial, if not total, loss of your investment. If you engage in day trading, you do so at your own risk.",
      "disclaimerThirdListItem": "Vanguard reserves the right to revoke your trading privileges at any time",
      "marketOpening": "Market opening",
      "marketOpeningBody": "When placing an order before the listing market opens, keep in mind that opening prices may be significantly higher or lower than the previous trading day’s closing price. Consider this when determining whether you have sufficient assets to cover your order.",
      "marketOpeningDescription": "<strong>Not all securities begin trading at 9:30 a.m., Eastern time.</strong> For most exchange-listed securities, Vanguard will only execute orders once the exchange that lists the security opens for trading. Although exchanges begin trading equities at 9:30 a.m., Eastern time, some securities may not open until after 9:30 a.m. Trades executed before the listing exchange opens are considered pre-market trades. Please be aware that Vanguard doesn’t participate in pre-market trading. In addition, the opening price shown on vanguard.com will always be the consolidated opening price, which may not be the same as the security’s listing exchange opening price.",
      "marketOpeningEnd": "In the majority of cases, orders received before 9:27:30 a.m., Eastern time, will receive the primary listing exchange opening price. Any request to change or cancel an open order between 9:25:00 a.m. and the primary listing exchange open is on a best-efforts basis. The opening process varies by listing exchange for the security being traded.",
      "placingOrder": "Placing an order",
      "placingOrderEnd": "for full details. These commissions and fee schedules are subject to change.",
      "placingOrderFifthListItem": "A separate commission is charged for each order to buy or sell a security. Orders that are changed and executed in multiple trades on the same day are charged separate commissions. See the ",
      "placingOrderFirstListItem": "Vanguard reserves the right to review orders submitted electronically and to subsequently approve or reject the order in accordance with Vanguard’s trading policies. As a result, it’s possible that the execution of your order will be delayed or that your order will not be accepted.",
      "placingOrderFourthListItem": "Before you place a sell order, the security should be in good order in your account. You may be able to sell shares not yet credited to your account on or after the ex-dividend date of a split by calling us.",
      "placingOrderSecondListItem": "If you receive an error message when submitting an order and aren’t sure it was received by Vanguard, check your <strong>Order status</strong> on vanguard.com to confirm your order. If you’re still uncertain about the status of an order, call us.",
      "placingOrderThirddListItem": "On occasion, halted or delayed trading on a security may occur. If you placed an order for a security for which trading has been halted, your trade may not be executed until the halt has been lifted. When trading is re-established the price may vary from the quotes available at the time you placed your trade.",
      "specificSecurity": "Specific security policies",
      "specificSecurityFirstListItem": "<strong>Orders for securities that trade without a ticker symbol</strong> can’t be placed online. Please call us to place an order.",
      "specificSecuritySecondListItem": "<strong>Orders for initial public offerings (IPOs)</strong> can be placed online on the morning that the security is scheduled to trade on the secondary market. Vanguard doesn’t accept market orders for IPOs or exchange-traded product (ETP) launches before each security’s opening in the secondary market.",
      "specificSecurityThirdListItem": "<strong>Orders for some over-the-counter (OTC) market and bulletin board securities</strong> can also be placed online. You’ll be shown the time and date of the last trade while you’re entering your order. However, the last trade may not be indicative of the current market price. Only limit orders may be placed for OTC market and bulletin board securities. To obtain real-time bid/offer prices, call us.",
      "title": "Trading Policies",
      "tradingPoliciesBodyTop": "This information is for educational purposes only. If you have questions, please call us. Remember: You are responsible for all orders that you place.",
      "vbaLink": "https://personal1.vanguard.com/pdf/vbsaacp.pdf",
      "vbaLinkText": "Vanguard Brokerage Agreement"
    },
    "tradingRisksTab": {
      "dayTrading": "Day trading",
      "dayTradingBody": "Vanguard does not encourage day trading. The high transaction costs and risks associated with this type of strategy may limit investment returns and cause a substantial, if not total, loss of your investment. If you engage in day trading, you do so at your own risk. Vanguard reserves the right to revoke your trading privileges at any time.",
      "links": {
        "finra": "http://www.finra.org/",
        "finraText": "FINRA.ORG",
        "secGov": "https://www.sec.gov",
        "secGovText": "SEC.gov",
        "termsAndConditions": "https://personal.vanguard.com/us/content/SiteWide/LglDocs/SWLglTermsConditionsContent.jsp",
        "termsAndConditionsText": "Terms and Conditions of Use"
      },
      "liquidity": "Liquidity and volatility",
      "liquidityFirstListItem": "Lower liquidity means fewer shares of a security are being traded, which may result in larger spreads between bid and ask prices and larger swings in execution prices.",
      "liquiditySecondListItem": "Generally, stocks and ETFs that are less liquid will tend to be more volatile. Low liquidity and high volatility may prevent an order from being executed in whole or in part, or from receiving a favorable execution price.",
      "mitigate": "Mitigating trading risks",
      "mitigateBody": "<strong>Large block transactions, especially those in illiquid or thinly traded securities, may be suited for special handling by Vanguard’s Block Desk. Vanguard block traders can work with execution partners to obtain favorable execution and minimize the market impact of a large order.</strong>",
      "mitigateEnd": "To learn more about important terms and conditions that apply to your account, please review the ",
      "mitigateFirstListItem": "Placing limit orders may reduce your risk of trade executions at unexpected prices. Limit orders allow you to place an upper or lower limit on the amount you’re willing to pay to purchase or accept for a sale of a stock or an ETF. However, there’s no guarantee that a limit order will be executed.",
      "mitigateSecondListItem": "Market orders, by contrast, are executed at the best price available when your order reaches the market center, assuming that the security is actively traded and market conditions permit.  You should exercise caution in placing market orders. Keep in mind that the execution price for a market order is not guaranteed; changes in the market can have significant impact on the price you receive.  If you place a market order when the market is closed, remember that securities can open at a price much higher or lower than the previous close.",
      "riskBody1": "For additional information on risks associated with trading online, refer to the resources on",
      "riskBody2": "You should also review our",
      "riskBody3": "for information on the limits of our liability for performance, errors, interruptions, deletions, defects or delays in online trading operations, or for unauthorized activity on your account.",
      "riskFirstListItem": "Access to Vanguard’s electronic services is not guaranteed, and may be limited or unavailable during periods of peak demand, market volatility, systems upgrades or maintenance, or for other reasons. If the electronic services are unavailable or delayed at any time, you may need to use alternative means to place your orders, such as calling a Vanguard client representative.",
      "risks": "Risks of electronic trading",
      "riskSecondListItem": "If you trade online through Vanguard’s website, you assume the risk that the internet and other online communication devices may not perform as intended, despite our efforts, your efforts, and those of your internet service provider. Delays or failures due to a high volume of communications or computer system problems experienced by Vanguard’s trading partners or exchanges may prevent of delay the execution of your order.  Vanguard is not held liable for missed executions in these cases.",
      "title": "Trading Risks",
      "tradingRisksBodyTop": "All investments are subject to risk. It’s important to consider the risks associated with trading stocks and ETFs. Please keep in mind that your liability or losses are not limited to the available balance in your account.",
      "volatileMarket": "Volatile market conditions",
      "volatileMarketFirstListItem": "Volatile markets, whether in a particular security or the market overall, are characterized by high trading volumes, the inability of market makers and specialists to quickly match buy and sell orders, and the imbalance of trade orders in one direction.",
      "volatileMarketFourthListItem": "In volatile markets, you may not be able to place orders electronically and may have difficulty reaching our representatives by phone.",
      "volatileMarketSecondListItem": "Under these conditions, individual stock and ETF prices can change quickly and dramatically. As a result, real-time quotes can lag behind the actual movements of the market. The risk of market losses increases during periods of high volatility.",
      "volatileMarketThirdListItem": "High trading volumes may cause delays in execution, and your order may execute at a price significantly different from quotes displayed at the time your order was entered."
    }
  },
  "tradeOrderForm": "Trade order form",
  "validation": {
    "accountSelectionRequired": "Account selection is required.",
    "dollars": {
      "amountLimit": "This amount exceeds your current holdings.",
      "decimals": "Price should include two decimal places.",
      "empty": "Enter a dollar amount.",
      "max": "Invalid amount. Dollar-based trades have a $5M maximum.",
      "min": "Invalid amount. Dollar-based trades have a $1 minimum.",
      "sharesLimit": "The number of estimated shares must be less than 100,000,000."
    },
    "duration": "Select a duration",
    "errorSummary": "Before you can proceed with your trade, you’ll need to resolve the following field error(s):",
    "eveningTrading": {
      "tickerHasNoEveningTrading": "The ticker symbol you entered isn't available during extended-hours trading."
    },
    "invalidChars": "Invalid character(s) entered.",
    "limitPrice": "Enter a limit price",
    "numeric": "Enter only numeric characters.",
    "orderType": "Select an order type.",
    "priceDecimals": "Price should include two decimals.",
    "priceMinMax": "Price must be greater than $0.00 and less than $1,000,000,000.00.",
    "required": "Required",
    "securityAccountType": "Select Margin Sub-Type",
    "shares": {
      "empty": "Enter a whole number.",
      "holdLimit": "This number exceeds your current amount of shares.",
      "integer": "Enter amount in whole shares.",
      "max": "The number of shares must be less than 100,000,000.",
      "min": "The number of shares must be at least 1.",
      "sellAllNoSharesHeld": "The selected account does not include this security.",
      "sellAllNoSymbolOrAccount": "Invalid amount."
    },
    "stopPrice": "Enter a stop price",
    "subDollarDecimals": "Prices under $1 are limited to four decimals.",
    "symbol": {
      "empty": "Enter a valid symbol.",
      "icon": "warning",
      "ipoInlineErrorMessage": "This security is a recent initial public offering (IPO) and may not be available for purchase.",
      "maxlength": "Symbol must be 10 characters or less.",
      "notFound": "The ticker symbol you entered was not recognized. Please check your symbol and try again.",
      "tryKeywordSearch": "Symbol not found. Try searching by keyword using the link below."
    },
    "transaction": "Select a transaction."
  },
  "whatHappensNext": {
    "buy": "Trades settle one business day after your order is executed. Be sure your settlement fund contains the necessary funds to complete the trade.",
    "coverYourTrade": {
      "part1": "You can",
      "part2": "move money to your settlement fund",
      "part3": "to cover your trade."
    },
    "sell": "Trades settle one business day after your order is executed. Unless you are selling short, sale proceeds will typically appear in your settlement fund on the second day following execution.",
    "sellAll": "If you’re selling your entire position, any fractional shares liquidate automatically on the settlement date, at no additional cost to you. The proceeds from liquidating fractional shares sweep into your settlement fund one business day after the settlement date. Your fractional shares are sold at the same price as your whole shares.",
    "transactionConfirmation": "We’ll let you know when the transaction confirmation is available.",
    "transactionConfirmationEditCostBasis": "Your updated cost basis method will be applied to this executed order.",
    "openOrderStatement": {
      "part1": "To check the status of any pending orders, select the \"open orders\" tab at the top of the page.",
      "part2": "Prior to settlement of the order, it can be",
      "part3": "changed",
      "part4": "or",
      "part5": "canceled.",
      "part6": "Market orders are subject to immediate execution during market hours. In such instances, it may not be possible to change or cancel."
    }
  }
}
