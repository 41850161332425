@import '../variables';
@import './text-crop';

.#{$namespace}-text {

  color: $default-color;
  &-6xl {
    @include c11n-font-rules('regular', '6xl');
    &-headline {
      @include c11n-font-rules('heavy', '6xl');
      &--crop {
        @include c11n-font-rules('heavy', '6xl');
        @include c11n-text-crop-6xl-headline();
      }
    }
    &-display {
      @include c11n-font-rules('display', '6xl');
      &--crop {
        @include c11n-font-rules('display', '6xl');
        @include c11n-text-crop-6xl-display();
      }
    }
    &--crop {
      @include c11n-font-rules('regular', '6xl');
      @include c11n-text-crop-6xl();
    }
  }
  &-5xl {
    @include c11n-font-rules('regular', '5xl');
    &-headline {
      @include c11n-font-rules('heavy', '5xl');
      &--crop {
        @include c11n-font-rules('heavy', '5xl');
        @include c11n-text-crop-5xl();
      }
    }
    &-display {
      @include c11n-font-rules('display', '5xl');
      &--crop {
        @include c11n-font-rules('display', '5xl');
        @include c11n-text-crop-5xl-display();
      }
    }
    &--crop {
      @include c11n-font-rules('regular', '5xl');
      @include c11n-text-crop-5xl();
    }
  }
  &-4xl {
    @include c11n-font-rules('regular', '4xl');
    &-headline {
      @include c11n-font-rules('heavy', '4xl');
      &--crop {
        @include c11n-font-rules('heavy', '4xl');
        @include c11n-text-crop-4xl-headline();
      }
    }
    &-display {
      @include c11n-font-rules('display', '4xl');
      &--crop {
        @include c11n-font-rules('display', '4xl');
        @include c11n-text-crop-4xl-display();
      }
    }
    &--crop {
      @include c11n-font-rules('regular', '4xl');
      @include c11n-text-crop-4xl();
    }
  }
  &-3xl {
    @include c11n-font-rules('regular', '3xl');
    &-headline {
      @include c11n-font-rules('heavy', '3xl');
      &--crop {
        @include c11n-font-rules('heavy', '3xl');
        @include c11n-text-crop-3xl-headline();
      }
    }
    &-display {
      @include c11n-font-rules('display', '3xl');
      &--crop {
        @include c11n-font-rules('display', '3xl');
        @include c11n-text-crop-3xl-display();
      }
    }
    &--crop {
      @include c11n-font-rules('regular', '3xl');
      @include c11n-text-crop-3xl();
    }
  }
  &-2xl {
    @include c11n-font-rules('regular', '2xl');
    &-headline {
      @include c11n-font-rules('heavy', '2xl');
      &--crop {
        @include c11n-font-rules('heavy', '2xl');
        @include c11n-text-crop-2xl();
      }
    }
    &-display {
      @include c11n-font-rules('display', '2xl');
      &--crop {
        @include c11n-font-rules('display', '2xl');
        @include c11n-text-crop-2xl-display();
      }
    }
    &--crop {
      @include c11n-font-rules('regular', '2xl');
      @include c11n-text-crop-2xl();
    }
  }
  &-xl {
    @include c11n-font-rules('regular', 'xl');
    &-headline {
      @include c11n-font-rules('heavy', 'xl');
      &--crop {
        @include c11n-font-rules('heavy', 'xl');
        @include c11n-text-crop-xl-headline();
      }
    }
    &-display {
      @include c11n-font-rules('display', 'xl');
      &--crop {
        @include c11n-font-rules('display', 'xl');
        @include c11n-text-crop-xl-display();
      }
    }
    &--crop {
      @include c11n-font-rules('regular', 'xl');
      @include c11n-text-crop-xl();
    }
  }
  &-lg {
    @include c11n-font-rules('regular', 'lg');
    &-bold {
      @include c11n-font-rules('bold', 'lg');
      &--crop {
        @include c11n-font-rules('bold', 'lg');
        @include c11n-text-crop-lg-bold();
      }
    }
    &-headline {
      @include c11n-font-rules('heavy', 'lg');
      &--crop {
        @include c11n-font-rules('heavy', 'lg');
        @include c11n-text-crop-lg();
      }
    }
    &--crop {
      @include c11n-font-rules('regular', 'lg');
      @include c11n-text-crop-lg();
    }
  }
  &-md {
    @include c11n-font-rules();
    &-bold {
      @include c11n-font-rules('bold', 'md');
      &--crop {
        @include c11n-font-rules('bold', 'md');
        @include c11n-text-crop-md();
      }
    }
    &-article {
      @include c11n-font-rules('article', 'md');
      &--crop {
        @include c11n-font-rules('article', 'md');
        @include c11n-text-crop-md-article();
      }
    }
    &-table {
      @include c11n-font-rules('table', 'md');
      &--crop {
        @include c11n-font-rules('table', 'md');
        @include c11n-text-crop-md();
      }
    }
    &-table-narrow {
      @include c11n-font-rules('narrow', 'md');
      &--crop {
        @include c11n-font-rules('narrow', 'md');
        @include c11n-text-crop-md();
      }
    }
    &--crop {
      @include c11n-font-rules();
      @include c11n-text-crop-md();
    }
  }
  &-sm {
    @include c11n-font-rules('regular', 'sm');
    &-bold {
      @include c11n-font-rules('bold', 'sm');
      &--crop {
        @include c11n-font-rules('bold', 'sm');
        @include c11n-text-crop-sm();
      }
    }
    &-table {
      @include c11n-font-rules('table', 'sm');
      &--crop {
        @include c11n-font-rules('table', 'sm');
        @include c11n-text-crop-sm();
      }
    }
    &-table-narrow {
      @include c11n-font-rules('narrow', 'sm');
      &--crop {
        @include c11n-font-rules('table', 'sm');
        @include c11n-text-crop-sm();
      }
    }
    &--crop {
      @include c11n-font-rules('regular', 'sm');
      @include c11n-text-crop-sm();
    }
  }
  &-xs {
    @include c11n-font-rules('regular', 'xs');
    &--crop {
      @include c11n-font-rules('regular', 'xs');
      @include c11n-text-crop-xs();
    }
  }
}
