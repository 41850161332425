"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FederalWithholdingAmountTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var FederalWithholdingAmountTypeEnum;
(function (FederalWithholdingAmountTypeEnum) {
  FederalWithholdingAmountTypeEnum["DOLLARS"] = "DOLLARS";
  FederalWithholdingAmountTypeEnum["PERCENT"] = "PERCENT";
  FederalWithholdingAmountTypeEnum["DO_NOT_WITHHOLD"] = "DO_NOT_WITHHOLD";
})(FederalWithholdingAmountTypeEnum = exports.FederalWithholdingAmountTypeEnum || (exports.FederalWithholdingAmountTypeEnum = {}));
