<ng-container
  *ngIf="{
    selectedAccount: (selectedAccount$ | async),
    etfs: (etfs$ | async),
    stocks: (stocks$ | async),
    options: (options$ | async),
    mutualFunds: (mutualFunds$ | async),
    CDs: (CDs$ | async),
    bonds: (bonds$ | async),
    accountIsMargin: (accountIsMargin$ | async)
  } as view"
>
  <div class="container-fluid c11n-space-stack-5x c11n-space--force" data-testid="holdings-container">
    <div class="row">
      <twe-accounts-control class="col-12" [showAccountDetails]="true"></twe-accounts-control>
      <twe-refresh-button
        *ngIf="view.selectedAccount"
        class="c11n-space-stack-3x c11n-space--force col-12 justify-content-start"
        [isLoading]="isRefreshingAccounts"
        [hasFailed]="hasCriticalHoldingError"
        (refresh)="refreshHoldingsForAccount()"
        [title]="content.loading.refreshHoldings"
        [isWrap]="false"
        [ariaLabel]="'Account holdings, as of ' + (view.selectedAccount?.asOfDate | tweDate)"
      >
        <tcx-timestamp
          [showTime]="true"
          [prefixText]="content.labels.asOf"
          [size]="timestampSizeEnum.XS"
          [date]="view.selectedAccount?.asOfDate | tweDate"
        ></tcx-timestamp>
      </twe-refresh-button>
    </div>

    <twe-service-unavailable-msg *ngIf="!view.selectedAccount && !hasAccountRetrievalError">
      <p class="c11n-space-stack-1x c11n-space--force">{{ content.labels.selectAccountForHoldings }}</p>
    </twe-service-unavailable-msg>

    <twe-accounts-error (hasAccountRetrievalErrorEmitter)="setHasAccountRetrievalError($event)"></twe-accounts-error>

    <twe-generic-error-modal></twe-generic-error-modal>

    <div *ngIf="view.selectedAccount" class="twe-scrollable">
      <div class="c11n-space-stack-3x">
        <div *ngIf="hasCriticalHoldingError" class="container-fluid holdings-unavailable">
          <div class="text-center">
            <img src="{{ windowService.assetsPath }}assets/svg/load-fail.svg" alt="" class="c11n-space-stack-2_5x" />
            <p class="twe-c11n-color-ink-400 c11n-space-stack-1x">
              {{ content.holdings.criticalHoldingError.body }}
            </p>
            <p>
              <a c11n-link [routerLink]="TRADE_PATH">
                {{ content.holdings.criticalHoldingError.linkText }}
              </a>
            </p>
          </div>
        </div>

        <div *ngIf="!hasCriticalHoldingError">
          <twe-service-unavailable-msg
            class="holdings-unavailable"
            *ngIf="view.selectedAccount && !view.selectedAccount?.holdings?.length && !isRefreshingAccounts"
          >
            <p class="twe-c11n-color-ink-400 c11n-space-stack-1x" data-testid="noHoldingsText">{{
              content.holdings.noHoldings.noHoldingsText
            }}</p>
            <p>
              <a c11n-link [routerLink]="TRADE_PATH">{{ content.holdings.noHoldings.backToTrading }}</a>
            </p>
          </twe-service-unavailable-msg>

          <ng-container
            *ngTemplateOutlet="accountHoldings; context: { $implicit: view.etfs, tableCaption: content.labels.etfs }"
          >
          </ng-container>

          <ng-container
            *ngTemplateOutlet="
              accountHoldings;
              context: { $implicit: view.stocks, tableCaption: content.labels.stocks }
            "
          >
          </ng-container>

          <ng-container
            *ngTemplateOutlet="
              accountHoldings;
              context: { $implicit: view.options, tableCaption: content.labels.options }
            "
          >
          </ng-container>

          <ng-container
            *ngTemplateOutlet="
              accountHoldings;
              context: { $implicit: view.mutualFunds, tableCaption: content.labels.mutualFunds }
            "
          >
          </ng-container>

          <ng-container
            *ngTemplateOutlet="accountHoldings; context: { $implicit: view.cds, tableCaption: content.labels.cds }"
          >
          </ng-container>

          <ng-container
            *ngTemplateOutlet="accountHoldings; context: { $implicit: view.bonds, tableCaption: content.labels.bonds }"
          >
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <ng-template #accountHoldings let-holdings let-tableCaption="tableCaption">
    <div class="c11n-space-stack-4x" *ngIf="holdings?.length">
      <div class="twe-card">
        <twe-card-cap>
          <h3 class="text-uppercase c11n-text-xs" role="rowheader">{{ tableCaption }}</h3>
        </twe-card-cap>

        <div class="twe-card-body">
          <div role="table" class="tds-table tds-table-theme-holdings" [attr.aria-label]="tableCaption">
            <div role="rowgroup" class="visually-hidden">
              <div role="row">
                <span role="columnheader">{{
                  tableCaption === content.labels.bonds ? '' : content.labels.symbol
                }}</span>
                <span role="columnheader">{{ content.labels.productName }}</span>
                <span
                  role="columnheader"
                  class="c11n-text-xs c11n-space-stack-0_5x text-uppercase"
                  id="holding-header-shares-{{ tableCaption }}"
                  >{{ content.labels.shares }}</span
                >
              </div>
            </div>
            <div role="rowgroup">
              <div role="row" class="tds-table__row" *ngFor="let holding of holdings">
                <div role="cell" class="tds-table__data">
                  <span *ngIf="tableCaption === content.labels.bonds" class="mb-0 c11n-text-xs text-uppercase">{{
                    holding.raw.cusip
                  }}</span>
                  <span *ngIf="tableCaption !== content.labels.bonds">{{ holding.raw.ticker }}</span>
                </div>
                <div role="cell" class="tds-table__data"
                  >{{ holding.name }}
                  {{ view.accountIsMargin ? '(' + holding.raw.brokeragePosition.accountType + ')' : '' }}
                </div>
                <div role="cell" class="tds-table__data">
                  <div
                    class="c11n-text-xs c11n-space-stack-0_5x text-uppercase"
                    [attr.aria-labelledby]="'holding-header-shares-' + tableCaption"
                    aria-hidden="true"
                    >{{ content.labels.shares }}</div
                  >
                  <div class="twe-text-bold c11n-text-sm">{{ holding.raw.quantity | number : '1.4-4' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
