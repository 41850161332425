@import '@vg-constellation/angular-17/styles/spacing';
@import '@vg-constellation/angular-17/styles/breakpoints';

#basicModalContent-costBasisModal {
  form > div.mb-4 {
    &:first-child {
      margin-bottom: 0 !important;
    }
  }

  .container-radio-option-paragraph {
    &:first-child {
      width: 30%;
    }

    width: 70%;
  }

  // to match the banner spacing, we need to create a box type padding for the checkbox text
  // so there is very little jitter
  .spacer {
    padding: rem(24);
    @include media-breakpoint-up(sm) {
      padding: rem(12);
    }
  }
}
