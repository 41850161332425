import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { AdobeAnalyticsService, GatekeeperService } from '@app/core/services';
import { TimeoutErrorModalComponent } from '@app/etfs-equities/components';
import { OrderEnums, TradeHelpIDs } from '@app/etfs-equities/enums';
import { Account, JsonContent, Order } from '@app/etfs-equities/models';
import { OrderService, WindowService } from '@app/etfs-equities/services';
import {
  createUpdateSelectedLotsAction,
  selectIsEditCostBasisState,
  selectIsScreenXSmall,
  selectIsSellingAllHeldShares,
  selectOrder,
  selectOrderEveningDuration,
  selectSelectedAccount,
  selectSelectedLots,
  selectShouldDisplayProspectusLink,
  TayneState,
} from '@app/etfs-equities/store';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { AcceptedRulesNextStep } from '@etfs-equities/enums/triggered-rule.enums';
import { EditService } from '@etfs-equities/services/edit/edit.service';
import { SubmitService } from '@etfs-equities/services/submit/submit.service';
import { select, Store } from '@ngrx/store';
import { CostBasisMethod } from '@vanguard/trade-ui-components-lib-ng-17';
import { Observable, Subject } from 'rxjs';
import { filter, first, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { FocusOnElementUtility } from '../../../shared/utilities/focus-element/focus-element.utility';

@Component({
  selector: 'twe-preview',
  templateUrl: './preview-page.component.html',
  styleUrls: ['./preview-page.component.scss'],
})
export class PreviewPageComponent implements OnInit, OnDestroy {
  AcceptedRulesNextStep = AcceptedRulesNextStep;

  // Decorators...

  @ViewChild('timeoutErrorModal')
  timeoutErrorModal: TimeoutErrorModalComponent;

  // Public observables/subjects...

  unsubscribe$: Subject<void> = new Subject();
  shouldDisplayProspectusLink$: Observable<boolean>;
  order$: Observable<Order.Order>;
  selectedAccount$: Observable<Account.Account>;
  isSellingAllHeldShares$: Observable<boolean>;
  dollarBasedTradesEnabled$: Observable<boolean>;
  isEditCostBasis$: Observable<boolean>;
  isScreenXSmall$: Observable<boolean>;
  isEveningOrder$: Observable<boolean>;

  // Public variables...

  content: JsonContent = content;
  secureSiteUrls = environment.secureSiteUrls;

  transactionTypes = OrderEnums.TransactionTypes;
  amountTypes = OrderEnums.AmountTypes;
  tradeHelpDollarBasedTradingTab = TradeHelpIDs.DOLLAR_BASED_TRADES;
  isBeacon = false;

  /*
  |=====================================================================
  | Lifecycle
  |=====================================================================
  */

  // eslint-disable-next-line max-params
  constructor(
    private readonly adobeService: AdobeAnalyticsService,
    public orderService: OrderService,
    private readonly store: Store<TayneState>,
    private readonly editService: EditService,
    public readonly submitService: SubmitService,
    private readonly windowService: WindowService,
    public readonly loadingService: LoadingService,
    private readonly gatekeeperService: GatekeeperService
  ) {}

  ngOnInit() {
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.shouldDisplayProspectusLink$ = this.store.pipe(select(selectShouldDisplayProspectusLink));
    this.order$ = this.store.pipe(select(selectOrder));
    this.isSellingAllHeldShares$ = this.store.pipe(select(selectIsSellingAllHeldShares));
    this.isEditCostBasis$ = this.store.pipe(select(selectIsEditCostBasisState));
    this.isScreenXSmall$ = this.store.pipe(select(selectIsScreenXSmall));
    this.isEveningOrder$ = this.store.pipe(select(selectOrderEveningDuration));

    this.dollarBasedTradesEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_DOLLAR_BASED_TRADES
    );

    this.isBeacon = this.windowService.getIsBeacon();

    this.addSpecIdLotsToOrder();
    FocusOnElementUtility.focus('h1-sr-only');

    this.orderService.httpError$
      .pipe(
        tap(() => {
          if (this.orderService.hasSubmitTimeoutError) {
            this.timeoutErrorModal.modal.openModalDialog();
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /*
  |=====================================================================
  | View Helpers
  |=====================================================================
  */

  submit() {
    this.submitService.submitOrder$.next();
  }

  edit(isEditCostBasis = false) {
    if (!isEditCostBasis) {
      this.adobeService.sendAdobeLaunchProcess('Order Review: Edit Order');
    }
    this.editService.editOrder$.next();
  }

  /*
  |=====================================================================
  | Private Helpers
  |=====================================================================
  */

  // If the client is placing a sell using Spec ID, add the selected lots to the order here.
  // This is a "path of least resistance" implementation to support an edge case where a
  // client uses the browser forward button to navigate from lot selection to preview.
  // Per RTLTL-2174, treat a forward button click as if the client clicked preview.
  private addSpecIdLotsToOrder() {
    this.order$
      .pipe(
        first(),
        filter(
          (order) =>
            order &&
            (order.transactionType === OrderEnums.TransactionTypes.SELL ||
              order.transactionType === OrderEnums.TransactionTypes.BUY_TO_COVER) &&
            order.costBasisMethod === CostBasisMethod.SPEC_ID
        ),
        withLatestFrom(this.store.pipe(select(selectSelectedLots))),
        tap(([_order, selectedLots]) => this.store.dispatch(createUpdateSelectedLotsAction(selectedLots)))
      )
      .subscribe();
  }
}
