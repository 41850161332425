import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { Account, CostBasis } from '@app/etfs-equities/models';
import { Holding } from '@app/etfs-equities/models/account.model';
import { TradeTicketService } from '@app/etfs-equities/services';
import { CostBasisService } from '@app/etfs-equities/services/cost-basis/cost-basis.service';
import { CostBasisForSelectedAccountResponse } from '@etfs-equities/models/cost-basis.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CostBasisMethod } from '@vanguard/trade-ui-components-lib-ng-17';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
  AccountActionTypes,
  CostBasisActionTypes,
  createAvailableMethodsLoadedAction,
  createClearLoadAvailableMethodsErrorAction,
  createClearLoadLotsErrorAction,
  createLoadAvailableMethodsErrorAction,
  createLoadAvailableMethodsSuccessAction,
  createLoadCostBasisForAccountErrorAction,
  createLoadCostBasisForAccountSuccessAction,
  createLoadLotsErrorAction,
  createLoadLotsSuccessAction,
  createNoopAction,
  createSetPreselectedCostbasisMethodAction,
  createSubmitOrderErrorAction,
  createSubmitOrderSuccessAction,
  LoadAvailableMethodsAction,
  LoadAvailableMethodsErrorAction,
  LoadAvailableMethodsSuccessAction,
  LoadCostBasisForAccountSuccessAction,
  LoadLotsAction,
  RefreshCostBasisForSelectedAccountAction,
  SelectAccountAction,
  TradeTicketActionTypes,
  UpdateTradeTicketTabLinkAction,
} from '../../actions';
import {
  selectCostBasisEligible,
  selectCostBasisForSelectedAccount,
  selectCostBasisMethodByPosition,
  selectOrder,
  selectPositionForCurrentQuoteBySecurityType,
  selectSelectedAccount,
  selectSetCostBasisMethodForFutureTrades,
  selectTradeMethodsForCurrentPosition,
  selectTradeTicketTabLink,
} from '../../selectors';
import { TayneState } from '../../states';

@Injectable()
export class CostBasisEffects {
  private counter = 0;

  loadLots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostBasisActionTypes.LOAD_LOTS),
      exhaustMap((action: LoadLotsAction) =>
        this.costBasisService
          .fetchLots(
            action.payload.accountId,
            action.payload.positionId,
            action.payload.orderId,
            action.payload.transactionType
          )
          .pipe(
            map((response: CostBasis.LotResponse) => createLoadLotsSuccessAction(response)),
            catchError((error: HttpErrorResponse) => of(createLoadLotsErrorAction(error)))
          )
      )
    )
  );

  loadLotsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostBasisActionTypes.LOAD_LOTS_SUCCESS),
      withLatestFrom(this.store.pipe(select(selectTradeTicketTabLink))),
      tap(([_action, tabLink]) => {
        this.costBasisService.isLoadingLots = false;
        const tradeRoute = this.router.url?.split('?')[0];
        const selectSharesPaths: string[] = [
          CONSTANTS.SELECT_SHARES_PATH,
          CONSTANTS.SELECT_SHARES_EDIT_COST_BASIS_PATH,
          CONSTANTS.SELECT_SHARES_EDIT_EXTENDED_HOURS_PATH,
        ];

        if (selectSharesPaths.includes(tradeRoute)) {
          return;
        }

        let navigatePath: string;

        switch (tradeRoute) {
          case CONSTANTS.EDIT_COST_BASIS_PATH:
            navigatePath = CONSTANTS.SELECT_SHARES_EDIT_COST_BASIS_PATH;
            break;
          case CONSTANTS.EXTENDED_TRADING_PATH:
            navigatePath = CONSTANTS.SELECT_SHARES_EDIT_EXTENDED_HOURS_PATH;
            break;
          default:
            navigatePath = CONSTANTS.SELECT_SHARES_PATH;
        }

        this.router.navigate([navigatePath], { queryParams: tabLink.queryParams });
      }),
      map(() => createClearLoadLotsErrorAction(null))
    )
  );

  loadLotsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CostBasisActionTypes.LOAD_LOTS_ERROR),
        tap(() => {
          this.costBasisService.isLoadingLotsFailed = true;
          this.costBasisService.isLoadingLots = false;
        })
      ),
    { dispatch: false }
  );

  loadHoldingsCostBasis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostBasisActionTypes.LOAD_AVAILABLE_METHODS),
      withLatestFrom(
        this.store.pipe(select(selectSelectedAccount)),
        this.store.pipe(select(selectPositionForCurrentQuoteBySecurityType)),
        this.store.pipe(select(selectTradeMethodsForCurrentPosition))
      ),
      exhaustMap(
        ([action, account, holding, tradeMethods]: [
          LoadAvailableMethodsAction,
          Account.Account,
          Holding,
          CostBasisMethod[]
        ]) => {
          if (tradeMethods && holding) {
            this.costBasisService.openSelectionModal();
            return of(createAvailableMethodsLoadedAction());
          }
          return this.costBasisService.fetchTradeMethods(account?.accountId, holding?.positionId).pipe(
            map((response) => createLoadAvailableMethodsSuccessAction(response)),
            catchError((error: HttpErrorResponse) =>
              of(createLoadAvailableMethodsErrorAction(error, { openErrorModal: action.payload.openErrorModal }))
            )
          );
        }
      )
    )
  );

  loadHoldingsCostBasisSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostBasisActionTypes.LOAD_AVAILABLE_METHODS_SUCCESS),
      withLatestFrom(this.store.pipe(select(selectCostBasisMethodByPosition))),
      tap(([_action, _methodCodeByPosition]: [LoadAvailableMethodsSuccessAction, CostBasisMethod]) => {
        this.costBasisService.isLoadingTradeMethods = false;
        this.costBasisService.openSelectionModal();
      }),
      exhaustMap(([action, methodCodeByPosition]: [LoadAvailableMethodsSuccessAction, CostBasisMethod]) => {
        const preSelectedMethod = action.payload.preSelectedMethod;
        if (preSelectedMethod && !methodCodeByPosition) {
          return of(
            createSetPreselectedCostbasisMethodAction(
              action.payload.accountId,
              action.payload.holdingId,
              preSelectedMethod
            )
          );
        }

        return of(createClearLoadAvailableMethodsErrorAction(null));
      })
    )
  );

  loadHoldingsCostBasisError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CostBasisActionTypes.LOAD_AVAILABLE_METHODS_ERROR),
        tap((action: LoadAvailableMethodsErrorAction) => {
          this.costBasisService.isLoadingTradeMethods = false;

          if (action.payload.options.openErrorModal === true) {
            this.costBasisService.openUnavailableModal$.next();
          }
        })
      ),
    { dispatch: false }
  );

  submitEditCostBasis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostBasisActionTypes.SUBMIT_EDIT_COST_BASIS),
      withLatestFrom(
        this.store.pipe(select(selectOrder)),
        this.store.pipe(select(selectSelectedAccount)),
        this.store.pipe(select(selectPositionForCurrentQuoteBySecurityType)),
        this.store.pipe(select(selectSetCostBasisMethodForFutureTrades)),
        this.store.pipe(select(selectCostBasisEligible))
      ),
      map(([_action, order, selectedAccount, holding, updateDefaultCostBasis, isCostBasisEligible]) => ({
        order: {
          ...order,
          costBasisDetailsToChangeCbaMethod: updateDefaultCostBasis
            ? {
                accountId: selectedAccount.accountId,
                holdingId: holding.positionId,
                cbaMethodCode: order.costBasisMethod,
              }
            : null,
          isCostBasisEligible,
        },
      })),

      // Submit the order to the midtier.
      exhaustMap(({ order }) =>
        this.costBasisService.submitEditCostBasis(order).pipe(
          map((orderItem) => createSubmitOrderSuccessAction(orderItem)),
          catchError((error: HttpErrorResponse) => of(createSubmitOrderErrorAction(error)))
        )
      )
    )
  );

  loadCostBasisForSelectedAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActionTypes.SELECT_ACCOUNT, CostBasisActionTypes.REFRESH_COST_BASIS_FOR_SELECTED_ACCOUNT),
      withLatestFrom(
        this.store.pipe(select(selectSelectedAccount)),
        this.store.pipe(select(selectCostBasisForSelectedAccount))
      ),
      // using the selected account ID, now call the service to fetch the cost basis data for that account
      // then dispatch the success action with the response data
      switchMap(
        ([action, selectedAccount, selectedAccountCostBasisData]: [
          SelectAccountAction | RefreshCostBasisForSelectedAccountAction,
          Account.Account,
          CostBasisForSelectedAccountResponse
        ]) => {
          if (
            !selectedAccount ||
            (selectedAccountCostBasisData &&
              action.type !== CostBasisActionTypes.REFRESH_COST_BASIS_FOR_SELECTED_ACCOUNT)
          ) {
            return of(createNoopAction());
          }

          return this.costBasisService.fetchCostBasisForAccount(selectedAccount.accountId).pipe(
            map((response: CostBasis.CostBasisForSelectedAccountResponse) => {
              return createLoadCostBasisForAccountSuccessAction(response);
            }),
            catchError((error: HttpErrorResponse) => {
              return of(createLoadCostBasisForAccountErrorAction(error));
            })
          );
        }
      )
    )
  );

  setIsCostBasisEligible$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AccountActionTypes.SELECT_ACCOUNT,
          CostBasisActionTypes.LOAD_COST_BASIS_FOR_ACCOUNT_SUCCESS,
          // Also listening to the trade ticket tab link update action
          // to ensure the cost basis eligibility flag is set correctly when
          // navigating between pages (e.g. from main page to open orders and back)
          TradeTicketActionTypes.UPDATE_TRADE_TICKET_TAB_LINK,
          TradeTicketActionTypes.UPDATE_EXTENDED_TRADING_TAB_LINK
        ),
        withLatestFrom(this.store.pipe(select(selectCostBasisForSelectedAccount))),
        tap(
          ([_action, costBasisForSelectedAccount]: [
            SelectAccountAction | LoadCostBasisForAccountSuccessAction | UpdateTradeTicketTabLinkAction,
            CostBasisForSelectedAccountResponse
          ]) => {
            // Set the cost basis eligibility flag in the trade ticket form
            // to determine if the cost basis is eligible for the selected account
            this.tradeTicketService.setIsCostBasisEligible(
              costBasisForSelectedAccount?.tradeCostBasisEligible ?? false
            );
          }
        )
      ),
    { dispatch: false }
  );

  constructor(
    private readonly costBasisService: CostBasisService,
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly store: Store<TayneState>,
    private readonly tradeTicketService: TradeTicketService
  ) {}
}
