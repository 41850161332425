"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeOrderCostBasisRequestOrderCostBasisMethodEnum = exports.ChangeOrderCostBasisRequestOrderStatusEnum = exports.ChangeOrderCostBasisRequestTransactionTypeEnum = exports.ChangeOrderCostBasisRequestActionCodeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ChangeOrderCostBasisRequestActionCodeEnum;
(function (ChangeOrderCostBasisRequestActionCodeEnum) {
  ChangeOrderCostBasisRequestActionCodeEnum["DEFAULT"] = "Default";
  ChangeOrderCostBasisRequestActionCodeEnum["ADD"] = "ADD";
  ChangeOrderCostBasisRequestActionCodeEnum["UPDATE"] = "UPDATE";
  ChangeOrderCostBasisRequestActionCodeEnum["CANCEL"] = "CANCEL";
  ChangeOrderCostBasisRequestActionCodeEnum["OTHER"] = "OTHER";
})(ChangeOrderCostBasisRequestActionCodeEnum = exports.ChangeOrderCostBasisRequestActionCodeEnum || (exports.ChangeOrderCostBasisRequestActionCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ChangeOrderCostBasisRequestTransactionTypeEnum;
(function (ChangeOrderCostBasisRequestTransactionTypeEnum) {
  ChangeOrderCostBasisRequestTransactionTypeEnum["DEFAULT"] = "Default";
  ChangeOrderCostBasisRequestTransactionTypeEnum["REDEEM_ALL"] = "REDEEM_ALL";
  ChangeOrderCostBasisRequestTransactionTypeEnum["BUY"] = "BUY";
  ChangeOrderCostBasisRequestTransactionTypeEnum["SELL"] = "SELL";
  ChangeOrderCostBasisRequestTransactionTypeEnum["SHORT"] = "SHORT";
  ChangeOrderCostBasisRequestTransactionTypeEnum["EXCHANGE"] = "EXCHANGE";
  ChangeOrderCostBasisRequestTransactionTypeEnum["BUY_MINUS"] = "BUY_MINUS";
  ChangeOrderCostBasisRequestTransactionTypeEnum["SELL_PLUS"] = "SELL_PLUS";
  ChangeOrderCostBasisRequestTransactionTypeEnum["BUY_TO_COVER"] = "BUY_TO_COVER";
  ChangeOrderCostBasisRequestTransactionTypeEnum["EXCHNAGE_ALL"] = "EXCHNAGE_ALL";
  ChangeOrderCostBasisRequestTransactionTypeEnum["BUY_TO_OPEN"] = "BUY_TO_OPEN";
  ChangeOrderCostBasisRequestTransactionTypeEnum["BUY_TO_CLOSE"] = "BUY_TO_CLOSE";
  ChangeOrderCostBasisRequestTransactionTypeEnum["SELL_TO_OPEN"] = "SELL_TO_OPEN";
  ChangeOrderCostBasisRequestTransactionTypeEnum["SELL_TO_CLOSE"] = "SELL_TO_CLOSE";
})(ChangeOrderCostBasisRequestTransactionTypeEnum = exports.ChangeOrderCostBasisRequestTransactionTypeEnum || (exports.ChangeOrderCostBasisRequestTransactionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ChangeOrderCostBasisRequestOrderStatusEnum;
(function (ChangeOrderCostBasisRequestOrderStatusEnum) {
  ChangeOrderCostBasisRequestOrderStatusEnum["DEFAULT"] = "Default";
  ChangeOrderCostBasisRequestOrderStatusEnum["ENTERED"] = "ENTERED";
  ChangeOrderCostBasisRequestOrderStatusEnum["OPEN"] = "OPEN";
  ChangeOrderCostBasisRequestOrderStatusEnum["CANCELED"] = "CANCELED";
  ChangeOrderCostBasisRequestOrderStatusEnum["EXECUTED"] = "EXECUTED";
  ChangeOrderCostBasisRequestOrderStatusEnum["EXPIRED"] = "EXPIRED";
  ChangeOrderCostBasisRequestOrderStatusEnum["FAILED"] = "FAILED";
  ChangeOrderCostBasisRequestOrderStatusEnum["PARTIALLY_EXECUTED"] = "PARTIALLY_EXECUTED";
  ChangeOrderCostBasisRequestOrderStatusEnum["PARTIALLY_CANCELED"] = "PARTIALLY_CANCELED";
})(ChangeOrderCostBasisRequestOrderStatusEnum = exports.ChangeOrderCostBasisRequestOrderStatusEnum || (exports.ChangeOrderCostBasisRequestOrderStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ChangeOrderCostBasisRequestOrderCostBasisMethodEnum;
(function (ChangeOrderCostBasisRequestOrderCostBasisMethodEnum) {
  ChangeOrderCostBasisRequestOrderCostBasisMethodEnum["FIFO"] = "FIFO";
  ChangeOrderCostBasisRequestOrderCostBasisMethodEnum["HIFO"] = "HIFO";
  ChangeOrderCostBasisRequestOrderCostBasisMethodEnum["MINTAX"] = "MINTAX";
  ChangeOrderCostBasisRequestOrderCostBasisMethodEnum["SPEC"] = "SPEC";
  ChangeOrderCostBasisRequestOrderCostBasisMethodEnum["LIFO"] = "LIFO";
  ChangeOrderCostBasisRequestOrderCostBasisMethodEnum["AVGC"] = "AVGC";
})(ChangeOrderCostBasisRequestOrderCostBasisMethodEnum = exports.ChangeOrderCostBasisRequestOrderCostBasisMethodEnum || (exports.ChangeOrderCostBasisRequestOrderCostBasisMethodEnum = {}));
