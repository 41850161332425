"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SellRetirementDistributionInstructionsReasonCodeEnum = exports.SellRetirementDistributionInstructionsDistributionTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var SellRetirementDistributionInstructionsDistributionTypeEnum;
(function (SellRetirementDistributionInstructionsDistributionTypeEnum) {
  SellRetirementDistributionInstructionsDistributionTypeEnum["EXCESS_CONTRIBUTION"] = "EXCESS_CONTRIBUTION";
  SellRetirementDistributionInstructionsDistributionTypeEnum["EXCESS_EARNINGS"] = "EXCESS_EARNINGS";
  SellRetirementDistributionInstructionsDistributionTypeEnum["HARDSHIP"] = "HARDSHIP";
  SellRetirementDistributionInstructionsDistributionTypeEnum["I401_K"] = "I401K";
  SellRetirementDistributionInstructionsDistributionTypeEnum["LUMP_SUM"] = "LUMP_SUM";
  SellRetirementDistributionInstructionsDistributionTypeEnum["NORMAL"] = "NORMAL";
})(SellRetirementDistributionInstructionsDistributionTypeEnum = exports.SellRetirementDistributionInstructionsDistributionTypeEnum || (exports.SellRetirementDistributionInstructionsDistributionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SellRetirementDistributionInstructionsReasonCodeEnum;
(function (SellRetirementDistributionInstructionsReasonCodeEnum) {
  SellRetirementDistributionInstructionsReasonCodeEnum["_01"] = "01";
  SellRetirementDistributionInstructionsReasonCodeEnum["_02"] = "02";
  SellRetirementDistributionInstructionsReasonCodeEnum["_04"] = "04";
  SellRetirementDistributionInstructionsReasonCodeEnum["_05"] = "05";
  SellRetirementDistributionInstructionsReasonCodeEnum["_07"] = "07";
  SellRetirementDistributionInstructionsReasonCodeEnum["_08"] = "08";
  SellRetirementDistributionInstructionsReasonCodeEnum["_10"] = "10";
  SellRetirementDistributionInstructionsReasonCodeEnum["_11"] = "11";
  SellRetirementDistributionInstructionsReasonCodeEnum["_12"] = "12";
  SellRetirementDistributionInstructionsReasonCodeEnum["_1_D"] = "1D";
  SellRetirementDistributionInstructionsReasonCodeEnum["_1_P"] = "1P";
  SellRetirementDistributionInstructionsReasonCodeEnum["_2_D"] = "2D";
  SellRetirementDistributionInstructionsReasonCodeEnum["_2_P"] = "2P";
  SellRetirementDistributionInstructionsReasonCodeEnum["_3_M"] = "3M";
  SellRetirementDistributionInstructionsReasonCodeEnum["_4_D"] = "4D";
  SellRetirementDistributionInstructionsReasonCodeEnum["_4_G"] = "4G";
  SellRetirementDistributionInstructionsReasonCodeEnum["_4_M"] = "4M";
  SellRetirementDistributionInstructionsReasonCodeEnum["_4_P"] = "4P";
  SellRetirementDistributionInstructionsReasonCodeEnum["_81"] = "81";
  SellRetirementDistributionInstructionsReasonCodeEnum["_82"] = "82";
  SellRetirementDistributionInstructionsReasonCodeEnum["_84"] = "84";
  SellRetirementDistributionInstructionsReasonCodeEnum["_8_J"] = "8J";
  SellRetirementDistributionInstructionsReasonCodeEnum["_8_M"] = "8M";
  SellRetirementDistributionInstructionsReasonCodeEnum["B"] = "B";
  SellRetirementDistributionInstructionsReasonCodeEnum["B1"] = "B1";
  SellRetirementDistributionInstructionsReasonCodeEnum["B2"] = "B2";
  SellRetirementDistributionInstructionsReasonCodeEnum["B4"] = "B4";
  SellRetirementDistributionInstructionsReasonCodeEnum["B7"] = "B7";
  SellRetirementDistributionInstructionsReasonCodeEnum["B8"] = "B8";
  SellRetirementDistributionInstructionsReasonCodeEnum["BD"] = "BD";
  SellRetirementDistributionInstructionsReasonCodeEnum["BG"] = "BG";
  SellRetirementDistributionInstructionsReasonCodeEnum["BP"] = "BP";
  SellRetirementDistributionInstructionsReasonCodeEnum["D"] = "D";
  SellRetirementDistributionInstructionsReasonCodeEnum["G"] = "G";
  SellRetirementDistributionInstructionsReasonCodeEnum["H"] = "H";
  SellRetirementDistributionInstructionsReasonCodeEnum["H4"] = "H4";
  SellRetirementDistributionInstructionsReasonCodeEnum["J"] = "J";
  SellRetirementDistributionInstructionsReasonCodeEnum["M"] = "M";
  SellRetirementDistributionInstructionsReasonCodeEnum["N"] = "N";
  SellRetirementDistributionInstructionsReasonCodeEnum["P"] = "P";
  SellRetirementDistributionInstructionsReasonCodeEnum["PJ"] = "PJ";
  SellRetirementDistributionInstructionsReasonCodeEnum["PM"] = "PM";
  SellRetirementDistributionInstructionsReasonCodeEnum["PT"] = "PT";
  SellRetirementDistributionInstructionsReasonCodeEnum["Q"] = "Q";
  SellRetirementDistributionInstructionsReasonCodeEnum["R"] = "R";
  SellRetirementDistributionInstructionsReasonCodeEnum["S"] = "S";
  SellRetirementDistributionInstructionsReasonCodeEnum["T"] = "T";
})(SellRetirementDistributionInstructionsReasonCodeEnum = exports.SellRetirementDistributionInstructionsReasonCodeEnum || (exports.SellRetirementDistributionInstructionsReasonCodeEnum = {}));
