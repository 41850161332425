"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelectedTaxLotTransactionTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var SelectedTaxLotTransactionTypeEnum;
(function (SelectedTaxLotTransactionTypeEnum) {
  SelectedTaxLotTransactionTypeEnum["DEFAULT"] = "Default";
  SelectedTaxLotTransactionTypeEnum["REDEEM_ALL"] = "REDEEM_ALL";
  SelectedTaxLotTransactionTypeEnum["BUY"] = "BUY";
  SelectedTaxLotTransactionTypeEnum["SELL"] = "SELL";
  SelectedTaxLotTransactionTypeEnum["SHORT"] = "SHORT";
  SelectedTaxLotTransactionTypeEnum["EXCHANGE"] = "EXCHANGE";
  SelectedTaxLotTransactionTypeEnum["BUY_MINUS"] = "BUY_MINUS";
  SelectedTaxLotTransactionTypeEnum["SELL_PLUS"] = "SELL_PLUS";
  SelectedTaxLotTransactionTypeEnum["BUY_TO_COVER"] = "BUY_TO_COVER";
  SelectedTaxLotTransactionTypeEnum["EXCHNAGE_ALL"] = "EXCHNAGE_ALL";
  SelectedTaxLotTransactionTypeEnum["BUY_TO_OPEN"] = "BUY_TO_OPEN";
  SelectedTaxLotTransactionTypeEnum["BUY_TO_CLOSE"] = "BUY_TO_CLOSE";
  SelectedTaxLotTransactionTypeEnum["SELL_TO_OPEN"] = "SELL_TO_OPEN";
  SelectedTaxLotTransactionTypeEnum["SELL_TO_CLOSE"] = "SELL_TO_CLOSE";
})(SelectedTaxLotTransactionTypeEnum = exports.SelectedTaxLotTransactionTypeEnum || (exports.SelectedTaxLotTransactionTypeEnum = {}));
