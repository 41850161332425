"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BusinessOriginBusinessDepartment = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var BusinessOriginBusinessDepartment;
(function (BusinessOriginBusinessDepartment) {
  BusinessOriginBusinessDepartment["AUTOMATION_AND_DIGITIZATION"] = "AUTOMATION_AND_DIGITIZATION";
  BusinessOriginBusinessDepartment["CASH_MANAGEMENT"] = "CASH_MANAGEMENT";
  BusinessOriginBusinessDepartment["CLIENT_EXPERIENCE_DIGITAL"] = "CLIENT_EXPERIENCE_DIGITAL";
  BusinessOriginBusinessDepartment["DIGITAL_ADVICE"] = "DIGITAL_ADVICE";
  BusinessOriginBusinessDepartment["FOUNDATIONAL_OPERATIONS"] = "FOUNDATIONAL_OPERATIONS";
  BusinessOriginBusinessDepartment["INVESTOR_TRADING_SERVICE"] = "INVESTOR_TRADING_SERVICE";
  BusinessOriginBusinessDepartment["PERSONAL_ADVISOR_SERVICE"] = "PERSONAL_ADVISOR_SERVICE";
  BusinessOriginBusinessDepartment["RETAIL_TEST_DATA_MANAGEMENT"] = "RETAIL_TEST_DATA_MANAGEMENT";
  BusinessOriginBusinessDepartment["SPECIALIZED_BROKERAGE_OPERATIONS"] = "SPECIALIZED_BROKERAGE_OPERATIONS";
  BusinessOriginBusinessDepartment["VANGUARD_INSTITUTIONAL_ADVISORY_SERVICES"] = "VANGUARD_INSTITUTIONAL_ADVISORY_SERVICES";
  BusinessOriginBusinessDepartment["VANGUARD_PERSONALIZED_INDEXING_MANAGEMENT"] = "VANGUARD_PERSONALIZED_INDEXING_MANAGEMENT";
  BusinessOriginBusinessDepartment["INVEST_FOUNDATIONS"] = "INVEST_FOUNDATIONS";
})(BusinessOriginBusinessDepartment = exports.BusinessOriginBusinessDepartment || (exports.BusinessOriginBusinessDepartment = {}));
