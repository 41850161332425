import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CostBasisFormGroup, JsonContent, Quote } from '@app/etfs-equities/models';
import { TradeTicketService, WindowService } from '@app/etfs-equities/services';
import { CostBasisService } from '@app/etfs-equities/services/cost-basis/cost-basis.service';
import { selectQuote, selectTradeMethodsForCurrentPosition, TayneState } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { SingleErrorFormControl } from '@vanguard/trade-standard-forms-lib-ng-17';
import { CostBasisMethod, CostBasisMethodFormControlType } from '@vanguard/trade-ui-components-lib-ng-17';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';
import { Observable, Subject, Subscription, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'twe-cost-basis-modal',
  templateUrl: './cost-basis-modal.component.html',
  styleUrls: ['./cost-basis-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CostBasisModalComponent implements OnInit, OnDestroy {
  //  Decorators...

  @ViewChild('modal')
  modal?: ModalDialogComponent;

  @Input() isSellAll: boolean;

  @Input() allowSpecID: boolean;

  //  Public variables...

  content: JsonContent = content;
  defaultCostBasisCheckboxValue = false;
  costBasisMethodCodeEnums = CostBasisMethod;
  costBasisFormGroup: FormGroup<CostBasisFormGroup>;
  specIdIsSelected: boolean;

  //  Public Observables/Subjects...

  costBasisMethodCodes$: Observable<CostBasisMethod[]>;
  openSelectionModalSubscription: Subscription;
  quote$: Observable<Quote>;

  //  Private Observables/Subjects...

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public tradeTicketService: TradeTicketService,
    public store: Store<TayneState>,
    public readonly windowService: WindowService,
    private readonly costBasisService: CostBasisService
  ) {
    this.costBasisFormGroup = new FormGroup<CostBasisFormGroup>({
      costBasisMethodFormControl: new SingleErrorFormControl<CostBasisMethodFormControlType>(CostBasisMethod.FIFO),
      setAsPreferredFormControl: new SingleErrorFormControl<boolean>(false),
    });
  }

  ngOnInit() {
    this.costBasisMethodCodes$ = this.store.pipe(select(selectTradeMethodsForCurrentPosition));
    this.quote$ = this.store.pipe(select(selectQuote));
    this.openSelectionModalSubscription = this.costBasisService.openSelectionModal$.subscribe(() =>
      this.openCostBasisModal()
    );
    this.watchForCostBasisMethodChange();
  }

  ngOnDestroy() {
    this.openSelectionModalSubscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openCostBasisModal() {
    this.costBasisFormGroup.controls.costBasisMethodFormControl.setValue(
      this.tradeTicketService.tradeTicket.controls.costBasisMethod.value ?? this.costBasisMethodCodeEnums.FIFO
    );
    this.defaultCostBasisCheckboxValue =
      this.tradeTicketService.tradeTicket.controls.updateDefaultCostBasis.value ?? false;
    this.modal.openModalDialog();
  }

  /**
   * Sets the cost basis method and updateDefaultCostBasis
   * value on the trade ticket to the value selected in
   * the modal and closes the modal
   */
  continue() {
    this.tradeTicketService.setCostBasisMethod(this.costBasisFormGroup.controls.costBasisMethodFormControl.value);
    this.tradeTicketService.setUpdateDefaultCostBasis(this.defaultCostBasisCheckboxValue);
  }

  /**
   * returns true if the argument passed in
   * matches cost basis enum Spec Id
   *
   * @param costBasis CostBasisMethod
   * @returns boolean
   */
  isCostBasisMethodSpecId(costBasis: CostBasisMethod): boolean {
    return costBasis === this.costBasisMethodCodeEnums.SPEC_ID;
  }

  /**
   * remove SpecID from the list of cost basis methods for cases where it is disallowed
   *
   * @param codes CostBasisMethod[]
   * @returns CostBasisMethod[]
   */
  getCostBasisCodes(codes: CostBasisMethod[]): CostBasisMethod[] {
    return codes?.filter((code) => !this.isCostBasisMethodSpecId(code) || this.allowSpecID);
  }

  openLinkInNewTab(link: string): void {
    this.windowService.navigateToExternalLink(link, '_blank');
  }

  getModalContent(): JsonContent {
    const modalContent = this.content.costBasisSelection.costBasisSelectionModal;

    // note: the order-type-conditional disclaimer takes precedence over sellAll and dollar-based trade disclaimers
    if (!this.tradeTicketService.orderTypeIsMarket()) {
      modalContent.bodyTextLotsAndSpecIdNotAvailable = this.content.costBasisSpecIdDisclaimer;
    } else if (this.isSellAll) {
      modalContent.bodyTextLotsAndSpecIdNotAvailable = modalContent.specIDNotAvailableForSellAll;
    } else if (this.tradeTicketService.amountTypeIsDollars()) {
      modalContent.bodyTextLotsAndSpecIdNotAvailable = modalContent.specIDNotAvailableForDollars;
    } else {
      modalContent.bodyTextLotsAndSpecIdNotAvailable = '';
    }

    return modalContent;
  }

  private watchForCostBasisMethodChange() {
    this.costBasisFormGroup.controls.costBasisMethodFormControl.valueChanges
      .pipe(
        tap((costBasisMethod) => {
          this.specIdIsSelected = this.isCostBasisMethodSpecId(costBasisMethod);
          if (this.specIdIsSelected) {
            this.defaultCostBasisCheckboxValue = false;
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
