"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InstrumentClassSetInstrumentClassesEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var InstrumentClassSetInstrumentClassesEnum;
(function (InstrumentClassSetInstrumentClassesEnum) {
  InstrumentClassSetInstrumentClassesEnum["EQUITIES"] = "EQUITIES";
  InstrumentClassSetInstrumentClassesEnum["PREFERRED"] = "PREFERRED";
  InstrumentClassSetInstrumentClassesEnum["OPTIONS"] = "OPTIONS";
  InstrumentClassSetInstrumentClassesEnum["MUTUAL_FUNDS"] = "MUTUAL_FUNDS";
  InstrumentClassSetInstrumentClassesEnum["ETFS"] = "ETFs";
  InstrumentClassSetInstrumentClassesEnum["ETNS"] = "ETNs";
  InstrumentClassSetInstrumentClassesEnum["FIXED_INCOME"] = "FIXED_INCOME";
  InstrumentClassSetInstrumentClassesEnum["LIMITED_PARTNERSHIPS"] = "LIMITED_PARTNERSHIPS";
  InstrumentClassSetInstrumentClassesEnum["EXTRA_TYPE1"] = "EXTRA_TYPE1";
  InstrumentClassSetInstrumentClassesEnum["EXTRA_TYPE2"] = "EXTRA_TYPE2";
  InstrumentClassSetInstrumentClassesEnum["LEV_INV_RESET_ETF"] = "LevInv_RESET_ETF";
  InstrumentClassSetInstrumentClassesEnum["LEV_INV_RESET_FUNDS"] = "LevInv_RESET_FUNDS";
  InstrumentClassSetInstrumentClassesEnum["EXTRA_TYPE3"] = "EXTRA_TYPE3";
  InstrumentClassSetInstrumentClassesEnum["EXTRA_TYPE4"] = "EXTRA_TYPE4";
  InstrumentClassSetInstrumentClassesEnum["EXTRA_TYPE5"] = "EXTRA_TYPE5";
  InstrumentClassSetInstrumentClassesEnum["EXTRA_TYPE6"] = "EXTRA_TYPE6";
  InstrumentClassSetInstrumentClassesEnum["EXTRA_TYPE7"] = "EXTRA_TYPE7";
  InstrumentClassSetInstrumentClassesEnum["EXTRA_TYPE8"] = "EXTRA_TYPE8";
  InstrumentClassSetInstrumentClassesEnum["EXTRA_TYPE9"] = "EXTRA_TYPE9";
  InstrumentClassSetInstrumentClassesEnum["EXTRA_TYPE510"] = "EXTRA_TYPE510";
  InstrumentClassSetInstrumentClassesEnum["SHORT_SALE_ETB"] = "SHORT_SALE_ETB";
})(InstrumentClassSetInstrumentClassesEnum = exports.InstrumentClassSetInstrumentClassesEnum || (exports.InstrumentClassSetInstrumentClassesEnum = {}));
