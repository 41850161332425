"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WithdrawalBusinessOriginBusinessWorkStreamEnum = exports.WithdrawalBusinessOriginBusinessDepartmentEnum = exports.WithdrawalBusinessOriginWorkStreamChannelTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var WithdrawalBusinessOriginWorkStreamChannelTypeEnum;
(function (WithdrawalBusinessOriginWorkStreamChannelTypeEnum) {
  WithdrawalBusinessOriginWorkStreamChannelTypeEnum["BATCH"] = "BATCH";
  WithdrawalBusinessOriginWorkStreamChannelTypeEnum["MAIL"] = "MAIL";
  WithdrawalBusinessOriginWorkStreamChannelTypeEnum["MOBILE"] = "MOBILE";
  WithdrawalBusinessOriginWorkStreamChannelTypeEnum["ONLINE"] = "ONLINE";
})(WithdrawalBusinessOriginWorkStreamChannelTypeEnum = exports.WithdrawalBusinessOriginWorkStreamChannelTypeEnum || (exports.WithdrawalBusinessOriginWorkStreamChannelTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var WithdrawalBusinessOriginBusinessDepartmentEnum;
(function (WithdrawalBusinessOriginBusinessDepartmentEnum) {
  WithdrawalBusinessOriginBusinessDepartmentEnum["AUTOMATION_AND_DIGITIZATION"] = "AUTOMATION_AND_DIGITIZATION";
  WithdrawalBusinessOriginBusinessDepartmentEnum["CASH_MANAGEMENT"] = "CASH_MANAGEMENT";
  WithdrawalBusinessOriginBusinessDepartmentEnum["CLIENT_EXPERIENCE_DIGITAL"] = "CLIENT_EXPERIENCE_DIGITAL";
  WithdrawalBusinessOriginBusinessDepartmentEnum["DIGITAL_ADVICE"] = "DIGITAL_ADVICE";
  WithdrawalBusinessOriginBusinessDepartmentEnum["FOUNDATIONAL_OPERATIONS"] = "FOUNDATIONAL_OPERATIONS";
  WithdrawalBusinessOriginBusinessDepartmentEnum["INVESTOR_TRADING_SERVICE"] = "INVESTOR_TRADING_SERVICE";
  WithdrawalBusinessOriginBusinessDepartmentEnum["PERSONAL_ADVISOR_SERVICE"] = "PERSONAL_ADVISOR_SERVICE";
  WithdrawalBusinessOriginBusinessDepartmentEnum["RETAIL_TEST_DATA_MANAGEMENT"] = "RETAIL_TEST_DATA_MANAGEMENT";
  WithdrawalBusinessOriginBusinessDepartmentEnum["SPECIALIZED_BROKERAGE_OPERATIONS"] = "SPECIALIZED_BROKERAGE_OPERATIONS";
  WithdrawalBusinessOriginBusinessDepartmentEnum["VANGUARD_INSTITUTIONAL_ADVISORY_SERVICES"] = "VANGUARD_INSTITUTIONAL_ADVISORY_SERVICES";
  WithdrawalBusinessOriginBusinessDepartmentEnum["VANGUARD_PERSONALIZED_INDEXING_MANAGEMENT"] = "VANGUARD_PERSONALIZED_INDEXING_MANAGEMENT";
  WithdrawalBusinessOriginBusinessDepartmentEnum["INVEST_FOUNDATIONS"] = "INVEST_FOUNDATIONS";
})(WithdrawalBusinessOriginBusinessDepartmentEnum = exports.WithdrawalBusinessOriginBusinessDepartmentEnum || (exports.WithdrawalBusinessOriginBusinessDepartmentEnum = {}));
/**
    * @export
    * @enum {string}
    */
var WithdrawalBusinessOriginBusinessWorkStreamEnum;
(function (WithdrawalBusinessOriginBusinessWorkStreamEnum) {
  WithdrawalBusinessOriginBusinessWorkStreamEnum["AUTOMATIC_INVESTMENT_PLAN"] = "AUTOMATIC_INVESTMENT_PLAN";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["AUTOMATIC_INVESTMENT_PLAN_SETUP"] = "AUTOMATIC_INVESTMENT_PLAN_SETUP";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["AUTOMATIC_WITHDRAWAL_PLAN"] = "AUTOMATIC_WITHDRAWAL_PLAN";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["BROKERAGE_ORDER_CHANGE"] = "BROKERAGE_ORDER_CHANGE";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["CMA_CASH_TRANSFER"] = "CMA_CASH_TRANSFER";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["CORPORATE_TRUST"] = "CORPORATE_TRUST";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["CORRECTIONS_AT_SCALE"] = "CORRECTIONS_AT_SCALE";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["DAILY_FEES"] = "DAILY_FEES";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["DIRECT_DEPOSIT_EXCEPTION"] = "DIRECT_DEPOSIT_EXCEPTION";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["FINANCIAL_PLANNING_CASH_MOVEMENT"] = "FINANCIAL_PLANNING_CASH_MOVEMENT";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["PORTFOLIO_CONSTRUCTION_ADVISOR"] = "PORTFOLIO_CONSTRUCTION_ADVISOR";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["PORTFOLIO_CONSTRUCTION_CASH_MOVEMENT"] = "PORTFOLIO_CONSTRUCTION_CASH_MOVEMENT";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["PORTFOLIO_CONSTRUCTION_DAILY"] = "PORTFOLIO_CONSTRUCTION_DAILY";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["PORTFOLIO_CONSTRUCTION_NON_SETTLEMENT_FUND"] = "PORTFOLIO_CONSTRUCTION_NON_SETTLEMENT_FUND";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["PORTFOLIO_CONSTRUCTION_NON_SPEND_FUND"] = "PORTFOLIO_CONSTRUCTION_NON_SPEND_FUND";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["PORTFOLIO_CONSTRUCTION_NON_SWEEP_FUND"] = "PORTFOLIO_CONSTRUCTION_NON_SWEEP_FUND";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["PORTFOLIO_CONSTRUCTION_QUARTERLY"] = "PORTFOLIO_CONSTRUCTION_QUARTERLY";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["PORTFOLIO_CONSTRUCTION_SETTLEMENT_FUND"] = "PORTFOLIO_CONSTRUCTION_SETTLEMENT_FUND";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["PORTFOLIO_CONSTRUCTION_SWEEP_FUND"] = "PORTFOLIO_CONSTRUCTION_SWEEP_FUND";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["PRIVATE_EQUITY_FEE"] = "PRIVATE_EQUITY_FEE";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["QUARTERLY_FEES"] = "QUARTERLY_FEES";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["ROTH_CONVERSION"] = "ROTH_CONVERSION";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["STANDARD_TRADING_OPERATIONAL_PROCEDURE"] = "STANDARD_TRADING_OPERATIONAL_PROCEDURE";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["TAX_LOSS_HARVESTING"] = "TAX_LOSS_HARVESTING";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["TEST_DATA_AS_A_SERVICE"] = "TEST_DATA_AS_A_SERVICE";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["VANGUARD_PERSONALIZED_INDEXING"] = "VANGUARD_PERSONALIZED_INDEXING";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["AUTO_INVEST_INTO_ETFS"] = "AUTO_INVEST_INTO_ETFS";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["CREDIT_MARGIN_REPORT_AUTO_SELL"] = "CREDIT_MARGIN_REPORT_AUTO_SELL";
  WithdrawalBusinessOriginBusinessWorkStreamEnum["DIRECT_DIVIDEND_SERVICES"] = "DIRECT_DIVIDEND_SERVICES";
})(WithdrawalBusinessOriginBusinessWorkStreamEnum = exports.WithdrawalBusinessOriginBusinessWorkStreamEnum || (exports.WithdrawalBusinessOriginBusinessWorkStreamEnum = {}));
