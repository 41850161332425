"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.44.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClientManagementResponseMaritalStatusEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ClientManagementResponseMaritalStatusEnum;
(function (ClientManagementResponseMaritalStatusEnum) {
  ClientManagementResponseMaritalStatusEnum["DIVORCED"] = "DIVORCED";
  ClientManagementResponseMaritalStatusEnum["DOMESTIC_PARTNER"] = "DOMESTIC_PARTNER";
  ClientManagementResponseMaritalStatusEnum["MARRIED"] = "MARRIED";
  ClientManagementResponseMaritalStatusEnum["SEPARATED"] = "SEPARATED";
  ClientManagementResponseMaritalStatusEnum["SINGLE"] = "SINGLE";
  ClientManagementResponseMaritalStatusEnum["UNDISCLOSED"] = "UNDISCLOSED";
  ClientManagementResponseMaritalStatusEnum["UNKNOWN"] = "UNKNOWN";
  ClientManagementResponseMaritalStatusEnum["WIDOWED"] = "WIDOWED";
})(ClientManagementResponseMaritalStatusEnum = exports.ClientManagementResponseMaritalStatusEnum || (exports.ClientManagementResponseMaritalStatusEnum = {}));
