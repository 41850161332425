import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { GatekeeperService } from '@app/core/services';
import { Observable } from 'rxjs';

export const holdingsCanMatch: CanMatchFn = (): Observable<boolean> => {
  const gatekeeperService = inject(GatekeeperService);

  return gatekeeperService.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_MODERN_HOLDINGS_TAB);
};
