import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Account, JsonContent } from '@app/etfs-equities/models';
import { selectAccountIsMargin, TayneState } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { Store } from '@ngrx/store';
import {
  DetailType,
  ProductListTableColumnDescription,
  ProductListTableRow,
  ProductListTableRowButtonType,
  ProductListTableRowContentStyle,
  ProductListTableTextDetails,
  ResponsiveBreakpoint,
} from '@vanguard/trade-ui-components-lib-ng-17';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-holdings-table',
  templateUrl: './holdings-table.component.html',
  styleUrl: './holdings-table.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoldingsTableComponent implements OnInit {
  // Private variables...
  private currentPage: number = null;

  // Public variables...
  @Input({ required: true }) title: string;
  @Input({ required: true }) holdings: Account.MappedHolding[];

  content: JsonContent = content;
  columnDescriptions: ProductListTableColumnDescription[];
  tableRows: ProductListTableRow[];

  // Observables
  accountIsMargin$: Observable<boolean>;

  constructor(private readonly store: Store<TayneState>) {}

  ngOnInit(): void {
    this.columnDescriptions = this.getColumnDescriptions(this.content.holdings.tableHeaders);

    this.accountIsMargin$ = this.store.select(selectAccountIsMargin);
  }

  handlePaginationChange(event: number, header: HTMLElement): void {
    // skip init call
    if (this.currentPage && this.currentPage !== event) {
      header?.scrollIntoView({ behavior: 'smooth' });
    }
    this.currentPage = event;
  }

  getTableRows(isMarginAccount: boolean): ProductListTableRow[] {
    this.currentPage = null;
    return (
      this.holdings?.map((holding) => {
        return {
          cells: [
            {
              lines: [
                {
                  details: [
                    {
                      detailType: DetailType.TEXT,
                      text: holding.raw.ticker,
                      contentStyle: ProductListTableRowContentStyle.BOLD,
                    },
                    {
                      detailType: DetailType.TEXT,
                      text: holding.name,
                    },
                  ],
                },
              ],
            },
            {
              lines: [
                {
                  details: this.getCurrentPositionDetails(holding, isMarginAccount),
                },
              ],
            },
            {
              lines: [
                {
                  textAlignRight: true,
                  details: [
                    {
                      detailType: DetailType.BUTTON,
                      buttonType: ProductListTableRowButtonType.BUTTON,
                      labelText: this.content.labels.buy,
                    },
                    {
                      detailType: DetailType.BUTTON,
                      buttonType: ProductListTableRowButtonType.BUTTON,
                      labelText: this.content.labels.sell,
                    },
                  ],
                },
              ],
            },
          ],
        };
      }) || []
    );
  }

  private getColumnDescriptions(headers: { text: string; wide: boolean }[]): ProductListTableColumnDescription[] {
    const descriptions = [];

    headers.forEach((header, index) => {
      descriptions.push({
        alignRightDesktop: index !== 0,
        wide: header.wide,
        columnHeader: {
          text: header.text,
          visuallyHideForMobile: true,
          visuallyHideForBreakpoint: ResponsiveBreakpoint.BELOW_LG,
        },
      });
    });

    // last column with action buttons
    descriptions.push({
      columnHeader: {
        text: this.content.labels.actions,
        visuallyHideForDesktop: true,
        visuallyHideForMobile: true,
        visuallyHideForBreakpoint: ResponsiveBreakpoint.BELOW_XXXL,
      },
      wide: true,
    });

    return descriptions;
  }

  private getCurrentPositionDetails(
    holding: Account.MappedHolding,
    isMarginAccount: boolean
  ): ProductListTableTextDetails[] {
    const details: ProductListTableTextDetails[] = [
      {
        detailType: DetailType.TEXT,
        text: `$${(+holding.raw.balance)?.toFixed(2)}`,
        contentStyle: ProductListTableRowContentStyle.BOLD,
      },
      {
        detailType: DetailType.TEXT,
        text: `${holding.quantity?.toFixed(2)} shares`,
      },
    ];

    if (isMarginAccount) {
      details.push({
        detailType: DetailType.TEXT,
        text: holding.raw.brokeragePosition.accountType,
      });
    }

    return details;
  }
}
